import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { UserContext } from '../user-context.js';
import { API } from '../api-service.js';
import { Handle, Position, useReactFlow } from 'reactflow';
import Select from 'react-select';
import { toast } from "react-toastify";
import { WhatsappTemplateContext } from './WhatsAppTemplate.js';
import { FaBoltLightning, FaCircleStop } from 'react-icons/fa6';
import { IoIosArrowBack } from "react-icons/io";
import { TbTemplate } from "react-icons/tb";
import { LuListTodo } from "react-icons/lu";
import { AiFillHome } from "react-icons/ai";
import { MdOutlinePermMedia, MdCancel } from "react-icons/md";

import './css/CustomNodes.css';

const arrangeNodes = (nodes, edges) => {
    const SPACING = {
        HORIZONTAL: 400,
        VERTICAL_PADDING: 150
    };

    // Helper function to get node height with padding
    const getNodeSpacing = (node) => {
        const element = document.querySelector(`[data-id="${node.id}"]`);
        if (element) {
            const height = element.getBoundingClientRect().height;
            return height + SPACING.VERTICAL_PADDING;
        }
        return 200; // fallback height
    };

    // Helper function to get complete template chain
    const getTemplateChain = (startNodeId, visited = new Set()) => {
        if (visited.has(startNodeId)) return [];
        visited.add(startNodeId);

        const chain = [];
        const outgoingEdges = edges.filter(edge => edge.source === startNodeId);
        
        outgoingEdges.forEach(edge => {
            const targetNode = nodes.find(n => n.id === edge.target);
            if (targetNode && targetNode.type === 'templateNode') {
                chain.push({
                    node: targetNode,
                    children: getTemplateChain(targetNode.id, visited)
                });
            }
        });

        return chain;
    };

    // Find reference points
    const checkInNode = nodes.find(node => node.id === 'CHECKIN');
    const baseX = checkInNode?.position.x || 0;
    const baseY = checkInNode?.position.y || 0;

    const unitNodes = nodes.filter(node => node.type === 'unitNode');
    const templateNodes = nodes.filter(node => node.type === 'templateNode');

    // Handle check-in units
    const checkInUnits = unitNodes.filter(node => 
        edges.some(edge => edge.source === 'CHECKIN' && edge.target === node.id)
    );
    
    let cumulativeHeight = baseY;
    const checkInUnitPositions = new Map();
    
    checkInUnits.forEach(node => {
        checkInUnitPositions.set(node.id, cumulativeHeight);
        cumulativeHeight += getNodeSpacing(node);
    });

    cumulativeHeight += SPACING.VERTICAL_PADDING;

    // Handle check-out units
    const checkOutUnits = unitNodes.filter(node => 
        edges.some(edge => edge.source === 'CHECKOUT' && edge.target === node.id)
    );
    
    const checkOutUnitPositions = new Map();
    checkOutUnits.forEach(node => {
        checkOutUnitPositions.set(node.id, cumulativeHeight);
        cumulativeHeight += getNodeSpacing(node);
    });

    // First pass: Position base nodes
    let positionedNodes = nodes.map(node => {
        let newPos = { ...node.position };

        if (node.id === 'CHECKOUT') {
            newPos = {
                x: baseX,
                y: checkInUnitPositions.size > 0 ? 
                    [...checkInUnitPositions.values()].pop() + getNodeSpacing(checkInUnits[checkInUnits.length - 1]) :
                    baseY
            };
        } 
        else if (node.type === 'unitNode') {
            const isCheckInUnit = edges.some(edge => 
                edge.source === 'CHECKIN' && edge.target === node.id
            );
            
            newPos = {
                x: baseX + SPACING.HORIZONTAL/2,
                y: isCheckInUnit ? 
                    checkInUnitPositions.get(node.id) : 
                    checkOutUnitPositions.get(node.id)
            };
        }

        return {
            ...node,
            position: newPos
        };
    });

    // Second pass: Position template chains
    const processedTemplates = new Set();

    const positionTemplateChain = (chain, startX, startY, depth = 0) => {
        chain.forEach((item, index) => {
            if (!processedTemplates.has(item.node.id)) {
                processedTemplates.add(item.node.id);
                
                // Calculate position relative to the starting point
                const xPos = startX + SPACING.HORIZONTAL;
                const yPos = startY;

                positionedNodes = positionedNodes.map(node => 
                    node.id === item.node.id ? 
                        { ...node, position: { x: xPos, y: yPos } } : 
                        node
                );

                if (item.children.length > 0) {
                    // Pass the current node's position as the new start point
                    positionTemplateChain(item.children, xPos, yPos, depth + 1);
                }
            }
        });
    };

    // Process template chains for each unit node
    unitNodes.forEach(unitNode => {
        const templateChain = getTemplateChain(unitNode.id);
        const unitNodePos = positionedNodes.find(n => n.id === unitNode.id)?.position;
        if (unitNodePos) {
            positionTemplateChain(templateChain, unitNodePos.x, unitNodePos.y);
        }
    });

    // Process any remaining template chains
    templateNodes.forEach(templateNode => {
        if (!processedTemplates.has(templateNode.id)) {
            const sourceEdge = edges.find(edge => edge.target === templateNode.id);
            if (sourceEdge) {
                const sourceNode = positionedNodes.find(n => n.id === sourceEdge.source);
                if (sourceNode) {
                    const templateChain = getTemplateChain(templateNode.id);
                    positionTemplateChain([{node: templateNode, children: templateChain}], 
                        sourceNode.position.x, 
                        sourceNode.position.y);
                }
            }
        }
    });

    return positionedNodes;
};


function CheckInNode({ id, data, isConnectable, xPos, yPos }){

    const [isHovered, setIsHovered] = useState(false);
    const instance = useReactFlow();

    const onNodeClick = (e) => {
        // Prevent default delete behavior
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClick = (e) => {
        e.stopPropagation();
        const edges = instance.getEdges();

        const nodeId = 'UNIT-' + Date.now();
        
        const newNode = { id: nodeId, type: 'unitNode', position: {x: 0, y: 0}, data: {'text': '', onChange: data.onChange, unitOnChange: data.unitOnChange, setNodes: data.setNodes, setEdges: data.setEdges, openModal: data.openModal}};
        const edgeId = `${id}-${nodeId}`;
        const newEdge = {id: edgeId, source: id, target: nodeId, sourceHandle: 'checkInSource', targetHandle: 'unitTarget'};

        data.setNodes(nodes => arrangeNodes([...nodes, newNode], [...edges, newEdge]));
        data.setEdges(edges => [...edges, newEdge]);
    };
    return (
        <div className='startNode' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={onNodeClick} style={{ position: 'relative' }}>
            <div className="startNode-body">
                <FaBoltLightning /> <b>Check-in</b>
                <button onClick={handleClick} className={`addNodeBtn ${isHovered ? 'visible' : ''}`}>
                    +
                </button>
            </div>
            <Handle type='source' position={Position.Right} id='checkInSource' isConnectable={isConnectable} style={{opacity: 0}}/>
        </div>
    )
}

function CheckOutNode({ id, data, isConnectable, xPos, yPos }){
    const [isHovered, setIsHovered] = useState(false);
    const instance = useReactFlow();

    const onNodeClick = (e) => {
        // Prevent default delete behavior
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClick = (e) => {
        e.stopPropagation();
        const edges = instance.getEdges();
        const nodeId = 'UNIT-' + Date.now();
        
        // Find available position
        const newNode = { id: nodeId, type: 'unitNode', position: {x:0, y:0}, data: {'text': '', onChange: data.onChange, unitOnChange: data.unitOnChange, setNodes: data.setNodes, setEdges: data.setEdges, openModal: data.openModal}};
        const edgeId = `${id}-${nodeId}`;
        const newEdge = {id: edgeId, source: id, target: nodeId, sourceHandle: 'checkOutSource', targetHandle: 'unitTarget'};

        data.setNodes(nodes => arrangeNodes([...nodes, newNode], [...edges, newEdge]));
        data.setEdges(edges => [...edges, newEdge]);
    };
    return (
        <div className='startNode' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={onNodeClick} style={{ position: 'relative' }}>
            <div className="startNode-body">
                <FaBoltLightning /> <b>Check-out</b>
                <button onClick={handleClick} className={`addNodeBtn ${isHovered ? 'visible' : ''}`}>
                    +
                </button>
            </div>
            <Handle type='source' position={Position.Right} id='checkInSource' isConnectable={isConnectable} style={{opacity: 0}}/>
        </div>
    )
}

function UnitNode({id, data, isConnectable, xPos, yPos}){

    const [isAddNewUnit, setIsAddNewUnit] = useState(false);
    const [unit, setUnit] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [unitList, setUnitList] = useState([]);
    const instance = useReactFlow();
    
    useEffect(() => {
        setUnitList(JSON.parse(data.text || '[]'));
        data.unitOnChange('add', JSON.parse(data.text || '[]'))
    }, []);

    const handleAddUnit = () => {
        if (unit === '') {
            toast.error('Unit number cannot be empty');
            return;
        }

        if(data.unitOnChange('add', unit)){
            setUnitList([...unitList, unit]);
            data.onChange(id, JSON.stringify([...unitList, unit]));
        } else {
            toast.error('Unit number already exists');
        }

        setUnit('');
        setIsAddNewUnit(false);
    }

    const handleDeleteUnit = (unit) => {
        const newUnitList = unitList.filter((item) => item !== unit);
        setUnitList(newUnitList);
        data.unitOnChange('remove', unit);
        data.onChange(id, JSON.stringify(newUnitList));
    }

    const handleClick = (e) => {
        e.stopPropagation();
        const nodes = instance.getNodes();
        const edges = instance.getEdges();

        const existingTemplateEdges = edges.filter(edge => edge.source === id && edge.sourceHandle === 'unitMatch');

        if (existingTemplateEdges.length > 0) {
            toast.warn('A template node is already connected');
            return;
        }
        const nodeId = 'TEMPLATE-' + Date.now();
        
        const newNode = { id: nodeId, type: 'templateNode', position: {x:0, y:0}, data: {'text': '{day: \'Day of\'}', onChange: data.onChange, setNodes: data.setNodes, setEdges: data.setEdges, openModal: data.openModal}};
        const edgeId = `${id}-${nodeId}`;
        const newEdge = {id: edgeId, source: id, target: nodeId, sourceHandle: 'unitMatch', targetHandle: 'templateTarget'};

        data.setNodes(nodes => arrangeNodes([...nodes, newNode], [...edges, newEdge]));
        data.setEdges(edges => [...edges, newEdge]);
    }

    return (
        <div className='unitNode node' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className='unitNode-head'>
                <AiFillHome/><b>Unit No</b>
            </div>

            <div className='unitNode-body'>
                <div className='unit-grid'>
                    {unitList.map((unit, index) => {
                        return (
                            <div className='unit-card' key={index}>
                                {unit}
                                <MdCancel className='cancel-active' onClick={() => handleDeleteUnit(unit)}/>
                            </div>
                        )
                    })}
                </div>

                <div className={`add-unit-input ${isAddNewUnit ? 'unit-active' : ''}`}>
                    <div className='add-unit-input-header'>
                        <IoIosArrowBack onClick={() => setIsAddNewUnit(false)} style={{fontSize: '1rem'}}/>
                        <h3>Add new unit</h3>
                    </div>
                    <div className='input-container'>
                        <input id="unitNode" placeholder='Enter unit number' value={unit} name="unitNode" onChange={(evt)=>setUnit(evt.target.value)} className="nodrag" />
                        <label htmlFor="unitNode">Enter unit number</label>
                    </div>
                    <button className='add-unit-button' onClick={() => handleAddUnit()}>
                        Add new unit
                    </button>
                </div>
                <div className='add-unit-div'>
                    <button className='add-unit-card' onClick={() => setIsAddNewUnit(true)} style={{'display': isAddNewUnit ? 'none': 'block'}}>
                        Add new units
                    </button>
                </div>
            </div>
            <button onClick={handleClick} className={`addNodeBtn ${isHovered ? 'visible' : ''}`} style={{bottom: '2rem'}}>
                +
            </button>
            
            <Handle type='target' position={Position.Left} id='unitTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Right} id='unitMatch' isConnectable={isConnectable}/>
        </div>
    )
}


function TemplateNode({id, data, isConnectable }) {
    const {whatsappTemplates, setWhatsappTemplates} = useContext(WhatsappTemplateContext);
    
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateData, setTemplateData] = useState({});
    const [isHovered, setIsHovered] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);
    const instance = useReactFlow();
    const [checkInDay, setCheckInDay] = useState('Day of');
    const [checkInTime, setCheckInTime] = useState('');

    const safeParseJSON = (str) => {
        if (!str) return { day: '', time: '', template: '' };
        
        try {
            // If str is already an object, return it
            if (typeof str === 'object') return str;
            
            // Clean the string by replacing single quotes with double quotes
            // and handling potential undefined values
            const cleanStr = str.replace(/'/g, '"')
                              .replace(/undefined/g, '""');
            
            const parsed = JSON.parse(cleanStr);
            
            // Validate the structure
            return {
                day: parsed.day || '',
                time: parsed.time || '',
                template: parsed.template || ''
            };
        } catch (e) {
            console.error("Error parsing template data:", str, e);
            // Return default structure if parsing fails
            return {
                day: '',
                time: '',
                template: ''
            };
        }
    };

    useEffect(() => {
        const templateData = safeParseJSON(data.text);
        setCheckInDay(templateData.day || 'Day of');
        setCheckInTime(templateData.time || '');
        if (templateData.template && whatsappTemplates) {
            const matchedTemplate = whatsappTemplates.find(
                template => template.label === templateData.template
            );
            setSelectedTemplate(matchedTemplate || null);
        }
    }, [])

    useEffect(() => {
        // Close dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleTemplateChange = (type, text) => {
        if (type === 'day') {
            templateData['day'] = text;
            setCheckInDay(text)
        } else if (type === 'time') {
            templateData['time'] = text;
            setCheckInTime(text)
        } else if (type === 'template') {
            templateData['template'] = text.label;
            setSelectedTemplate(text);
        }

        setIsOpen(false);
        setSearchTerm('');
        data.onChange(id, templateData);
    };

    const filteredTemplates = whatsappTemplates?.filter(template =>
        template.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleClick = (e) => {
        e.stopPropagation();
        const nodes = instance.getNodes();
        const edges = instance.getEdges();
        const nodeId = 'TEMPLATE-' + Date.now();

        const existingTemplateEdges = edges.filter(edge => edge.source === id && edge.sourceHandle === 'templateMatch');

        if (existingTemplateEdges.length > 0) {
            toast.warn('A template node is already connected');
            return;
        }
        
        const newNode = { id: nodeId, type: 'templateNode', position: {x:0, y:0}, data: {'text': '', onChange: data.onChange, setNodes: data.setNodes, setEdges: data.setEdges}};
        const edgeId = `${id}-${nodeId}`;
        const newEdge = {id: edgeId, source: id, target: nodeId, sourceHandle: 'templateMatch', targetHandle: 'templateTarget'};

        data.setNodes(nodes => arrangeNodes([...nodes, newNode], [...edges, newEdge]));
        data.setEdges(edges => [...edges, newEdge]);
    }

    const generateOptions = () => {
        const options = [];
        for (let i = 14; i >= 1; i--) {
            options.push(`${i} days before`);
        }
        options.push('Day of');
        for (let i = 1; i <= 14; i++) {
            options.push(`${i} days after`);
        }
        return options;
    };

    return (
        <React.Fragment>

            <div className='msgNode node' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <div className='msgNode-head'>
                    <TbTemplate /><b>WhatsApp Template</b>
                </div>


                <div className='todolistNode-body' ref={dropdownRef}>
                    <div className='edit-schedule'>
                        <div className="input-container">
                            <select id='checkin-day' value={checkInDay} onChange={(e) => handleTemplateChange('day', e.target.value)} onClick={(e) => e.stopPropagation()}>
                                {generateOptions().map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor='checkin-day'>Schedule day:</label>
                        </div>
                        <div className="input-container">
                            <input id='edit-checkin-time' type='time' value={checkInTime} onChange={(e) => handleTemplateChange('time', e.target.value)} onClick={(e)=> e.stopPropagation()}/>
                            <label htmlFor='edit-checkin-time'>Schedule time:</label>
                        </div>
                    </div>


                    <div className='custom-dropdown'>
                        <div 
                            className='dropdown-header' 
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsOpen(!isOpen);
                            }}
                        >
                            {selectedTemplate ? selectedTemplate.label : 'Select WhatsApp template'}
                        </div>
                        {isOpen && (
                            <div className='dropdown-content'>
                                <input
                                    type="text"
                                    placeholder="Search templates..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onClick={(e) => e.stopPropagation()}
                                    className="dropdown-search"
                                />
                                <button className='templateNode-addtemplatebtn' onClick={() => {data.openModal()}}>Create WhatsApp Template</button>
                                <div className='dropdown-items'>
                                    {filteredTemplates?.map((template) => (
                                        <div
                                            key={template.label}
                                            className='dropdown-item'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleTemplateChange('template', template);
                                            }}
                                        >
                                            <div className="template-item-wrapper">
                                                <span className={`status-dot status-${template.value?.toLowerCase() || 'pending'}`} />
                                                <span className="template-label">{template.label}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <button onClick={handleClick} className={`addNodeBtn ${isHovered ? 'visible' : ''}`} style={{bottom: '3rem'}}>
                    +
                </button>
                <Handle type='target' position={Position.Left} id='templateTarget' isConnectable={isConnectable}/>
                <Handle type='source' position={Position.Right} id='templateMatch' isConnectable={isConnectable}/>
            </div>
        </React.Fragment>
    );
}


export {CheckInNode, CheckOutNode, TemplateNode, UnitNode};