import React, { createContext, useState, useEffect, useContext } from 'react';
import { UserContext } from '../user-context.js';

export const WhatsappTemplateContext = createContext();

export function WhatsappTemplateProvider({ children }) {
    const [whatsappTemplates, setWhatsappTemplates] = useState([]);
    const { wabaId, SystemUserToken } = useContext(UserContext);

    useEffect(() => {
        const getWhatsAppTemplate = async() => {
            try {
                const response = await fetch('https://graph.facebook.com/v20.0/'+ wabaId +'/message_templates?limit=250&fields=name,status', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + SystemUserToken,
                    }
                });
                const resp = await response.json();
                console.log(resp);
                const templateList = resp.data?.map((template) => ({
                    value: template.status,
                    label: template.name,
                }));
                setWhatsappTemplates(templateList);
            } catch (error) {
                console.error('Error fetching WhatsApp templates:', error);
            }
        };

        if (wabaId && SystemUserToken) {
            getWhatsAppTemplate();
        }
    }, [wabaId, SystemUserToken]);

    return (
        <WhatsappTemplateContext.Provider value={{whatsappTemplates, setWhatsappTemplates}}>
            {children}
        </WhatsappTemplateContext.Provider>
    );
}