import React, { useEffect, useState, useContext, useRef, useMemo, useCallback } from 'react';
import { UserContext } from "../user-context.js";
import { API } from "../api-service.js";
import './css/ChatRoomList.css';
import { useWebSocketContext } from '../pages/Websocket.js';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { isToday, isBefore, isAfter, isWithinInterval } from 'date-fns';

const getStatus = (checkinDate, checkoutDate) => {
        
    if (!checkinDate || !checkoutDate) {
        return { text: "No Dates", color: "#4b5563", backgroundColor: "#e5e7eb"}; // Gray
    }
    
    const now = new Date();
    const checkin = new Date(checkinDate);
    const checkout = new Date(checkoutDate);
    
    if (isBefore(now, checkin)) {
        return { text: "Not Check In", color: "#1e40af", backgroundColor: "#bfdbfe" }; // Light blue
    }
    
    
    if (isToday(checkin)) {
        return { text: "Check In Today", color: "#065f46", backgroundColor: "#d1fae5" } ; // Green
    }
    
    
    if (isWithinInterval(now, { start: checkin, end: checkout })) {
        return { text: "Checked In", color: "#0f766e", backgroundColor: "#ccfbf1" }; // Teal
    }
    
    
    if (isToday(checkout)) {
        return { text: "Check Out Today", color: "#c2410c", backgroundColor: "#fed7aa" }; // Orange
    }
    
    
    if (isAfter(now, checkout)) {
        return { text: "Checked Out", color: "#991b1b", backgroundColor: "#fecaca" }; // Red
    }
    
    return { text: "Unknown", color: "#4b5563", backgroundColor: "#e5e7eb"}; // Gray
    
};


const ChatRoomItem = ({ chatRoom, onChatRoomClick, isDisabled, setChatRooms, isSelected , massFlowOption, selectedTags = [] }) => {
    const { userToken, wabaId } = useContext(UserContext);
    const [isHiding, setIsHiding] = useState(false);
    const [participantData, setParticipantData] = useState({});
    const [contextMenu, setContextMenu] = useState(false);
    const contextMenuRef = useRef(null);
    const [longPressTimer, setLongPressTimer] = useState(null);
    const itemRef = useRef(null);
    const [currentParticipantTags, setCurrentParticipantTags] = useState([]);
    const [currentParticipantLabels, setCurrentParticipantLabels] = useState([]);
    const [contactData, setContactData] = useState({});
    const [userLabels, setUserLabels] = useState([]);

    // Format the last message data from chatRoom.last_message
    const lastMessage = useMemo(() => {
        if (!chatRoom.last_message) {
            return { text: '', date: '', time: '', type: '', id: '', sender: '' };
        }
    
        const date = new Date(chatRoom.last_message.timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = date.toLocaleString('en-US', {
            hour: '2-digit', 
            minute: '2-digit'
        });
    
        // Don't add prefix here, just store the raw text
        return {
            text: chatRoom.last_message.text,
            date: formattedDate,
            time: formattedTime,
            type: chatRoom.last_message.media_type || 'text',
            id: chatRoom.last_message.id,
            sender: chatRoom.last_message.sender
        };
    }, [chatRoom.last_message, wabaId]);

    const getUserName = (input) => {
        if (typeof input !== "string") return ""; // Ensure input is a string
    
        // Regular expression to capture everything after the last meaningful number/date/unit
        const regex = /(?:.*\b\d{1,2}\/\d{1,2}-\d{1,2}\/\d{1,2}\b|\S*\d+)?\s*(\S.*)/;
        const match = input.match(regex);
    
        return match && match[1].trim() ? match[1].trim() : "";
    };
    
    
    // Modify the getInitial function to use getUserName
    const getInitial = (name) => {
        if (!name) return '?';
        const actualName = getUserName(name);
        return actualName.charAt(0).toUpperCase();
    };

    const getMessageDateDisplay = (messageDate) => {
        if (!messageDate) return ''; // Handle null or undefined input
    
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
    
        // Parse the message date manually to avoid timezone issues
        const [day, month, year] = messageDate.split('/');
        const messageDateTime = new Date(year, month - 1, day); // Month is zero-based in JS Dates
    
        if (isNaN(messageDateTime)) return ' '; // Invalid date case
    
        // Use toDateString() for reliable date comparison
        const todayStr = today.toDateString();
        const yesterdayStr = yesterday.toDateString();
        const messageStr = messageDateTime.toDateString();
    
        if (messageStr === todayStr) return 'Today';
        if (messageStr === yesterdayStr) return 'Yesterday';
        
        return messageDate; // Return original format for other dates
    };
    
    
    const handleClick = async (e) => {
        if (isDisabled) {
            e.preventDefault();
            return;
        }

        // If there are unread messages, trigger the hiding animation
        if (chatRoom.unread_count > 0) {
            setIsHiding(true);
            // Wait for animation to complete before updating the count
            setTimeout(() => {
                setChatRooms(prevRooms => 
                    prevRooms.map(room => 
                        room.id === chatRoom.id 
                            ? { ...room, unread_count: 0 }
                            : room
                    )
                );
                setIsHiding(false);
            }, 200); // Match this with CSS animation duration
        }
        onChatRoomClick(chatRoom.id, chatRoom);
    };

    // Add participant data processing
    useEffect(() => {
        const processParticipantData = async () => {
            try {
                const participantsData = {};
                for (const participant of chatRoom.participants) {
                    if (participant?.phone_number) {
                        
                        const userData = participant;

                        const checkin_list = typeof userData.checkin_date === 'string' 
                            ? JSON.parse(userData.checkin_date.replace(/'/g, '"')) 
                            : [userData.checkin_date] || [];

                        const checkout_list = typeof userData.checkout_date === 'string' 
                            ? JSON.parse(userData.checkout_date.replace(/'/g, '"')) 
                            : [userData.checkout_date] || [];

                        const unit_list = typeof userData.unit_no === 'string' 
                            ? JSON.parse(userData.unit_no.replace(/'/g, '"')) 
                            : [userData.unit_no] || [];

                        const buildings = [];
                        // Only create building if we have valid data
                        if (unit_list.length > 0) {
                            const building = {
                                buildingName: participant.name.split(' ')[0],
                                unit: unit_list[0],
                                checkinDate: checkin_list[0],
                                checkoutDate: checkout_list[0]
                            };
                            buildings.push(building);
                        }

                        participantsData[participant?.phone_number] = {
                            ...userData,
                            buildings,
                            checkinDate: checkin_list[0],
                            checkoutDate: checkout_list[0]
                        };

                    }
                }
                setParticipantData(participantsData);
            } catch (error) {
                console.error('Error processing participant data:', error);
            }
        };

        processParticipantData();
    }, [chatRoom.participants, userToken]);

    const handleRightClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setContextMenu(true);
    };

    const handleTouchStart = (event) => {
        event.preventDefault(); // Prevent unwanted browser behaviors
        const timer = setTimeout(() => {
            handleRightClick(event); // Treat long press like a right-click
        }, 500); // Long press threshold (500ms)
        setLongPressTimer(timer);
    };

    const handleTouchEnd = () => {
        clearTimeout(longPressTimer);
    };

    const markAsUnread = async(e) => {
        e.preventDefault();
        e.stopPropagation();

        const resp = await API.markAsUnread(chatRoom.id)
        setChatRooms(prevRooms => {
            const updatedRooms = prevRooms.map(room => 
                room.id === chatRoom.id
                    ? {
                        ...room,
                        // Increment unread count for incoming messages
                        unread_count: 1
                    }
                    : room
            );
            
            return updatedRooms;
        });
        setContextMenu(null);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                setContextMenu(null);
            }
        };
        
        if (contextMenu) {
            document.addEventListener('mousedown', handleClickOutside);
            // Also close menu when scrolling the container
            const container = itemRef.current?.closest('.chatroom-list-container');
            if (container) {
                container.addEventListener('scroll', () => setContextMenu(null));
            }
        }
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            const container = itemRef.current?.closest('.chatroom-list-container');
            if (container) {
                container.removeEventListener('scroll', () => setContextMenu(null));
            }
        };
    }, [contextMenu]);
    
    return (
        <div 
            ref={itemRef}
            className={`chatroom-list ${isSelected ? 'chatroom-selected' : ''}`}
            onClick={handleClick}
            onContextMenu={handleRightClick}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchEnd}
            style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
        >
            <div className="inbox-item-pic">
                {chatRoom.participants.map((participant, index) => (
                    <div key={index}>
                        <b>{getInitial(participant.name)}</b>
                    </div>
                ))}
            </div>
            
            <div className="inbox-item-name">
                {chatRoom.participants.map((participant, index) => {
                    const userData = participantData[participant?.phone_number];
                    const status = getStatus(userData?.checkinDate, userData?.checkoutDate);
                    return (
                        <div key={index}>
                            <div className='inbox-item-name-and-date'>
                                <h3>
                                    {chatRoom.unread_count > 0 && (
                                        <span className={`unread-badge ${isHiding ? 'hiding' : ''}`}>
                                            {chatRoom.unread_count}
                                        </span>
                                    )}
                                    {getUserName(participant.name)}
                                    {contextMenu && (
                                        <div ref={contextMenuRef} className="context-menu" style={{ position: 'fixed', top: `${contextMenu.y}px`, left: `${contextMenu.x}px`,zIndex: 1000}}>
                                            <button 
                                                className="context-menu-item" 
                                                onClick={(e) => markAsUnread(e)}
                                            >
                                                Mark as Unread
                                            </button>
                                            {/* Add more context menu items here */}
                                        </div>
                                    )}
                                </h3>
                                <p className='inbox-item-last-message-timestamp'>
                                    {getMessageDateDisplay(lastMessage.date)}
                                </p>
                            </div>
                            <div className="inbox-item-last-message">
                                <p className='inbox-item-last-message-text'>
                                {lastMessage.type !== 'text' ? 
                                    `${lastMessage.sender ? (lastMessage.sender === 'WabaId' ? 'You: ' : 'Customer: ') : ''}${lastMessage.type}` : 
                                    `${lastMessage.sender ? (lastMessage.sender === 'WabaId' ? 'You: ' : 'Customer: ') : ''}${lastMessage.text}`
                                }
                                </p>
                                {lastMessage.time !== "Invalid Date" && (
                                    <p className='inbox-item-last-message-timestamp'>{lastMessage.time}</p>
                                )}
                            </div>
                            {/* Add building tags */}
                            <div className="building-tags-container">
                                {userData?.buildings?.map((building, idx) => {
                                    const isValidBuilding = massFlowOption?.some(option => 
                                        option.label === building.buildingName
                                    );
                                    
                                    return (isValidBuilding) ? (
                                        <div key={idx} className="building-tag">
                                            {building.buildingName}
                                            {building.unit && ` ${building.unit}`}
                                        </div>
                                    ) : null;
                                })}
                            </div>
                            <div className="status-tag-container">
                                <div className={`status-tag`} style={{'color':status.color, 'backgroundColor': status.backgroundColor}}>
                                    {status.text}
                                </div>
                                {chatRoom.participants.flatMap(participant => {
                                    const userData = participantData[participant?.phone_number];
                                    return userData?.labels?.map((label, idx) => (
                                        <div key={`${participant?.phone_number}-${idx}`} className="label-tag" style={{ backgroundColor: label.color, color: label.text_color}}>
                                            {label.name}
                                        </div>
                                    )) || [];
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>

        </div>
    );
};


function ChatRoomList({ onChatRoomClick, onChatRoomDelete, searchQuery, filters, massFlowOption, setPageTitle}) { 
    const socketRef = useRef();
    const [chatRooms, setChatRooms] = useState([]);
    const [fullChatRoom, setFullChatRoom] = useState([]);
    const { userToken, wabaId } = useContext(UserContext);
    const { toggleConnection } = useWebSocketContext();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedChatRoomId, setSelectedChatRoomId] = useState(null);
    const [isRoomLoading, setIsRoomLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const [loadedPages] = useState(new Set()); // Track which pages we've loaded
    const isLoadingRef = useRef(false); // Use ref to track loading state
    const abortControllerRef = useRef(null);
    const [selectedTags, setSelectedTags] = useState([]);

    // Add cache handling
    const CACHE_KEY = `chatrooms_${wabaId}`;
    const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds

    useEffect(() => {
        const loadFromCache = () => {
            try {
                const cached = localStorage.getItem(CACHE_KEY);
                if (cached) {
                    const { data, timestamp } = JSON.parse(cached);
                    const isExpired = Date.now() - timestamp > CACHE_EXPIRY;
                    
                    if (!isExpired) {
                        console.log('Loading from cache...');
                        setChatRooms(data);
                        const totalUnreadCount = data.reduce((sum, chatRoom) => sum + chatRoom.unread_count, 0);
                        const displayCount = totalUnreadCount - 1;
                        setPageTitle(`StreamHost | Inbox Page${displayCount > 0 ? ` (${displayCount})` : ""}`);
                        return true;
                    } else {
                        localStorage.removeItem(CACHE_KEY);
                    }
                }
                return false;
            } catch (error) {
                console.error('Cache error:', error);
                return false;
            }
        };

        const loadedFromCache = loadFromCache();
        if (!loadedFromCache) {
            fetchChatRooms();
        }
    }, [wabaId]);

    // Modified fetchChatRooms to prevent duplicate page loads
    const fetchChatRooms = useCallback(async (page = 1, isBackground = false) => {
        if (loadedPages.has(page) || isLoadingRef.current) {
            console.log(`⚠️ Page ${page} already loaded or loading in progress`);
            return;
        }

        // Create new AbortController for this fetch
        abortControllerRef.current = new AbortController();

        try {
            isLoadingRef.current = true;
            if (page === 1) {
                setIsLoading(true);
                loadedPages.clear();
            } else if (!isBackground) {
                setIsLoadingMore(true);
            }
            
            const response = await API.getChatRooms3(
                userToken, 
                wabaId, 
                page, 
                abortControllerRef.current.signal
            );
            
            // Update fullChatRoom by appending new results
            setFullChatRoom(prevResults => {
                // If it's the first page, initialize with response.results
                if (page === 1) {
                    return response.results || [];
                }
                // Otherwise, append new results while avoiding duplicates
                const existingIds = new Set(prevResults.map(room => room.id));
                const newResults = response.results.filter(room => !existingIds.has(room.id));
                return [...prevResults, ...newResults];
            });
            
            if (response && response.results) {
                loadedPages.add(page); // Mark this page as loaded

                if (page === 1) {
                    setChatRooms(response.results);
                    const totalUnreadCount = response.results.reduce((sum, chatRoom) => sum + chatRoom.unread_count, 0);
                    const displayCount = totalUnreadCount - 1;
                    setPageTitle(`StreamHost | Inbox Page${displayCount > 0 ? ` (${displayCount})` : ""}`);
                    // Start background loading of next pages after first page is loaded
                    if (response.next) {
                        setTimeout(() => fetchChatRooms(2, true), 1000);
                    }
                } else {
                    setChatRooms(prev => [...prev, ...response.results]);
                    // Continue background loading next page if available
                    if (response.next && isBackground) {
                        setTimeout(() => fetchChatRooms(page + 1, true), 1000);
                    }
                }
                
                setHasMore(!!response.next);
                setCurrentPage(page);
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
                return;
            }
            console.error('❌ Error:', error);
            if (!isBackground) {
                toast.error('Failed to load chatrooms');
            }
        } finally {
            isLoadingRef.current = false;
            setIsLoading(false);
            if (!isBackground) {
                setIsLoadingMore(false);
            }
        }
    }, [userToken, wabaId, loadedPages]);

    // Initial fetch
    useEffect(() => {
        fetchChatRooms(1);
    }, [fetchChatRooms]);

    // Modified scroll handler with debounce
    const handleScroll = useCallback(
        debounce(() => {
            if (!containerRef.current || isLoadingRef.current || !hasMore) return;

            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            const isNearBottom = scrollHeight - scrollTop <= clientHeight + 100;

            if (isNearBottom && !loadedPages.has(currentPage + 1)) {
                fetchChatRooms(currentPage + 1);
            }
        }, 100), // 100ms debounce
    [isLoadingRef, hasMore, currentPage, fetchChatRooms, loadedPages]);

    // Add scroll listener
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    // Update cache when chatrooms change
    useEffect(() => {
        if (chatRooms.length > 0) {
            const cacheData = {
                data: chatRooms,
                timestamp: Date.now()
            };
            localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
        }
    }, [chatRooms]);

    // Clear cache on unmount or when wabaId changes
    useEffect(() => {
        return () => {
            localStorage.removeItem(CACHE_KEY);
        };
    }, [wabaId]);


    // Memoize sorted and filtered chatrooms
    const sortedChatRooms = useMemo(() => {
        const sourceData = (searchQuery === '') ? chatRooms : fullChatRoom;
        // Create a map of existing participant data to preserve it
        const existingParticipantDataMap = new Map();
        chatRooms.forEach(room => {
            room.participants.forEach(participant => {
                if (participant?.phone_number) {
                    existingParticipantDataMap.set(participant?.phone_number, participant);
                }
            });
        });

        const filtered = sourceData.filter(chatRoom => {
            // Name search filter
            const nameMatch = chatRoom.participants.some(participant =>
                participant.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            if (!nameMatch) return false;

            // Preserve participant data when switching between sources
            chatRoom.participants.forEach(participant => {
                if (participant?.phone_number) {
                    const existingData = existingParticipantDataMap.get(participant?.phone_number);
                    if (existingData) {
                        // Preserve existing data
                        participant.buildings = existingData.buildings;
                        participant.checkinDate = existingData.checkinDate;
                        participant.checkoutDate = existingData.checkoutDate;
                        participant.chatflow_id = existingData.chatflow_id;
                        participant.unit_no = existingData.unit_no;
                    }
                }
            });

            // New Contacts filter
            if (filters?.showNewContacts) {
                return !chatRoom.last_message;
            }

            // Building filter
            if (filters?.buildings?.length > 0) {
                const buildingMatch = filters.buildings.some(building => {
                    return chatRoom.participants.some(participant => {
                        const participantTags = participant.name.split(' ');
                        return participantTags.some(tag => tag === building.label);
                    });
                });
                if (!buildingMatch) return false;
            }

            // Unit filter
            if (filters?.units?.length > 0) {
                const unitMatch = filters.units.some(unit => 
                    chatRoom.participants.some(participant => 
                        participant.name.includes(unit.value) // Check if name contains the unit
                    )
                );
                if (!unitMatch) return false;
            }

            if (filters?.filter === 'Unread') {
                const unreadMatch = chatRoom.unread_count > 0;
                if (!unreadMatch) return false;

            }else if(filters?.filter === 'Checked In' || filters?.filter === 'Checked Out' || filters?.filter === 'Not Check In' || filters?.filter === 'Check In Today' || filters?.filter === 'Check Out Today' || filters?.filter === 'No Dates'){
                const checkedInMatch = chatRoom.participants.some(participant => {
                    const checkin_list = typeof participant.checkin_date === 'string' 
                        ? JSON.parse(participant.checkin_date.replace(/'/g, '"')) 
                        : [participant.checkin_date] || [];
                    const checkout_list = typeof participant.checkout_date === 'string' 
                        ? JSON.parse(participant.checkout_date.replace(/'/g, '"')) 
                        : [participant.checkout_date] || [];

                    const status = getStatus(checkin_list[0], checkout_list[0]);
                    return status.text === filters?.filter;
                });
                if (!checkedInMatch) return false;
            }

            return true;
        });

        return [...filtered].sort((a, b) => {
            // If filtering for new contacts, no need to sort
            if (filters?.showNewContacts) return 0;
            
            // Your existing sort logic
            if (!a.last_message && !b.last_message) return 0;
            if (!a.last_message) return 1;
            if (!b.last_message) return -1;
            const timeA = new Date(a.last_message.timestamp).getTime();
            const timeB = new Date(b.last_message.timestamp).getTime();
            return timeB - timeA;
        });
    }, [chatRooms, fullChatRoom, searchQuery, filters]); // Add dependencies

    // WebSocket handler for real-time updates
    useEffect(() => {
        // WebSocket initialization
        socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/chatroomlist/`);
        toggleConnection(false);
        
        // Setup heartbeat interval (5 minutes = 300000 ms)
        const heartbeatInterval = 300000;
        let heartbeatTimer = null;
        
        // Function to send heartbeat
        const sendHeartbeat = () => {
            if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                console.log("[chatroomlist] Sending heartbeat");
                socketRef.current.send(JSON.stringify({ type: 'heartbeat' }));
            } else {
                console.log("[chatroomlist] Connection lost, attempting to reconnect...");
                // Connection lost, try to reconnect
                if (socketRef.current) {
                    socketRef.current.close();
                }
                socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/chatroomlist/`);
                socketRef.current.onopen = handleOpen;
                socketRef.current.onmessage = handleMessage;
            }
        };
    
        // Handle WebSocket open event
        const handleOpen = () => {
            console.log("[chatroomlist] Connected");
            // Start heartbeat timer after connection is established
            if (heartbeatTimer) {
                clearInterval(heartbeatTimer);
            }
            heartbeatTimer = setInterval(sendHeartbeat, heartbeatInterval);
        };
    
        // Handle WebSocket messages
        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            
            // Handle heartbeat response if needed
            if (message.type === 'heartbeat_response') {
                console.log("[chatroomlist] Heartbeat response received");
                return;
            }
            
            if (!message.chatRoomId || !message.timestamp) return;
            
            if (message.message_type === 'new_chatroom') {
                // Fetch the new chatroom details
                API.getChatRooms(userToken, wabaId)
                    .then(fetchedChatRooms => {
                        const newChatRoom = fetchedChatRooms.find(room => room.id === message.chatRoomId);
                        if (newChatRoom) {
                            setChatRooms(prevRooms => {
                                // Check if the room already exists
                                const roomExists = prevRooms.some(room => room.id === newChatRoom.id);
                                if (!roomExists) {
                                    return [...prevRooms, newChatRoom];
                                }
                                return prevRooms;
                            });
                        }
                    })
                    .catch(error => console.error('Error fetching new chatroom:', error));
                return;
            }
            
            setChatRooms(prevRooms => {
                const updatedRooms = prevRooms.map(room => 
                    room.id === message.chatRoomId
                        ? {
                            ...room,
                            last_message: {
                                text: message.message,
                                timestamp: message.timestamp,
                                media_type: message.media_type || 'text',
                                id: message.messageId
                            },
                            // Increment unread count for incoming messages
                            unread_count: message.message_sender === wabaId 
                            ? (room.unread_count || 0)  // Keep current count
                            : (room.unread_count || 0) + 1  // Increment count
                        }
                        : room
                );
                const totalUnreadCount = updatedRooms.reduce((sum, chatRoom) => sum + chatRoom.unread_count, 0);
                const displayCount = totalUnreadCount - 1;
                setPageTitle(`StreamHost | Inbox Page${displayCount > 0 ? ` (${displayCount})` : ""}`);
                return updatedRooms;
            });
        };
    
        // Set event handlers
        socketRef.current.onopen = handleOpen;
        socketRef.current.onmessage = handleMessage;
    
        // Handle WebSocket errors
        socketRef.current.onerror = (error) => {
            console.error("[chatroomlist] WebSocket error:", error);
        };
    
        // Handle WebSocket close events
        socketRef.current.onclose = (event) => {
            console.log("[chatroomlist] WebSocket closed:", event.code, event.reason);
            // Clear heartbeat timer
            if (heartbeatTimer) {
                clearInterval(heartbeatTimer);
                heartbeatTimer = null;
            }
            
            // Attempt to reconnect after a delay if not intentionally closed
            if (!event.wasClean) {
                console.log("[chatroomlist] Connection lost, attempting to reconnect in 5 seconds...");
                setTimeout(() => {
                    socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/chatroomlist/`);
                    socketRef.current.onopen = handleOpen;
                    socketRef.current.onmessage = handleMessage;
                    socketRef.current.onerror = socketRef.current.onerror;
                    socketRef.current.onclose = socketRef.current.onclose;
                }, 5000);
            }
        };
    
        // Cleanup function
        return () => {
            if (heartbeatTimer) {
                clearInterval(heartbeatTimer);
            }
            if (socketRef.current) {
                socketRef.current.close();
                toggleConnection(true);
            }
        };
    }, [userToken, toggleConnection, wabaId]);

    // Modify the handleChatRoomClick to include unread count reset
    const handleChatRoomClick = useCallback(async (chatRoomId, chatRoom) => {
        if (isRoomLoading) {
            console.log('🚫 Blocked - Currently loading another chatroom');
            return;
        }

        const startTime = performance.now();
        console.log(`🕒 Starting to load chatroom ${chatRoomId} at ${new Date().toISOString()}`);

        try {
            setIsRoomLoading(true);
            setSelectedChatRoomId(chatRoomId);
            await onChatRoomClick(chatRoomId, chatRoom);
            
            await API.markAsRead(chatRoomId);

            const totalUnreadCount = chatRooms.reduce((sum, chatRoom) => sum + chatRoom.unread_count, 0);
            const displayCount = totalUnreadCount - 1;
            setPageTitle(`StreamHost | Inbox Page${displayCount > 0 ? ` (${displayCount})` : ""}`);
            const endTime = performance.now();
            const loadTime = (endTime - startTime) / 1000; // Convert to seconds
            console.log(`✅ Chatroom ${chatRoomId} loaded in ${loadTime.toFixed(2)} seconds`);
        } finally {
            setTimeout(() => {
                setIsRoomLoading(false);
            }, 1000);
        }
    }, [isRoomLoading, onChatRoomClick, userToken]);

    // Add cleanup effect
    useEffect(() => {
        return () => {
            // Abort any ongoing fetches when component unmounts
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            // Reset loading states
            isLoadingRef.current = false;
            setIsLoading(false);
            setIsLoadingMore(false);
            // Clear loaded pages
            loadedPages.clear();
        };
    }, []);

    return (
        <div 
            className="chatroom-list-container" 
            ref={containerRef}
        >
            {isLoading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    {sortedChatRooms.map(chatRoom => (
                        <ChatRoomItem 
                            key={chatRoom.id}
                            chatRoom={chatRoom}
                            onChatRoomClick={handleChatRoomClick}
                            isDisabled={isRoomLoading}
                            setChatRooms={setChatRooms}
                            isSelected={selectedChatRoomId === chatRoom.id}
                            massFlowOption={massFlowOption} 
                            selectedTags={selectedTags}
                        />
                    ))}
                    {isLoadingMore && (
                        <div className="loading-more">
                            <div className="loader"></div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default React.memo(ChatRoomList);