import React, {useState, useEffect, useRef, useContext} from "react";
import { UserContext } from "../user-context";
import { API } from "../api-service";
import ChatArea from '../components/ChatArea';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate } from 'react-router-dom';

import './css/StreamhostInbox.css';

function StreamHostInbox () {

    const {userToken, imageUrl, setUserToken, setImageUrl} = useContext(UserContext);
    const [text, setText] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [headerName, setHeaderName] = useState('');
    const socketRef = useRef();
    const [textAreaHeight, setTextAreaHeight] = useState('auto');
    const [audioPreviewUrl, setAudioPreviewUrl] = useState(null);
    const [chatRoomDetails, setChatRoomDetails] = useState([]);
    const [chatRoomDetails2, setChatRoomDetails2] = useState([]); //dummy state to force re-render one time
    const [currentParticipantPhoneNumber, setCurrentParticipantPhoneNumber] = useState();
    const [replyInfo, setReplyInfo] = useState(null);
    const [MessageToChatArea, setMessageToChatArea] = useState(null);
    const [ImageToChatArea, setImageToChatArea] = useState('');
    const [AudioToChatArea, setAudioToChatArea] = useState(null);
    const [replyText, setReplyText] = useState(null);
    const [captionInput, setCaptionInput] = React.useState('');
    const [isSendingFile, setIsSendingFile] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [currentMessages, setCurrentMessages] = useState([]);
    const [showRefreshMessage, setShowRefreshMessage] = useState(false);

    const [hasMore, setHasMore] = useState();
    const [page, setPage] = useState(1);
    const [isConnectionLost, setIsConnectionLost] = useState(false);
    const [clientUser, setClientUser] = useState(null);
    const [chatRoomId, setChatRoomId] = useState(null);
    const { user_id, phone_number, wabaId } = useParams();
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const getChatRoomData = async() => {
            try {
                setIsLoading(true);
                let token = await API.getToken({'user_id': user_id});
                setUserToken(token['token']);
    
                if (token) {
                    const data = await API.fetchProfile(userToken);
                    setImageUrl(data['image']);
                }
    
                let client_user = await API.getClientUser({
                    'token': token['token'], 
                    'phone_number': phone_number.replace(/\s+/g, '').replace('+', ''),
                    'waba_id': wabaId
                });
                setClientUser(client_user)
    
                let chatroom = await API.getChatRoom({
                    'token': token['token'],
                    'user_id': user_id,
                    'phone_number': phone_number.replace(/\s+/g, '').replace('+', ''),
                    'waba_id': wabaId
                });
    
                if ('error' in chatroom) {
                    chatroom = await API.addChatRoom({
                        'token': token['token'],
                        'client_user_id': client_user['id'], 
                        'chatroom_type': 'streamhost',
                        'waba_id': wabaId
                    })
                }
                setChatRoomId(chatroom['id']);
                
                const waba_list = await API.getWabaId(token['token']);
                const waba = waba_list.find(waba => waba.waba_id === wabaId);
                setHeaderName(waba.verified_name);
            } catch (error) {
                console.error('Error fetching chat room data:', error);
            } finally {
                setIsLoading(false);
            }
        }
        getChatRoomData();
    }, [userToken]);

    useEffect(() => {
        setCurrentMessages([]); 
        let timeoutId;
        setShowRefreshMessage(false);
        setHasMore(false);
        setPage(1);

        console.time('🔌 WebSocket Connection');
        const connectStart = performance.now();
        
        socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/chat/`+ chatRoomId);
        
        socketRef.current.onopen = () => {
            const connectEnd = performance.now();
            console.timeEnd('🔌 WebSocket Connection');
            console.log(`✨ WebSocket connected in ${(connectEnd - connectStart).toFixed(2)}ms`);
            
            setIsConnectionLost(false);
            
            setInterval(function() {
                if (socketRef.current.readyState === WebSocket.OPEN) {
                    socketRef.current.send(JSON.stringify({ 
                        message: 'heartbeat', 
                        senderId: wabaId, 
                        chatRoomId: chatRoomId, 
                        message_type: "heartbeat"
                    }));
                }
            }, 60000);
        };

        fetchChatRoomMessages(chatRoomId, userToken);
        
        socketRef.current.onerror = (event) => {
            console.error('WebSocket error:', event);
        };
        

        socketRef.current.onclose = function(event) {   
            if (event.wasClean) {
              console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
              setIsConnectionLost(true);
              timeoutId = setTimeout(() => {
                    setShowRefreshMessage(true);
                }, 10000);
            } else {
              // e.g. server process killed or network down
              // event.code is usually 1006 in this case
              console.log('[close] Connection died');
              setIsConnectionLost(true);
              timeoutId = setTimeout(() => {
                    setShowRefreshMessage(true);
                }, 10000);
            }
          };
          

        // Clean up the WebSocket connection when the component unmounts or the chat room ID changes
        return () => {
            console.log('🔌 Closing WebSocket connection');
            socketRef.current.close();
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
            
    }, [chatRoomId, userToken, wabaId]);

    useEffect(() => {
        
        if (socketRef.current) {
            socketRef.current.onmessage = (event) => {
                const message = JSON.parse(event.data);
                const currentTime = new Date();
                const baseUrl = "https://streamhost-media.s3.ap-southeast-2.amazonaws.com/";
                const formattedCurrentTime = currentTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
               
                
                // Check message type and prepend URL if it's a file
                let messageType = message.message_type;

                let messageContent = message.message;
                // Process message content if it contains a file extension
                if (typeof messageContent === 'string') {
                    const lastDotIndex = messageContent.lastIndexOf('.');
                    if (lastDotIndex !== -1 && messageContent.charAt(lastDotIndex - 1) === '=') {
                        // Remove the '=' before the extension
                        messageContent = messageContent.slice(0, lastDotIndex - 1) + messageContent.slice(lastDotIndex);
                    }
                }

                if (message.message === 'heartbeat') {
                    return;
                }

                else if (messageType === 'text') {
                    
                    const newMessage = {
                        text: messageContent,
                        senderId: message.senderId,
                        timestamp: formattedCurrentTime,
                        media_type: messageType,
                        wamid: message.wamid,
                        reply_to: message.reply_to ? {
                            text: message.reply_to.text,
                            media_type: message.reply_to.media_type,
                            wamid: message.reply_to.wamid
                        } : null
                    };
                    setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
                }
                else {
                    const newMessage = {
                        text: null,
                        senderId: message.senderId,
                        timestamp: formattedCurrentTime,
                        media_type: messageType,
                        image: baseUrl + messageContent,
                        wamid: message.wamid,
                        reply_to: message.reply_to ? {
                            text: message.reply_to.text,
                            media_type: message.reply_to.media_type,
                            wamid: message.reply_to.wamid
                        } : null,
                        caption: message.caption
                    };

                    setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
                }
              
            };
        }
    }, [currentParticipantPhoneNumber, chatRoomId, userToken]);

    const handleReply = (message) => {
        if (message === null) {
            setReplyText(null);
            setReplyInfo(null);
        } else {
            setReplyInfo(message);
            setReplyText(message.text || message.media_type);
        }
    };

    const fetchChatRoomMessages = async(chatRoomId, userToken) => {
        console.log('CHATROOM ID');
        console.log(chatRoomId);
        const startTime = performance.now();
        try {
            console.time('📨 Fetching Messages');
            const response = await API.getChatRoomMessagesNew(chatRoomId, userToken, 1);
            setChatRoomDetails(response.messages ?? []);
            setChatRoomDetails2(response.messages ?? []); 
            setHasMore(response.next);
            setCurrentParticipantPhoneNumber(response.participant_phone_numbers);

            const endTime = performance.now();
            console.timeEnd('📨 Fetching Messages');
            console.log(`✨ Loaded ${response.messages?.length || 0} messages in ${(endTime - startTime).toFixed(2)}ms`);
        } catch (error) {
            console.error('❌ Error loading messages:', error);
        }
    }

    const sendStreamHostMessage = async () => {

        try {
            const response = await API.saveChatRoomMessages({
                message: text,
                client_id: clientUser.id,
                chatRoomId: chatRoomId,
                userToken: userToken,
                wamId: null,
                type: 'streamhost',
                reply_to_id: null
            });
            console.log(response);
        } catch (error) {
            console.error(error);
        }

    }

    const [isSending, setIsSending] = useState(false);

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                setPreviewUrl(fileReader.result);
            };
            fileReader.readAsDataURL(file);
        }
    };


    const handleSend = async () => {
        if (!text && !selectedFile) return;
        setIsSending(true);

        try {
            if (selectedFile) {
                const fileExtension = selectedFile.name?.split('.').pop();
                const uniqueFileName = `${uuidv4()}.${fileExtension}`;

                // Create a new File object with the unique name
                const uniqueFile = new File([selectedFile], uniqueFileName, { type: selectedFile.type });

                const formData = new FormData();
                formData.append('image', uniqueFile);
                formData.append('message', captionInput);
                formData.append('client_id', clientUser.id);
                formData.append('type', 'streamhost');
                formData.append('reply_to_id', replyInfo ? replyInfo.id : null);
                const baseUrl = "https://streamhost-media.s3.ap-southeast-2.amazonaws.com/chat_images/";

                const updatedImage = baseUrl + uniqueFileName;
            
                try { 
                    const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/' + chatRoomId + '/messages/', {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Token ' + userToken
                        },
                        body: formData
                    });

                    if (response.ok) {
                        setImageToChatArea({
                            media: baseUrl + uniqueFileName, 
                            wamid: ''
                        });
                        setPreviewUrl('');
                        setSelectedFile(null);
                    } else {
                        console.error('Failed to upload file:', response.statusText);
                        setIsSendingFile(false);
                    }
                } catch (error) {
                    setIsSendingFile(false);
                    console.error('Error uploading file:', error);
                }

                setSelectedFile(null);
                setPreviewUrl('');
            } else {
                await sendStreamHostMessage();
            }
            setText('');
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsSending(false);
        }
    };

    return (
        <div className="sh-inbox-container">
            {isLoading ? (
                <div className="sh-loading">
                    <div className="sh-spinner"></div>
                    <p>Loading chat room...</p>
                </div>
            ) : (
                <>
                <div className="sh-inbox-header">
                    <div className="sh-header-content">
                        <div className="sh-header-avatar">
                            <img src={imageUrl} alt="Profile"/>
                        </div>
                        <h3 className="sh-header-title">{headerName}</h3>
                    </div>
                </div>
                
                <div className="sh-chat-container">
                    <ChatArea chatRoomId={chatRoomId} MessageToChatArea={MessageToChatArea} ImageToChatArea={ImageToChatArea} AudioToChatArea={AudioToChatArea} socketRef={socketRef} replyText={replyText} isSendingFile={isSendingFile} setIsSendingFile={setIsSendingFile} setSelectedFile={setSelectedFile} setPreviewUrl={setPreviewUrl} onReply={handleReply} setCaptionInput={setCaptionInput} captionInput={captionInput} user={'clientUser'} /> 
                </div>
        
                <div className="sh-message-composer">
                    <div className="sh-textarea-wrapper">
                        {/* File Preview */}
                        {previewUrl && (
                            <div className="sh-file-preview">
                                {selectedFile?.type.startsWith('image/') ? (
                                    <img src={previewUrl} alt="Preview" />
                                ) : (
                                    <div className="sh-file-name">{selectedFile?.name}</div>
                                )}
                                <button 
                                    className="sh-remove-file"
                                    onClick={() => {
                                        setSelectedFile(null);
                                        setPreviewUrl('');
                                    }}
                                >
                                    ×
                                </button>
                            </div>
                        )}
                        
                        <textarea type="text" placeholder='Enter message to send' value={text} onChange={(e) => setText(e.target.value)} style={{ display: audioPreviewUrl ? 'none' : 'block' ,height: textAreaHeight, maxHeight: '200px', fontSize: '1rem'}} className='chat-box-form-input-box' inputMode="text"
                            onKeyDown={async(event) => {
                                if (event.key === 'Enter' && isMobile) {
                                    event.preventDefault();
                                }else if (event.key === 'Enter' && !event.shiftKey && !event.nativeEvent.isComposing && !isMobile) {
                                    event.preventDefault();
                                    sendStreamHostMessage()
                                    setText('');
                                }
                            }
                        }/>
                
                        {/* Attachment Options */}
                        <div className="sh-attachment-options">
                            <input
                                type="file"
                                id="file-input"
                                className="sh-file-input"
                                onChange={handleFileSelect}
                                accept="image/*,video/*,.pdf,.doc,.docx"
                                multiple={false}
                            />
                            <label htmlFor="file-input" className="sh-attachment-button">
                                <span>📎</span>
                            </label>
                        </div>
                    </div>
                    
                    <button 
                        className="sh-send-button"
                        onClick={handleSend}
                        disabled={isSending}
                    >
                        {isSending ? 'Sending...' : 'Send'}
                    </button>
                </div>
                    </>
                )}
        </div>
    );
}

export {StreamHostInbox}