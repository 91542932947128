import React, { useCallback, useState, useMemo, useEffect, useContext, useRef } from 'react';
import ReactFlow, { MiniMap, Controls, Background, applyNodeChanges, applyEdgeChanges, useReactFlow, Panel, ReactFlowProvider} from 'reactflow';
import { Bounce, ToastContainer, toast} from 'react-toastify';
import { FaArrowLeft } from 'react-icons/fa6';
import { StartNode, CheckInNode, CheckOutNode, ConditionNode, MsgNode, TemplateNode, MediaNode, UnitNode } from '../components/CustomNode2.js';
import { WhatsappTemplateProvider, WhatsappTemplateContext } from '../components/WhatsAppTemplate.js';

import html2canvas from 'html2canvas';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../user-context.js';
import { API } from '../api-service.js';
import { IoIosArrowBack } from "react-icons/io";
import SideNav2 from '../components/Sidenav.js';

import '../components/css/CustomNodes.css'
import 'reactflow/dist/style.css';
import './css/Flow.css';


const defaultViewport = { x: 0, y: 0, zoom: 0.8 };


// Main component for the Chatbot Flow
function ChatbotFlow () {

    // Import custom nodes and handle states
    const nodeTypes = useMemo(() => ({ checkInNode: CheckInNode, checkOutNode: CheckOutNode, templateNode: TemplateNode, unitNode: UnitNode}), []);
    const { wabaId, userId, SystemUserToken, userToken } = useContext(UserContext);
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
    const [unitList, setUnitList] = useState([]);
    const { chatflow_id, chatflow_name, chatflow_type } = useParams();
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [chatFlowName, setChatFlowName] = useState('');
    const [rfInstance, setRfInstance] = useState(null); // This is for saving/ exporting the flowchart
    const divRef = useRef(); // Keep track of div to screenshot using html2canvas


    // Setup initial nodes and edges for the flowchartx
    useEffect(() => {
        // FETCH chatflow name
        setChatFlowName(chatflow_name);
        fetchNodes();
        fetchEdges();
    }, []);


    // Helper function
    const safeParseJSON = (str) => {
        if (!str) return { day: '', time: '', template: '' };
        
        try {
            // If str is already an object, return it
            if (typeof str === 'object') return str;
            
            // Clean the string by replacing single quotes with double quotes
            // and handling potential undefined values
            const cleanStr = str.replace(/'/g, '"')
                              .replace(/undefined/g, '""');
            
            const parsed = JSON.parse(cleanStr);
            
            // Validate the structure
            return {
                day: parsed.day || '',
                time: parsed.time || '',
                template: parsed.template || ''
            };
        } catch (e) {
            console.error("Error parsing template data:", str, e);
            // Return default structure if parsing fails
            return {
                day: '',
                time: '',
                template: ''
            };
        }
    };

    // 1. Handle when nodes are clicked, dragged, or removed
    const onNodesChange = useCallback((changes) => {
        const filteredChanges = changes.filter(change => {
            if (change.type === 'remove') {
                const nodeId = change.id;
    
                // Check if node is protected
                const isProtectedNode = nodeId === 'CHECKIN' || 
                                      nodeId === 'CHECKOUT';
                
                if (isProtectedNode) {
                    toast.error('This node cannot be deleted', {
                        position: "bottom-center",
                        autoClose: 2000
                    });
                    return false;
                }
    
                // If node is being deleted, find and remove all descendant nodes
                const nodesToRemove = new Set();
                
                const findDescendantNodes = (currentId) => {
                    // Get all edges where this node is the source
                    const outgoingEdges = edges.filter(edge => edge.source === currentId);
                    
                    // For each connected node, recursively find its descendants
                    outgoingEdges.forEach(edge => {
                        const targetId = edge.target;
                        nodesToRemove.add(targetId);
                        findDescendantNodes(targetId);
                    });
                };
    
                // Start finding descendants from the node being deleted
                findDescendantNodes(nodeId);
    
                // Add deletion changes for all descendant nodes
                setNodes(nds => nds.filter(node => !nodesToRemove.has(node.id)));
    
                // Update edges state to remove connections to deleted nodes
                setEdges(currentEdges => 
                    currentEdges.filter(edge => 
                        !nodesToRemove.has(edge.target) && 
                        !nodesToRemove.has(edge.source) &&
                        edge.source !== nodeId &&
                        edge.target !== nodeId
                    )
                );
            }
            return true;
        });
    
        setNodes((nds) => applyNodeChanges(filteredChanges, nds));
    }, [edges]);

    // 2. Handle when edges are clicked dragged, or removed
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    // 3. Handle when nodes are connected together
    const onConnect = useCallback(
        (connection) => {
            const newEdge = {
                id: connection.source + '-' + connection.target + '-',
                source: connection.source,
                target: connection.target,
                sourceHandle: connection.sourceHandle,
                targetHandle: connection.targetHandle,
            };
            setEdges((prevEdges) => [...prevEdges, newEdge]);
    }, [setEdges])

    // 4. Handle when user wants to save the flowchart
    const onSave = useCallback(() => {

        const validate_status = validateChatFlow();
        // Update nodes, edges and current image state to db
        html2canvas(divRef.current).then(canvas => {
            canvas.toBlob(blob => {
                const formData = new FormData();
                formData.append('chatflow_image', blob, 'thumbnail'+chatflow_id+chatflow_name+'.png');
                formData.append('id', chatflow_id);
                formData.append('token', userToken);
                formData.append('error_status', validate_status);
                formData.append('chatflow_name', chatFlowName);

                // Send image to backend
                API.updateChatFlowImage(formData)
                .catch((error) => {
                    console.error(error);
                });
            });
        });
        addNode(nodes);
        addEdge(edges);
        toast.success('Chatflow saved successfully!', {position: 'bottom-center'})
    }, [nodes, edges, chatFlowName]);

    // 5. Handle changes in node text input
    const onChange = useCallback((id, newValue) => {
        setNodes((nodes) =>
            nodes.map((node) => {
                if (node.id === id) {
                    // This is the node whose input field has changed, update its data
                    return { ...node, data: { ...node.data, 'text': newValue } };
                } else {
                    // This is not the node whose input field has changed, don't modify it
                    return node;
                }
            })
        );
    }, []);

    const fileOnChange = useCallback((id, newValue) => {
        setNodes((nodes) =>
            nodes.map((node) => {
                if (node.id === id) {
                    // This is the node whose input field has changed, update its data
                    return { ...node, data: { ...node.data, 'node_file': newValue} };
                } else {
                    // This is not the node whose input field has changed, don't modify it
                    return node;
                }
            })
        );
    }, []);

    // Handle unit change
    const unitOnChange = useCallback((type, newValue) => {
        if (type === 'add') {
            let status = true;
            setUnitList((prevUnitList) => {
                // Check if the new unit already exists in the list
                if (prevUnitList.includes(newValue)) {
                    status = false;
                    return prevUnitList;
                }
        
                // Append the new unit to the list
                return [...prevUnitList, ...(Array.isArray(newValue) ? newValue : [newValue])];
            });
    
            return status;
        } else if (type === 'remove') {
            setUnitList((prevUnitList) => {
                return prevUnitList.filter(unit => unit !== newValue);
            });
        }
    }, [])

    const openModal = useCallback(() => {
        setIsTemplateModalOpen(true);
    }, [])

    // 6. Handle when users click the back button
    const navigate = useNavigate();
    const onBack = () => {
        navigate('/main/chatflow');
    }

    // 7. validateChatFlow
    const validateChatFlow = () => {
        // 1. Check if there are any nodes and edges
        if (nodes.length === 0 || edges.length === 0) {
            toast.error('Chatflow must have at least one node and one edge!');
            return false;
        }

        // Build adjecency edge for easier graph traversal
        const adjacencyEdge = {};
        let adjecencyValue = [];
        edges.forEach(node => {
            if (!adjacencyEdge[node.source]) {
                adjacencyEdge[node.source] = [];
            }
            adjacencyEdge[node.source].push(node.target);
            adjecencyValue.push(node.target);
        });

        // Build node types for easier graph traversal
        const nodeTypeList = {};
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            nodeTypeList[node.id] = node.type;

            console.log(nodeTypeList[node.id]);
            let isValid = true;
            // Check if nodes are connected (Validation is done here to prevent multiple loops for the same data)
            if ((!adjacencyEdge[node.id] && nodeTypeList[node.id] !== 'templateNode') || (adjecencyValue.includes(node.id) && !(nodeTypeList[node.id] !== 'CHECKIN')) || (adjecencyValue.includes(node.id) && !(nodeTypeList[node.id] !== 'CHECKOUT'))) {
                toast.error('All nodes must be connected!');
                isValid = false;
            }
            // Check if there are nodes that do not consist of any text
            if (nodeTypeList[node.id] === 'unitNode') {
                if (node.data.text === '') {
                    toast.error('All nodes must have text!');
                    isValid = false;
                }
            }
            if (nodeTypeList[node.id] === 'templateNode') {
                const templateData = safeParseJSON(node.data.text);
                if (!templateData.template || !templateData.day || !templateData.time) {
                    toast.error('All data in template node must be filled');
                    isValid = false;
                }
            }
            if (!isValid) {
                return false;
            }
        }

        // If everything is fine
        return true 
    }


    // API Code FETCH, POST and PATCH ------------------------------------------------------------------------------------
    // FETCH Requests
    const fetchNodes = async() => {
        try {
            if (userToken) {
                const initialNodes = await API.getNodes({'token': userToken, 'id': chatflow_id, 'unit':false});
                const formattedNodes = initialNodes.map(node => ({
                    id: node.node_id,
                    type: node.node_type,
                    position: { x: node.position_x, y: node.position_y },
                    data: { text: node.text, node_file: node.node_file, setEdges: setEdges, setNodes: setNodes, onChange: onChange, unitOnChange: unitOnChange, openModal: openModal},
                }));
                formattedNodes.unshift({ id: 'CHECKIN', type:'checkInNode', position:{x: 200, y: 100, },data: {'text': '', setEdges: setEdges, setNodes: setNodes, onChange: onChange, unitOnChange: unitOnChange, openModal: openModal}, });
                formattedNodes.unshift({ id: 'CHECKOUT', type:'checkOutNode', position:{x: 200, y: 400, },data: {'text': '', setEdges: setEdges, setNodes: setNodes, onChange: onChange, unitOnChange: unitOnChange, openModal: openModal}, })
                setNodes(formattedNodes);
            }
        } catch(error) {
            console.error(error);
            throw error;
        }
    }

    const fetchEdges = async() => {
        try {
            if (userToken) {
                const initialEdges = await API.getEdges({'token': userToken, 'id': chatflow_id});
                const formattedEdges = initialEdges.map(edge => ({
                    id: edge.edge_id,
                    source: edge.source,
                    target: edge.target,
                    sourceHandle: edge.sourceHandle,
                    targetHandle: edge.targetHandle,
                }));
                setEdges(formattedEdges);
            }
        } catch(error) {
            console.error(error);
            throw error;
        }
    }


    // POST Requests
    const addNode = async(nodeList) => {
        if (userToken) {

            // Get list of nodes that exists in the database
            const initialNodes = await API.getNodes({'token': userToken, 'id': chatflow_id, unit: false});
            const formattedNodes = initialNodes.map(node => ({
                id: node.id,
                node_id: node.node_id,
                type: node.node_type,
                position: { x: node.position_x, y: node.position_y },
                data: { text: node.text, node_file: node.node_file },
            }));

            // Check each node one by one for changes
            for (const node of nodeList) {
                try {
                    // Check if node already exists in the database
                    const nodeExists = formattedNodes.find(dbNode => dbNode['node_id'] === node['id']);
                    if (nodeExists) {
                        // Check if theres any change in position_x, position_y, or text attribute
                        if (nodeExists['position']['x'] !== node['position']['x'] || nodeExists['position']['y'] !== node['position']['y'] || nodeExists['data']['text'] !== node['data']['text'] || nodeExists['data']['node_file'] !== node['data']['node_file']) {
                            
                            // Update node if there's any change (Use form as there is a file attribute)
                            const formData = new FormData();
                            formData.append('token', userToken);
                            formData.append('id', nodeExists['id']);
                            formData.append('node_id', node['id']);
                            formData.append('position_x', node['position']['x']);
                            formData.append('position_y', node['position']['y']);
                            formData.append('text', node['data']['text'] || '');
                            formData.append('node_file', node['data']['node_file'] || 'null');
                            const response = await API.updateNode(formData);
                        }
                    }else {
                        // Add node if node does not exist in the database
                        const formData = new FormData();
                        formData.append('token', userToken);
                        formData.append('chatflow_id',  chatflow_id);
                        formData.append('node_id', node['id']);
                        formData.append('position_x', node['position']['x']);
                        formData.append('position_y', node['position']['y']);
                        formData.append('text', node['data']['text'] || '');
                        formData.append('node_file', node['data']['node_file'] || 'null');
                        formData.append('node_type', node['type']);
                        const response = await API.addNode(formData);
                    }
                } catch(error){
                    console.error(error);
                    throw error;
                }
            }

            // After updating all nodes, Check if theres any node that was deleted
            const deletedNodes = formattedNodes.filter(dbNode => !nodeList.find(node => node['id'] === dbNode['node_id']));
            for (const deletedNode of deletedNodes) {
                try {
                    const response = await API.deleteNode({'token': userToken, 'id': deletedNode['id']});
                } catch(error) {
                    console.error(error);
                    throw error;
                }
            }
        } 
    }


    const addEdge = async(edgeList) => {
        if (userToken) {
            const initialEdges = await API.getEdges({'token': userToken, 'id': chatflow_id});
            const formattedEdges = initialEdges.map(edge => ({
                id: edge.id,
                edge_id: edge.edge_id,
                source: edge.source,
                target: edge.target,
                sourceHandle: edge.sourceHandle,
                targetHandle: edge.targetHandle,
            }));
            for (const edge of edgeList) {
                try {
                    const edgeExists = formattedEdges.find(dbEdge => dbEdge['edge_id'] === edge['id']);
                    if (edgeExists) {
                        if (edgeExists['source'] !== edge['source'] || edgeExists['target'] !== edge['target'] || edgeExists['sourceHandle'] !== edge['sourceHandle'] || edgeExists['targetHandle'] !== edge['targetHandle']) {
                            const edge_id = edge['source'] + '-' + edge['target'];
                            const response = await API.updateEdge({
                                'token': userToken,
                                'id': edgeExists['id'],
                                'edge_id': edge_id,
                                'source': edge['source'],
                                'target': edge['target'],
                                'sourceHandle': edge['sourceHandle'],
                                'targetHandle': edge['targetHandle'],
                            });
                        }
                        
                    }else {
                        const response = await API.addEdge({
                            'token': userToken,
                            'edge_id': edge['id'],
                            'source': edge['source'],
                            'target': edge['target'],
                            'sourceHandle': edge['sourceHandle'],
                            'targetHandle': edge['targetHandle'],
                            'chatflow_id': chatflow_id
                        });
                    }
                } catch(error) {
                    console.error(error);
                    throw error;
                }
            }
            const deletedEdges = formattedEdges.filter(dbEdge => !edgeList.find(edge => edge['id'] === dbEdge['edge_id']));

            // If no edge has been deleted
            if (!deletedEdges) {
                return;
            }
            for (const deletedEdge of deletedEdges) {
                try {
                    const response = await API.deleteEdge({'token': userToken, 'id': deletedEdge['id']});
                } catch(error) {
                    console.error(error);
                    throw error;
                }
            }
        }
    }
    // ----------------------------------------------------------------------------------------------------------------


    // Add Whatsapp Template Function
    // For adding whatsapp template
    const {whatsappTemplates, setWhatsappTemplates} = useContext(WhatsappTemplateContext);
    const [isSaving, setIsSaving] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [templateCategory, setTemplateCategory] = useState("UTILITY");
    const [templateHeader, setTemplateHeader] = useState("");
    const [templateBody, setTemplateBody] = useState("");
    const [bodyParameters, setBodyParameters] = useState([]);
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState("");
    const [headerTextVisibility, setHeaderTextVisibility] = useState(true);
    const [headerFileVisibility, setHeaderFileVisibility] = useState(true);
    const [focusedInput, setFocusedInput] = useState(null);
    const [isAddingTemplate, setIsAddingTemplate] = useState(false);
    const buttonText = [
        { text: "Guest's Name", value: "guest_name" },
        { text: "Guest's Phone", value: "guest_phone" },
        { text: "Check-in date", value: "checkin_date" },
        { text: "Check-out date", value: "checkout_date" },
        { text: "Unit no", value: "unit_no" },
        { text: "Building", value: "building" },
        { text: "Main door passcode", value: 'maindoor_passcode'},
        { text: "Mailbox passcode", value: 'mailbox_passcode'},
        { text: "Wifi username", value: 'wifi_username'},
        { text: "Wifi password", value: 'wifi_password'},
        { text: "Parking", value: 'parking'},
    ];
    const headerInputRef = useRef(null);
    const bodyInputRef = useRef(null);

    // Whatsapp template function
    const resetForm = () => {
        setTemplateName("");
        setTemplateCategory("UTILITY");
        setTemplateHeader("");
        setTemplateBody("");
        setBodyParameters([]);
        setFile(null);
        setHeaderTextVisibility(true);
        setHeaderFileVisibility(true);
        setFocusedInput(null);
    };

    const closeModal = () => {
        resetForm();
        setIsTemplateModalOpen(false);
    };

    const createWhatsAppTemplate = useCallback(async () => {
        setIsSaving(true);
        let count = 1;

        const replacePlaceholders = (text) => {
            let count = 1;

            // Use regular expression to find all placeholders and replace them with numbered ones
            return text?.replace(/{{\s*[^}]+\s*}}/g, () => `{{${count++}}}`);
        };

        if (!templateName) {
            toast.error("Please enter template name");
            setIsSaving(false);
            return;
        }

        if (!(templateBody && (!!templateHeader || !!file))) {
            toast.error("Text header or file cannot be empty");
            setIsSaving(false);
            return;
        }

        try {
            let components = [];

            // 1. Handle template file
            if (file !== null) {
                // Determine the file type and set the format accordingly
                const fileType = file.type.split("/")[0];
                let format = "";
                if (fileType === "image") {
                    format = "IMAGE";
                } else if (fileType === "video") {
                    format = "VIDEO";
                } else if (fileType === "application") {
                    format = "DOCUMENT";
                }

                const uploadResponse = await API.resumableUpload({
                    file_name: file["name"],
                    file_length: file["size"],
                    file_type: file["type"],
                    token: SystemUserToken,
                });
                const uploadSessionId = JSON.parse(uploadResponse)[
                    "id"
                ].replace(",", "");

                // 2. Upload file to WhatsApp resumable upload session to get the resumable upload id
                const handleData = new FormData();
                handleData.append("file", file);
                handleData.append("upload_id", uploadSessionId);
                handleData.append("waba_access_token", SystemUserToken);
                const uploadHandleResponse = await API.whatsappUploadHandle(
                    handleData
                );
                const uploadHandle = uploadHandleResponse["h"];

                components.push({
                    type: "HEADER",
                    format: format,
                    example: {
                        header_handle: [uploadHandle],
                    },
                });
            }

            // 2. Handle template header

            if (templateHeader !== "") {
                let headerComponent = {
                    type: "HEADER",
                    format: "TEXT",
                    text: replacePlaceholders(templateHeader),
                };

                const actualHeaderParameter =
                    templateHeader.match(/{{\s*([^}]+)\s*}}/);
                if (actualHeaderParameter !== null) {
                    headerComponent["example"] = {
                        header_text: [actualHeaderParameter[1]],
                    };
                }

                console.log(headerComponent);
                components.push(headerComponent);
            }

            // 3. Handle template body
            let bodyComponent = {
                type: "BODY",
                text: replacePlaceholders(templateBody),
            };
            if (bodyParameters !== null && Array.isArray(bodyParameters)) {
                bodyComponent["example"] = {
                    body_text: [bodyParameters],
                };
            }
            components.push(bodyComponent);
            console.log({
                name: templateName,
                language: "en_US",
                category: templateCategory,
                components: JSON.stringify(components),
            });

            // 4. Create whatsapp template
            const response = await fetch(
                "https://graph.facebook.com/v20.0/" +
                    wabaId +
                    "/message_templates",
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + SystemUserToken,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name: templateName,
                        language: "en_US",
                        category: templateCategory,
                        components: JSON.stringify(components),
                    }),
                }
            );
            const resp = await response.json();
            console.log(resp);
            if (resp["id"]) {
                // Fetch updated template list after successful creation
                const updatedResponse = await fetch(
                    "https://graph.facebook.com/v20.0/" + wabaId + "/message_templates?limit=250&fields=name,status",
                    {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer " + SystemUserToken,
                        },
                    }
                );
                const updatedResp = await updatedResponse.json();
                const templateList = updatedResp?.data.map((template) => ({
                    value: template.status,
                    label: template.name,
                }));
                setWhatsappTemplates(templateList);

                toast.success("Template added successfully");
                closeModal();
            } else {
                toast.error(resp["error"]["error_user_msg"]);
            }
        } catch (error) {
            console.error("Error creating template:", error);
            toast.error("Failed to create template");
        } finally {
            setIsSaving(false);
        }
    }, [SystemUserToken, 
        wabaId,
        templateName,
        templateBody,
        templateHeader,
        file,
        templateCategory,
        bodyParameters,
        setWhatsappTemplates]);

    // Display uploaded file
    const handleFileChange = useCallback((event) => {
        const selectedFile = event.target.files[0];
        const MAX_FILE_SIZE = 16 * 1024 * 1024; // 16MB in bytes

        if (selectedFile) {
            // Check file size
            if (selectedFile.size > MAX_FILE_SIZE) {
                toast.error(
                    "File size exceeds 16MB limit. Please choose a smaller file."
                );
                event.target.value = ""; // Reset file input
                return;
            }

            setFile(selectedFile);
            // Create URL only once when file is selected
            const url = URL.createObjectURL(selectedFile);
            setFileUrl(url);
            setHeaderFileVisibility(true);
            setHeaderTextVisibility(false);

            // Clean up previous URL if it exists
            return () => URL.revokeObjectURL(url);
        } else {
            setHeaderFileVisibility(true);
            setHeaderTextVisibility(true);
        }
    }, []);

    // Remove uploaded file
    const handleRemoveFile = useCallback(() => {
        if (fileUrl) {
            URL.revokeObjectURL(fileUrl);
            setFile(null);
            setHeaderFileVisibility(true);
            setHeaderTextVisibility(true);
        }
    }, [fileUrl]);

    const handleTemplateHeaderChange = useCallback((e) => {
        if (e.target.value !== "") {
            setHeaderTextVisibility(true);
            setHeaderFileVisibility(false);
        } else {
            setHeaderTextVisibility(true);
            setHeaderFileVisibility(true);
        }
        setTemplateHeader(e.target.value);
        const templateParameters = e.target.value.match(/{{(.*?)}}/g);
        if (templateParameters?.length > 1) {
            toast.error("There can only be one parameter in the header text");
        }
    }, []);

    const handleInputFocus = useCallback((inputType) => {
        setFocusedInput(inputType);
    }, []);

    const appendText = (text) => {
        if (focusedInput === "header") {
            const templateParameters = templateHeader.match(/{{(.*?)}}/g);
            if (templateParameters?.length < 1 || templateParameters === null) {
                const cursorPos = headerInputRef.current.selectionStart;
                const textBefore = templateHeader.substring(0, cursorPos);
                const textAfter = templateHeader.substring(cursorPos);

                const newText = textBefore + text + textAfter;
                setTemplateHeader(newText);

                // After state update, restore cursor position
                setTimeout(() => {
                    headerInputRef.current.focus();
                    const newCursorPos = cursorPos + text.length;
                    headerInputRef.current.setSelectionRange(
                        newCursorPos,
                        newCursorPos
                    );
                }, 0);
            } else {
                toast.error(
                    "There can only be one parameter in the header text"
                );
            }
        } else if (focusedInput === "body") {
            const cursorPos = bodyInputRef.current.selectionStart;
            const textBefore = templateBody.substring(0, cursorPos);
            const textAfter = templateBody.substring(cursorPos);

            const newText = textBefore + text + textAfter;
            setTemplateBody(newText);

            // Update parameters after inserting at cursor position
            const templateParameters = newText
                .match(/{{\s*(.*?)\s*}}/g)
                ?.map((param) => param.replace(/{{\s*|\s*}}/g, ""));
            setBodyParameters(templateParameters);

            // After state update, restore cursor position
            setTimeout(() => {
                bodyInputRef.current.focus();
                const newCursorPos = cursorPos + text.length;
                bodyInputRef.current.setSelectionRange(
                    newCursorPos,
                    newCursorPos
                );
            }, 0);
        }
    };

    const handleTemplateBodyChange = (e) => {
        setTemplateBody(e.target.value);
        const templateParameters = e.target.value
            .match(/{{\s*(.*?)\s*}}/g)
            ?.map((param) => param.replace(/{{\s*|\s*}}/g, ""));
        setBodyParameters(templateParameters);
    };

    return (
        <div className='flow-content'>
            <div className='flow-topBar'>
                <div className='flow-head'>
                    <button className='flow-back' onClick= { onBack }><FaArrowLeft/></button>
                    <input type='text' value={chatFlowName} onChange={(evt)=>setChatFlowName(evt.target.value)}/>
                </div>
                <div className='flow-save'>
                    <button onClick={onSave}>Save draft</button>
                </div>
            </div>

            <div className='flow-container' ref={divRef}>
            <ReactFlow 
                nodeTypes={nodeTypes} 
                nodes={nodes} 
                edges={edges} 
                defaultEdgeOptions={{animated: true}}  
                onNodesChange={onNodesChange} 
                onEdgesChange={onEdgesChange} 
                onConnect={onConnect} 
                onInit={setRfInstance} 
                defaultViewport={defaultViewport}
                nodesDraggable={false}
            >
                    <Background variant='dots'/>
                    <MiniMap zoomable pannable/>
                </ReactFlow>
            </div>     
            <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />   
            {isTemplateModalOpen && (
                <main className={`test-modal-main ${isTemplateModalOpen ? "open" : ""}`}>
                    <div className="settings-template-modal-container">
                        <div className="whatsapp-template-modal-content">
                            <div className="test-modal-header">
                                <IoIosArrowBack
                                    style={{ fontSize: "1.5rem" }}
                                    className="test-modal-icon"
                                    onClick={closeModal}
                                />
                                <h2>Create new whatsapp template</h2>
                            </div>

                            <div className="settings-template-overview">
                                {/* Actual add template */}
                                <div className="add-template-container">
                                    <div className="add-template-body">
                                        {/* Template name & Category */}
                                        <div className="add-template-header">
                                            <div className="input-container">
                                                <input type="text" id="templateName" placeholder="Enter Template Name" value={templateName} onChange={(e) =>setTemplateName(e.target.value)}/>
                                                <label htmlFor="templateName">
                                                    Enter template Name
                                                </label>
                                            </div>
                                            <div className="input-container">
                                                <select type="text" id="templateCategory" placeholder="Select template category" value={templateCategory} onChange={(e) => setTemplateCategory(e.target.value)}>
                                                    <option value="UTILITY">
                                                        UTILITY
                                                    </option>
                                                    <option value="MARKETING">
                                                        MARKETING
                                                    </option>
                                                    <option value="AUTHENTICATION">
                                                        AUTHENTICATION
                                                    </option>
                                                </select>
                                                <label htmlFor="templateCategory">
                                                    Select template category
                                                </label>
                                            </div>
                                        </div>

                                        {/* Template file section */}
                                        <div style={{display: headerFileVisibility ? "flex" : "none",}} className="add-template-file">
                                            <input type="file" onChange={handleFileChange} accept="image/*,video/*,.pdf,.doc,.docx"/>
                                            <div className="file-input-info">
                                                <span className="file-size-limit">
                                                    Maximum file size: 16MB
                                                </span>
                                            </div>
                                            {file && (
                                                <div className="template-file-preview">
                                                    {file.type.startsWith("image/") && (
                                                        <img src={fileUrl} alt="Preview" style={{maxWidth:"80%",}}/>
                                                    )}
                                                    {file.type === "application/pdf" && (
                                                        <object data={fileUrl} type="application/pdf" className="w-full h-64 rounded-lg">
                                                            <p>
                                                                PDF preview not available
                                                            </p>
                                                        </object>
                                                    )}
                                                    {file.type.startsWith("video/") && (
                                                        <video controls src={fileUrl} style={{ width: "80%",}}/>
                                                    )}
                                                    <button onClick={handleRemoveFile}className="remove-file-button">
                                                        {" "}
                                                        x{" "}
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        {/* Template content: First name last name etc */}
                                        <div className="add-template-text-btn">
                                            {buttonText.map(
                                                (text, index) => (
                                                    <button
                                                        onClick={() =>
                                                            appendText(
                                                                `{{${text.value}}}`
                                                            )
                                                        }
                                                    >
                                                        {text.text}
                                                    </button>
                                                )
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                display:
                                                    headerTextVisibility
                                                        ? "block"
                                                        : "none",
                                            }}
                                            className="input-container"
                                        >
                                            <input
                                                placeholder="Enter message header"
                                                value={templateHeader}
                                                onChange={
                                                    handleTemplateHeaderChange
                                                }
                                                ref={headerInputRef}
                                                onFocus={() =>
                                                    handleInputFocus(
                                                        "header"
                                                    )
                                                }
                                            />
                                            <label htmlFor="messageHeader">
                                                Enter message header
                                            </label>
                                        </div>

                                        <div className="input-container">
                                            <textarea
                                                id="message-body"
                                                placeholder="Enter message body"
                                                value={templateBody}
                                                onChange={
                                                    handleTemplateBodyChange
                                                }
                                                style={{
                                                    height: "100px",
                                                    resize: "vertical",
                                                }}
                                                ref={bodyInputRef}
                                                onFocus={() =>
                                                    handleInputFocus("body")
                                                }
                                            />
                                            <label htmlFor="message-body">
                                                Enter message body
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Template Preview Section */}
                                <div className="add-template-preview">
                                    <div>
                                        {file && (
                                            <div className="template-file-preview">
                                                {file.type.startsWith(
                                                    "image/"
                                                ) && (
                                                    <img
                                                        src={fileUrl}
                                                        alt="Preview"
                                                        style={{
                                                            maxWidth: "80%",
                                                        }}
                                                    />
                                                )}
                                                {file.type ===
                                                    "application/pdf" && (
                                                    <object
                                                        data={fileUrl}
                                                        type="application/pdf"
                                                        className="w-full h-64 rounded-lg"
                                                    >
                                                        <p>
                                                            PDF preview not
                                                            available
                                                        </p>
                                                    </object>
                                                )}
                                                {file.type.startsWith(
                                                    "video/"
                                                ) && (
                                                    <video
                                                        controls
                                                        src={fileUrl}
                                                        className="max-w-full h-auto rounded-lg"
                                                    />
                                                )}
                                            </div>
                                        )}
                                        <h3>{templateHeader}</h3>
                                        <pre>{templateBody}</pre>
                                    </div>
                                </div>
                            </div>

                            {/* Add template button */}
                            <div className="settings-add-template-btn">
                                <button
                                    onClick={createWhatsAppTemplate}
                                    disabled={isSaving}
                                >
                                    {isSaving
                                        ? "Loading..."
                                        : "Save Template"}
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </div>        
    );
}


function StreamFlow() {
    return(
        <div style={{width: '100dvw', height: '100dvh'}}>
            <ReactFlowProvider>
                <WhatsappTemplateProvider>
                    <ChatbotFlow/>
                </WhatsappTemplateProvider>
            </ReactFlowProvider>
        </div>
    )
}


export default StreamFlow;