import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../user-context";
import Sidenav2 from "../components/Sidenav";
import { API } from "../api-service";
import Select from 'react-select';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { useAddContact, AddContactButton, EditModal } from '../components/AddContactButton';
import HelpButton from '../components/HelpButton.js';
import { Bounce, ToastContainer, toast} from 'react-toastify';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';

import { MdDelete } from "react-icons/md";
import { IoHomeSharp } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";

import '../components/css/ContactModal.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/material.css';
import 'react-phone-input-2/lib/bootstrap.css';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import './css/ChatFlowList.css';


const StyledDateTimePicker = styled(DatePicker)`
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        transition: none;

        &:focus {
        margin: 0;
        outline: none; /* Remove default focus outline */
        }
        `;

function ChatFlowList() {

    // Maintain page state
    const { userToken, userId, wabaId, SystemUserToken, waPhoneNumber, waPhoneNumberId, subscriptionPlan } = useContext(UserContext);
    const navigate = useNavigate();
    const [streamFlowData, setStreamFlowData] = useState([]);
    const [massFlowData, setMassFlowData] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [selectedCard, setSelectedCard] = useState(null);
    const [clientUserLength, setClientUserLength] = useState(0);
    const [editContactName, setEditContactName] = useState('');
    const [editBuildings, setEditBuildings] = useState([]);
    const [existingContactData, setExistingContactData] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [unitList, setUnitList] = useState({});
    const [editClientUserId, setEditClientUserId] = useState('');
    const [rowData, setRowData] = useState([]);
    const closeModal = () => {
        setIsModalOpen(false);
    };

    
    // Select button state
    const [massFlowOption, setMassFlowOption] = useState([])
    const [unitOption, setUnitOption] = useState([]);
    const [contactName, setContactName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [buildings, setBuildings] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // Edit template
    const [editChatFlowName, setEditChatFlowName] = useState('');
    const [editCheckInTime, setEditCheckInTime] = useState('');
    const [editCheckOutTime, setEditCheckOutTime] = useState('');
    const [checkInDay, setCheckInDay] = useState('Day of');
    const [checkOutDay, setCheckOutDay] = useState('Day of');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTags, setSelectedTags] = useState([]);
    const [labelTag, setLabelTag] = useState([]);
    const [showLabelInput, setShowLabelInput] = useState(false);
    const [newLabel, setNewLabel] = useState("");
    const [newColor, setNewColor] = useState("");
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [filterModal, setFilterModal] = useState(false);
    const [currentParticipantLabels, setCurrentParticipantLabels] = useState([]);
    const [overallTag, setOverallTag] = useState([]);

    const handleTagClick = (tag) => {
        setSelectedTags((prevSelected) =>
            prevSelected.includes(tag.id)
                ? prevSelected.filter((tagId) => tagId !== tag.id)
                : [...prevSelected, tag.id]
        );
        console.log('label_ids being sent to API:', selectedTags);
    };

    const allowedColors = ["#FFD29D", "#FFB6A0", "#A3C4F3","#B5EAD7","#C3B1E1","#F7C6C7","#E0E0E0","#FBE192","#A2D2FF","#D4A5A5"];

    const handleAddLabel = async() => {
        if (newLabel && newColor) {

            const response = await API.createLabel({'label_name': newLabel, 'label_color': newColor, 'token': userToken});
            console.log(response);
            setLabelTag([...labelTag, { label: newLabel, color: newColor }]);
            setNewLabel("");
            setNewColor("");
            setShowLabelInput(false);
        }
    };

    // TODO: Update this with alvin and add Addon amount from user
    const planContactLimit = {
        'Free': 10,
        'Small': 200,
        'Medium': 990,
        'Large': Infinity
    }

    // Fetch data from API
    useEffect(() => {
        if(userToken) {
            const fetchBuildingAndUnit = async() => {
                setIsLoading(true);
                try {
                    const data= await API.getChatFlowList(userToken);
                    // Massflow data
                    const filteredData = data.filter(chatflow => chatflow.type === 'massflow' && chatflow.error_status===true).map(chatflow => ({value: chatflow.id, label: chatflow.chatflow_name}))
                    setStreamFlowData(data.filter(chatflow => chatflow.type === 'streamflow'));
                    setMassFlowData(data.filter(chatflow => chatflow.type === 'massflow'));
                    setMassFlowOption(filteredData);

                    // Unit data
                    let mappedUnit = {};

                    const fetchUnitNodes = async (chatflow) => {
                        try {
                            const response = await API.getNodes({ 'token': userToken, 'id': chatflow.value, 'unit': false});
                            const unitNodes = response?.filter(node => node.node_type === 'unitNode');
                            
                            // Combine the text fields into a single array
                            const unitNodeComplete = unitNodes.reduce((acc, node) => {
                                const nodeTextArray = JSON.parse(node.text);
                                return [...acc, ...nodeTextArray];
                            }, []);
                            
                            mappedUnit[chatflow.label] = unitNodeComplete.map(node => ({ value: node, label: node }));
                        } catch (error) {
                            console.error(error);
                        }
                    };

                    await Promise.all(filteredData.map(chatflow => fetchUnitNodes(chatflow)));
                    setUnitOption(mappedUnit);
                } catch(error) {
                    console.error(error);
                }
                finally {
                    setIsLoading(false);
                }
            }

            const getClientUserList = async() => {
                try {
                    const data = await API.getClientUserList(userToken);
                    setClientUserLength(data.length);
                } catch (error) {
                    console.error(error);
                }
            }
            fetchBuildingAndUnit();
            getClientUserList();
        }

        // Three dots button function
        // Add event listener to document
        const handleDocumentClick = () => {
            setSelectedCard(null);
        };

        document.addEventListener('click', handleDocumentClick);

        // Cleanup function to remove event listener
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    },[userToken]);


    useEffect(() => {
        if (selectedCard !== null) {
            const selectedChatflow = massFlowData[selectedCard];
            setEditChatFlowName(selectedChatflow.chatflow_name);
            setCheckInDay(convertNumberToPhrase(selectedChatflow.checkin_day));
            setCheckOutDay(convertNumberToPhrase(selectedChatflow.checkout_day));
            setEditCheckInTime(selectedChatflow.send_checkin_schedule);
            setEditCheckOutTime(selectedChatflow.send_checkout_schedule);
        }
    }, [selectedCard, massFlowData]);

    // Handle delete chatflow button
    const handleThreeDotsClick = (event, index) => {
        event.stopPropagation(); // Prevents the document click event from being triggered
        setSelectedCard(index);
    };
      

    const deleteChatflow = async(event, chatflow_id) => {
        event.stopPropagation(); // Prevents the document click event from being triggered

        // Ask for user confirmation before deleting
        const userConfirmed = window.confirm('Are you sure you want to delete this ChatFlow?');

        if (!userConfirmed) {
            return; // Exit the function if the user cancels the action
        }
        try {
            const response = await API.deleteChatFlow({'token': userToken, 'id': chatflow_id});

            // Remove the deleted chatflow from the state
            if(!activeTab) {
                setMassFlowData(massFlowData.filter(chatflow => chatflow.id !== chatflow_id));
            }else {
                setStreamFlowData(streamFlowData.filter(chatflow => chatflow.id !== chatflow_id));
            }

            toast.success('ChatFlow deleted successfully');
            setSelectedCard(null);
            
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    // Chatflow button onClick function
    const chatflowButton = (chatflow_id, chatflow_name, chatflow_type) => {
        navigate('/main/massflow/'+ chatflow_id + '/' + chatflow_name + '/' + chatflow_type);
    };

    const fetchLabels = async () => {
        try {
          const labels = await API.listLabel({ token: userToken });
          setLabelTag(labels);
        } catch (error) {
          console.error('Error fetching labels:', error);
        }
      };

    // MassFlow send message window
    const openModal = async (e, chatflow) => {
        e.stopPropagation();
        if (clientUserLength >= planContactLimit[subscriptionPlan]) {
            toast.error('You have reached the maximum number of contacts this month! Please consider add-ons or wait next month');
            return;
        }
        
        try {
            const labelsResponse = await API.listLabel({ token: userToken });
            setLabelTag(labelsResponse); 
            
            setIsModalOpen(true);
            setModalData({
                chatflow_id: chatflow.id,
                chatflow_name: chatflow.chatflow_name,
                chatflow_type: chatflow.type
            });
        } catch (error) {
            console.error('Error fetching labels:', error);
            // You might want to show an error toast here
            toast.error('Failed to load labels. Please try again.');
        }
    };


    // Edit chatflow button -------------------------------------------------------------------
    function convertPhraseToNumber(phrase) {
        if (phrase.includes("Day of")) {
            return 0;
        }
        let sign = 1;
        if (phrase.includes("before")) {
            sign = -1;
        }
        const numberMatch = phrase.match(/\d+/);
        if (numberMatch) {
            const number = parseInt(numberMatch[0], 10);
            console.log(sign * number);
            return sign * number;
        }
        throw new Error("Invalid phrase format");
    }

    function convertNumberToPhrase(number) {
        let signPhrase = "";
        let absNumber = Math.abs(number);
    
        if (number > 0) {
            signPhrase = "days after";
        } else if (number < 0) {
            signPhrase = "days before";
        } else {
            return "Day of"; // Handle the case for zero
        }
    
        return `${absNumber} ${signPhrase}`;
    }

    function formatTime(timeString) {
        // Split the time string by colon
        const [hours, minutes] = timeString.split(':');
        // Return the formatted time string
        return `${hours}:${minutes}`;
    }


    const editChatFlow = async(e) => {
        e.stopPropagation();
        try {
            const response = await API.editChatFlow({
                'token': userToken,
                'id': massFlowData[selectedCard].id,
                'chatflow_name': editChatFlowName,
                'checkin_day': convertPhraseToNumber(checkInDay),
                'checkout_day': convertPhraseToNumber(checkOutDay),
                'send_checkin_schedule': editCheckInTime,
                'send_checkout_schedule': editCheckOutTime
            });
            toast.success('ChatFlow updated successfully');
            setSelectedCard(null);
        } catch (error) {
            toast.error('Error updating chatflow');
            console.error(error);
        }
    }
    // -----------------------------------------------------------------------------------------------

    // Create ChatFlow button onClick function
    const createChatFlow = async() => {
        if(userToken) {
            // Get the chatflow data ready
            let chatFlowId = '';
            let chatFlowType = '';
            if (!activeTab) {
                chatFlowId = 'MASSFLOW-'+ Date.now();
                chatFlowType = 'massflow';
            }else {
                chatFlowId = 'STREAMFLOW-'+ Date.now(); 
                chatFlowType = 'streamflow';
            }
            const data = {
                'token': userToken,
                'chatflow_id': chatFlowId,
                'chatflow_type': chatFlowType,
                'chatflow_name': 'New ChatFlow',
            }

            // Create chatflow in database
            try {
                const response = await API.addChatFlow(data);
                if(response) {
                    navigate('/main/streamflow/'+response['id']+ '/' + response['chatflow_name'] + '/' + response['type']);
                }
            } catch (error) {
                console.error(error);
            }            
        }
    }
    // -----------------------------------------------------------------------------------------------

    // Contact modal state handling -------------------------------------------------------------------
    const deleteBuilding = (index, id) => {
        setBuildings(prevBuildings => 
            prevBuildings.map(building => {
                console.log(building.id, id);
                return building.id === id ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleAddBuilding = () => {
        setBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false}]);
        setTimeout(() => {
            setBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true, sendCheckIn: false, sendCheckOut: false }
                )
            );
        }, 1);
    }

    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    const handleTemplateChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleUnitChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleCheckInDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleCheckOutDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const handleSendCheckIn = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckIn: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleSendCheckOut = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOut: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleEditTemplateChange = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleEditUnitChange = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleEditCheckInDateChange = (index, date) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleEditCheckOutDateChange = (index, date) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const handleEditSendCheckIn = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckIn: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleEditSendCheckOut = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOut: selectedOption.target.checked
            };
            return updatedBuildings;
        }); 
    }

    const deleteEditBuilding = (index, id) => {
        setEditBuildings(prevBuildings => 
            prevBuildings.map(building => {
                return building.id === id + index ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setEditBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleEditAddBuilding = () => {
        setEditBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false}]);
        setTimeout(() => {
            setEditBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true, sendCheckIn: false, sendCheckOut: false,}
                )
            );
        }, 1);
    }

    const editContact = async() => {

        // Validations
        if (editBuildings.length === 0) {
            toast.error('Please add at least one building');
            return;
        }
        

        // Prepare data to send to API while validating form
        let checkin = [];
        let checkout = [];
        let unit = [];
        let templateLabel = [];
        let templateValue = [];
        let checkInBoolean = [];
        let checkOutBoolean = [];
        let checkInList = [];
        let checkOutList = [];
        let status = true;
        editBuildings.map(building => {

            // Validations
            if (!building.template || !building.unit || !building.checkIn || !building.checkOut) {
                toast.error('Please fill in all the fields');
                status = false;
                return;
            }
            
            if (!unitList[building.template.label].includes(building.unit)) {
                status = false;
                toast.error(`Please update unit for ${building.template.label}`);
                return;
            }

            // Check date validation
            const checkIn = new Date(building.checkIn);
            const checkOut = new Date(building.checkOut);
            if (checkIn >= checkOut) {
                status = false;
                toast.error('Check-out date must be after check-in date');
                return;
            }

            // If data no problem, append to list for API data preparation
            checkin.push(checkIn);
            checkout.push(checkOut);
            unit.push(building.unit);
            templateLabel.push(building.template.label);
            templateValue.push(building.template.value);
            checkInBoolean.push(building.sendCheckIn);
            checkOutBoolean.push(building.sendCheckOut);
        });

        // If everything goes well and pass the validation, then only edit contact
        if (!status) {
            return;
        }
        // Set contact Name
        // Initialize the formatted contact name
        let formattedContactName = "";
        // Iterate over the arrays and format the contact name
        for (let i = 0; i < templateLabel.length; i++) {
            if (i > 0) {
                formattedContactName += ", ";
            }
                // Reformat date and add user into contact
            let checkInDate = checkin[i];
            let checkOutDate = checkout[i];
            let checkInDay = checkInDate.getDate().toString().padStart(2, '0');
            const checkInMonth = (checkInDate.getMonth() + 1).toString().padStart(2, '0'); 
            const formattedCheckInDate = `${checkInDay}/${checkInMonth}`;
            let checkOutDay = checkOutDate.getDate().toString().padStart(2, '0');
            const checkOutMonth = (checkOutDate.getMonth() + 1).toString().padStart(2, '0'); 
            const formattedCheckOutDate = `${checkOutDay}/${checkOutMonth}`;

            formattedContactName += `${templateLabel[i]} ${unit[i]} ${formattedCheckInDate}-${formattedCheckOutDate}`;
        }

        // Append the contact name
        formattedContactName += ` ${editContactName}`;

        // Call API to edit contact
        try {
            const response = await API.editClientUser({
                'token': userToken,
                'id': editClientUserId,
                'name': formattedContactName,
                'unit_no': unit,
                'checkin_datetime': checkin,
                'checkout_datetime': checkout,
                'checkin_status': false,
                'checkout_status': false,
                'checkin_list': checkInBoolean,
                'checkout_list': checkOutBoolean,
                'chatflow_id': templateValue,
            });

            // Update row data with the new data
            let chatflowIds = [];
            let checkinDates = [];
            let checkoutDates = [];
            try {
                chatflowIds = JSON.parse(response.chatflow_id || '[]');
            } catch (error) {
                console.error('Error parsing chatflow_id:', error);
            }
            try {
                checkinDates = JSON.parse(response.checkin_datetime.replace(/'/g, '"') || '[]');
            } catch (error) {
                console.error('Error parsing checkin_datetime:', error);
            }
            try {
                checkoutDates = JSON.parse(response.checkout_datetime.replace(/'/g, '"') || '[]');
            } catch (error) {
                console.error('Error parsing checkout_datetime:', error);
            }

            const buildingArray = await Promise.all(
                chatflowIds.map(async (chatflow_id) => {
                    const chatflow = await API.getChatFlow({ 'token': userToken, 'id': chatflow_id });
                    return chatflow.chatflow_name;
                })
            );

            const formattedCheckIn = checkinDates.map(dateString => {
                const date = new Date(dateString);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            });

            const formattedCheckOut = checkoutDates.map(dateString => {
                const date = new Date(dateString);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            });

            const data = {
                id: response.id,
                building: buildingArray,
                name: response.name,
                phone_number: response.phone_number,
                unit_no: response.unit_no,
                checkin_datetime: formattedCheckIn,
                checkout_datetime: formattedCheckOut,
                checkin_list: response.checkin_list,
                checkout_list: response.checkout_list,
                checkin_status: response.checkin_status,
                checkout_status: response.checkout_status,
                chatflow_id: JSON.parse(response.chatflow_id),
            };
            setRowData(currentRowData => {
                return currentRowData.map(object => 
                    object.id === response.id ? { ...object, ...data } : object
                );
            });

            let checkInSent = false;
            let checkOutSent = false;
            // Send message immediately if slide button is checked
            // 1. Check in list
            checkInBoolean.map(async(checkIn, index) => {
                if (checkIn) {
                    checkInSent = true;
                    // Send checkin massflow message;
                    const resp = await API.massFlowAlgorithm({
                        'token': userToken,
                        'index': index,
                        'chatflow_id': templateValue[index],
                        'unit': unit[index],
                        'waba_id': wabaId,
                        'phone_number_id': waPhoneNumberId,
                        'to_phone_number': response.phone_number,
                        'type': 'checkInNode'
                    })
                }
            })

            // 2. Check out list
            checkOutBoolean.map(async(checkOut, index) => {
                if (checkOut) {
                    checkOutSent = true;
                    // Send checkout massflow message
                    const resp = await API.massFlowAlgorithm({
                        'token': userToken,
                        'index': index,
                        'chatflow_id': templateValue[index],
                        'unit': unit[index],
                        'waba_id': wabaId,
                        'phone_number_id': waPhoneNumberId,
                        'to_phone_number': response.phone_number,
                        'from_phone_number': waPhoneNumber,
                        'type': 'checkOutNode'
                    })
                }
            })
            
            setIsEditModalOpen(false);
            toast.success('Contact updated successfuly');
            closeModal();
        } catch (error) {
            console.error(error);
            toast.error('Something wrong happened')
        }
    }

    // button function
    const addContactButton = useAddContact({ userToken, contactName, phoneNumber, phoneError, buildings, waPhoneNumberId, userId, SystemUserToken, wabaId, waPhoneNumber, setIsButtonDisabled, isButtonDisabled, setEditContactName, setEditBuildings, setExistingContactData, setIsModalOpen, setIsEditModalOpen, selectedTags, setSelectedTags });
    
    // -----------------------------------------------------------------------------------------------

    return(
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Chatflow Page</title>
            </Helmet>
            <Sidenav2/>
            <main className='chatflowlist-main'>

                {/* MASSFLOW Section ------------------------------------------------------------- */}
                <div className='chatflowlist-contentbox'>
                    <h2>Schedule Message Flow</h2>
                    {isLoading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <div className={`chatflowlist-content ${!activeTab ? 'chatflowlist-active' : ''}`}>
                        {massFlowData.map((chatflow, index) => (
                            <div key={index} className={`chatflowlist-item ${(selectedCard===index) ? 'edit-active' : ''}`}  onClick={() => chatflowButton(chatflow.id, chatflow.chatflow_name, chatflow.type)}>
                                <div className='chatflowlist-item-header' style={{backgroundImage: `url(${chatflow.chatflow_image})`, 'display': selectedCard === index ? 'none' : 'block'}}></div>
                                <div className='chatflowlist-item-body'>
                                    <div className='chatflowlist-content-header'>
                                        <div className='chatflowcard-title' style={{'display': selectedCard === index ? 'none' : 'flex'}}>
                                            <h1>{chatflow.chatflow_name}</h1>
                                        </div>
                                        <div className={`chatflowlist-item-status ${chatflow.error_status ? 'item-green': 'item-red'}`} style={{'display': selectedCard === index ? 'none' : 'block'}}>
                                            {chatflow.error_status ? 'Available' : 'Error'}
                                        </div>
                                    </div>

                                    <div className={`chatflow-edit ${selectedCard === index ? 'edit-active' : ''}`}>
                                        <h3>Edit template</h3>
                                        <div className="input-container">
                                            <input id='chatflow-name' type='text' value={editChatFlowName} placeholder='Enter chatflow name' onChange={(e) => setEditChatFlowName(e.target.value)} onClick={(e)=> e.stopPropagation()}/>
                                            <label htmlFor='chatflow-name'>Edit chatflow name:</label>
                                        </div>

                                        <div className='chatflow-btn-div'>
                                            <button className='chatflow-delete-btn' id={index} onClick={(e) => deleteChatflow(e, chatflow.id)}><FaTrashAlt style={{fontSize:'1rem'}}/></button>
                                            <button onClick={(e) => editChatFlow(e)}>Done</button>
                                        </div>
                                    </div>

                                    <div className='chatflow-btn-div'>
                                        <button className='chatflow-edit-btn' onClick={(e) => handleThreeDotsClick(e, index)}>Edit</button>
                                        <button onClick={(e) => openModal(e, chatflow)} disabled={chatflow.error_status === false} style={{ opacity: chatflow.error_status === false ? 0.5 : 1, cursor: chatflow.error_status === false ? 'not-allowed' : 'pointer', display: selectedCard === index ? 'none' : 'block', }}>Send Message</button>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className='chatflowlist-addmore' onClick={createChatFlow}>
                            <FiPlusCircle size='5rem'/>
                            <h1>Create New</h1>
                        </div>
                    </div>
                    )}
                </div>
                <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} /> 
            </main>

            {/* Contact section */}
            {isModalOpen && (
                <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div className='test-modal-header'>
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={()=>setIsModalOpen(false)}/>
                                <h2>Create new contact</h2>
                            </div>
                            <div className='test-modal-contact'>
                                <h3>Contact details</h3>
                                <div class="input-container">
                                    <input type="text" id="myInput" value={contactName} placeholder="Enter contact name" onChange={(e) => setContactName(e.target.value)}/>
                                    <label for="myInput">Enter contact name</label>
                                </div>
                                <div class="input-container">
                                    <input type="tel" id="phoneInput" placeholder="Enter phone number" value={phoneNumber} onChange={handlePhoneChange}/>
                                    <label htmlFor="phoneInput">Enter phone number</label>
                                    {phoneError && <div className="error-message">{phoneError}</div>}
                                </div>
        
                                {buildings?.map((building, index) => (
                                    <div key={index}className={`building-div ${building.visible ? 'visible' : ''}`}>
                                        <div className='building-header'>
                                            <h3>{buildings[index]['template']?.label ? buildings[index]['template']['label'] : `Building ${index + 1}`} {buildings[index]['unit'] ? buildings[index]['unit'] : ''}</h3>
                                            <MdDelete className='delete-icon' onClick={() => deleteBuilding(index, building.id)} style={{ fontSize: '1.5rem' }} />
                                        </div>
                                        <div className='building-info'>
                                            <Select className='modal-select' placeholder={'Select building'} options={massFlowOption} value={buildings[index]['template']?.value ? {'label': buildings[index]['template']?.label, 'value': buildings[index]['template']?.value} : null} onChange={(selectedOption) => handleTemplateChange(index, selectedOption)} />
                                            <Select className='modal-select' placeholder={'Select unit'} options={unitOption[buildings[index]['template']?.label]} value={buildings[index]['unit'] ? {'label': buildings[index]['unit'], 'value': buildings[index]['unit']} : null} onChange={(selectedOption) => handleUnitChange(index, selectedOption)} />
                                            <div className='test-modal-date'>
                                                <div>
                                                    <label htmlFor='checkin'>Check In</label>
                                                    <StyledDateTimePicker id='checkin' selected={buildings[index]['checkIn']} onChange={(date) => handleCheckInDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check in" />
                                                    <div className='switch-div'>
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckIn']} onChange={(selectedOption) => handleSendCheckIn(index, selectedOption)}/>
                                                            <span class="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-in details now</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor='checkout'>Check Out</label>
                                                    <StyledDateTimePicker id='checkout' selected={buildings[index]['checkOut']} onChange={(date) => handleCheckOutDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check out" />
                                                    <div className='switch-div'>
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckOut']} onChange={(selectedOption) => handleSendCheckOut(index, selectedOption)}/>
                                                            <span class="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-out details now</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
        
                                <div className={'test-modal-add'} onClick={handleAddBuilding}>
                                    <IoHomeSharp style={{fontSize:'1.5rem'}}/>
                                    <p>Add building</p>
                                </div>
        
                                <div className='test-modal-add-btn'>
                                    <button className='test-modal-btn' onClick={addContactButton} disabled={isButtonDisabled}>Add contact</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}

            {/*  Add contact section */}
            {isModalOpen && (
                <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div className='test-modal-header'>
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={()=>setIsModalOpen(false)}/>
                                <h2>Create new contact</h2>
                            </div>
                            <div className='test-modal-contact'>
                                <h3>Contact details</h3>
                                <div className="input-container">
                                    <input type="text" id="myInput" value={contactName} placeholder="Enter contact name" onChange={(e) => setContactName(e.target.value)}/>
                                    <label for="myInput">Enter contact name</label>
                                </div>
                                <div className="input-container">
                                    <input type="tel" id="phoneInput" placeholder="Enter phone number" value={phoneNumber} onChange={handlePhoneChange}/>
                                    <label htmlFor="phoneInput">Enter phone number</label>
                                    {phoneError && <div className="error-message">{phoneError}</div>}
                                </div>

                                <h3>Labels</h3>
                                <div className='contact-label-container'>
                                {labelTag?.map((tag) => (
                                        <div
                                            key={tag.id}
                                            className="label-tag-ibx"
                                            onClick={() => handleTagClick(tag)}
                                            style={{
                                                backgroundColor: selectedTags.includes(tag.id) ? tag.label_color : "#f0f0f0",
                                            }}
                                        >
                                            {tag.label_name}
                                        </div>
                                    ))}
                                    {showLabelInput ? (
                                        <div className="add-label-input">
                                            <div className='input-container' style={{margin: '0'}}>
                                                <input type="text" id='addlabel' value={newLabel} onChange={(e) => setNewLabel(e.target.value)} placeholder="Enter label name"/>
                                                <label htmlFor="addlabel">Enter label name</label>
                                            </div>
                                            <div className="color-picker">
                                                {allowedColors.map((color) => (
                                                    <div key={color} className="color-option" style={{backgroundColor: color, border: newColor === color ? "2px solid black" : "none", width: "24px", height: "24px", display: "inline-block", cursor: "pointer", margin: "4px", borderRadius: "50%", }} onClick={() => setNewColor(color)}>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='label-button-container'>
                                                <button onClick={() => setShowLabelInput(false)} className='label-cancel-btn'>Cancel</button>
                                                <button onClick={handleAddLabel} className='label-add-btn'>Add</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="add-label" onClick={() => setShowLabelInput(true)}>
                                            + Add label
                                        </div>
                                    )}
                                </div>
        
                                {buildings?.map((building, index) => (
                                    <div key={index}className={`building-div ${building.visible ? 'visible' : ''}`}>
                                        <div className='building-header'>
                                            <h3>{buildings[index]['template']?.label ? buildings[index]['template']['label'] : `Building ${index + 1}`} {buildings[index]['unit'] ? buildings[index]['unit'] : ''}</h3>
                                            <MdDelete className='delete-icon' onClick={() => deleteBuilding(index, building.id)} style={{ fontSize: '1.5rem' }} />
                                        </div>
                                        <div className='building-info'>
                                            <Select className='modal-select' placeholder={'Select building'} options={massFlowOption} value={buildings[index]['template']?.value ? {'label': buildings[index]['template']?.label, 'value': buildings[index]['template']?.value} : null} onChange={(selectedOption) => handleTemplateChange(index, selectedOption)} />
                                            <Select className='modal-select' placeholder={'Select unit'} options={unitOption[buildings[index]['template']?.label]} value={buildings[index]['unit'] ? {'label': buildings[index]['unit'], 'value': buildings[index]['unit']} : null} onChange={(selectedOption) => handleUnitChange(index, selectedOption)} />
                                            <div className='test-modal-date'>
                                                <div className="checkin-container">
                                                    <div className="checkin-option">
                                                    <label htmlFor='checkin'>Check In</label>
                                                    <StyledDateTimePicker id='checkin' selected={buildings[index]['checkIn'] ? new Date(buildings[index]['checkIn']) : null}  onChange={(date) => handleCheckInDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check in" autoComplete="off" customInput={<input autoComplete="off" />}/>
                                                    <div className='switch-div'>
                                                    </div>
                                                    <p>Send check-in details now</p>
                                                        <label className="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckIn']} onChange={(selectedOption) => handleSendCheckIn(index, selectedOption)}/>
                                                            <span className="slider-btn"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="checkin-container">
                                                <div className="checkin-option">
                                                    <label htmlFor='checkout'>Check Out</label>
                                                    <StyledDateTimePicker id='checkout' selected={buildings[index]['checkOut'] ? new Date(buildings[index]['checkOut']) : null}  onChange={(date) => handleCheckOutDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check out" autoComplete="off" customInput={<input autoComplete="off" />} />
                                                    <div className='switch-div'>
                                                </div>
                                                <p>Send check-out details now</p>
                                                        <label className="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckOut']} onChange={(selectedOption) => handleSendCheckOut(index, selectedOption)}/>
                                                            <span className="slider-btn"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
        
                                <div className={'test-modal-add'} onClick={handleAddBuilding} style={{marginTop: '1.5rem'}}>
                                    <IoHomeSharp style={{fontSize:'1.5rem'}}/>
                                    <p>Add building</p>
                                </div>
        
                                <AddContactButton onClick={addContactButton} disabled={isButtonDisabled} >
                                    Add contact
                                </AddContactButton>
                            </div>
                        </div>
                    </div>
                </main>
            )}

            <EditModal isEditModalOpen={isEditModalOpen} setIsEditModalOpen={setIsEditModalOpen} editContactName={editContactName} setEditContactName={setEditContactName} editBuildings={editBuildings} massFlowOption={massFlowOption} unitOption={unitOption} handleEditTemplateChange={handleEditTemplateChange} handleEditUnitChange={handleEditUnitChange} handleEditCheckInDateChange={handleEditCheckInDateChange} handleEditSendCheckIn={handleEditSendCheckIn} handleEditCheckOutDateChange={handleEditCheckOutDateChange} handleEditSendCheckOut={handleEditSendCheckOut} deleteEditBuilding={deleteEditBuilding} handleEditAddBuilding={handleEditAddBuilding} editContact={editContact} isButtonDisabled={isButtonDisabled} />
            
        <HelpButton />
        </React.Fragment>
    )
}

export default ChatFlowList;