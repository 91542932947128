import Sidenav2 from "../components/Sidenav.js";
import SidenavSettings from "../components/SidenavSettings.js";
import CheckNotificationSubscription from "../components/CheckNotificationSubscription.js";
import React, {
    useEffect,
    useState,
    useContext,
    useRef,
    useCallback,
} from "react";
import Select from "react-select";
import { Helmet } from "react-helmet";
import { UserContext } from "../user-context.js";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { API } from "../api-service.js";
import { FaArrowLeft } from "react-icons/fa";
import { PiLinkBreakBold } from "react-icons/pi";
import "./css/Settings.css";
import "./css/SettingsAccount.css";
import "./css/SettingsShortcut.css";
import "./css/SettingsTemplate.css";
import "./css/SettingsPayment.css";
import "./css/SettingsPassword.css";
import "./css/SettingsNotifications.css";
import "./css/Chatbot.css";
import "./css/PlansAndBilling.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import HelpButton from "../components/HelpButton.js";
import { Link } from "react-router-dom";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import {
    IoIosArrowBack,
    IoIosCheckmark,
} from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { FaFileAlt } from "react-icons/fa";
import { IoChevronBack, IoCloseCircle } from "react-icons/io5";
import { IoHomeSharp } from "react-icons/io5";

// Register the modules
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
]);

// Main Settings Page ---------------------------------------------------------------
function Settings() {
    const [activeView, setActiveView] = useState("settings");
    const [selectedOption, setSelectedOption] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setActiveView("content");
    };

    const handleBackClick = () => {
        setActiveView("settings");
        setSelectedOption(null);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Settings Page</title>
            </Helmet>
            <Sidenav2 />
            <SidenavSettings onSelect={handleOptionSelect} />

            {selectedOption === "general" && <General />}
        </React.Fragment>
    );
}
//General function---------------------------------------------------------------------------------------------
function General() {
    // Keep state of variables
    const [isLoading, setIsLoading] = useState(true);
    const [settingsAbout, setAbout] = useState("");
    const [settingsAddress, setAddress] = useState("");
    const [settingsDescription, setDescription] = useState("");
    const [settingsEmail, setEmail] = useState("");
    const [settingsWebsite1, setWebsite1] = useState("");
    const [settingsWebsite2, setWebsite2] = useState("");
    const [settingsImageUrl, setSettingsImageUrl] = useState("");
    const [companyIndustry, setCompanyIndustry] = useState("");
    const industryOption = [
        "UNDEFINED",
        "OTHER",
        "AUTO",
        "BEAUTY",
        "APPAREL",
        "EDU",
        "ENTERTAIN",
        "EVENT_PLAN",
        "FINANCE",
        "GROCERY",
        "GOVT",
        "HOTEL",
        "HEALTH",
        "NONPROFIT",
        "PROF_SERVICES",
        "RETAIL",
        "TRAVEL",
        "RESTAURANT",
        "NOT_A_BIZ",
    ];

    const {
        userToken,
        profileId,
        userId,
        setImageUrl,
        wabaAccessToken,
        waPhoneNumberId,
        about,
        SystemUserToken,
    } = useContext(UserContext);

    const [activeView, setActiveView] = useState("settings");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        // after userToken is set, fetch user profile
        const fetchProfile = async () => {
            setIsLoading(true);
            try {
                // Fetch user profile
                if (userToken) {
                    const data = await API.fetchProfile(userToken);
                    // Set data
                    setSettingsImageUrl(data["image"]);
                    setAbout(data["about"]);
                    setAddress(data["address"]);
                    setDescription(data["company_description"]);
                    setEmail(data["email"]);
                    setWebsite1(data["company_website1"]);
                    setWebsite2(data["company_website2"]);
                    setCompanyIndustry(data["company_industry"]);
                } else {
                    console.error("User token not found");
                }
            } catch (error) {
                console.error(
                    "An error occurred while loading user profile:",
                    error
                );
            } finally {
                setIsLoading(false);
            }
        };

        fetchProfile();
    }, [userToken]);

    // Handle save button (onClick function)
    const saveClick = async () => {
        if (SystemUserToken) {
            const data = {
                token: userToken,
                phone_number_id: waPhoneNumberId,
                waba_access_token: SystemUserToken,
                id: profileId,
                about: settingsAbout,
                address: settingsAddress,
                email: settingsEmail,
                company_website1: settingsWebsite1,
                company_website2: settingsWebsite2,
                company_industry: companyIndustry,
                company_description: settingsDescription,
            };
            await API.uploadWhatsappProfile(data);
            await API.updateProfile(data);

            console.log(wabaAccessToken.length);
            for (let i = 0; i < wabaAccessToken.length; i++) {
                await API.uploadWhatsappProfile({
                    phone_number_id: waPhoneNumberId[i],
                    about: about,
                    waba_access_token: SystemUserToken,
                });
            }
            toast.success("Profile updated successfully");
        } else {
            toast.error("Please connect your WhatsApp account first");
            return;
        }
    };
    // Handle image upload
    const handleImageUpload = async (event) => {
        if (SystemUserToken) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("id", profileId);
            formData.append("user_id", userId);
            formData.append("token", userToken);
            formData.append("image", file, file["name"]);
            // Send image to backend
            const response = await API.uploadImage(formData);
            setSettingsImageUrl(response["image"]);
            setImageUrl(response["image"]);

            // Update all the whatsapp profile picture user has
            for (let i = 0; i < wabaAccessToken.length; i++) {
                // 1. Create WhatsApp resumable upload session
                const uploadResponse = await API.resumableUpload({
                    file_name: file["name"],
                    file_length: file["size"],
                    file_type: file["type"],
                    token: SystemUserToken,
                });
                const uploadSessionId = JSON.parse(uploadResponse)[
                    "id"
                ].replace(",", "");

                // 2. Upload file to WhatsApp resumable upload session to get the resumable upload id
                const handleData = new FormData();
                handleData.append("file", file);
                handleData.append("upload_id", uploadSessionId);
                handleData.append("waba_access_token", SystemUserToken);
                const uploadHandleResponse = await API.whatsappUploadHandle(
                    handleData
                );
                const uploadHandle = uploadHandleResponse["h"];

                // 3. Update WhatsApp profile picture
                const updateProfilePic = await API.whatsappUpdateProfilePic({
                    phone_number_id: waPhoneNumberId[i],
                    waba_access_token: SystemUserToken,
                    handle: uploadHandle,
                });
                console.log(updateProfilePic);
                toast.success("Profile picture updated successfully");
            }
        } else {
            toast.error("Please connect your WhatsApp account first");
            return;
        }
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | General </title>
            </Helmet>
            <Sidenav2 />
            <div className="sides">
                <SidenavSettings />
            </div>
            <main className="settings-main">
                <div
                    className={`settings-column2 ${
                        !isMobile || activeView === "content"
                            ? "settings-main-active"
                            : ""
                    }`}
                >
                    {isMobile && (
                        <div className="settings-header">
                            <Link to="/main/settings" className="sett-link1">
                                <FaArrowLeft />
                            </Link>
                            <h1>General</h1>
                        </div>
                    )}
                    <div className="settings-content-area">
                        {isLoading ? (
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <div className="main-content-settings">
                                <h2>Profile Picture</h2>
                                <div className="settings-profile-pic">
                                    <div className="settings-profile-img">
                                        <img
                                            src={settingsImageUrl}
                                            alt=""
                                            width="120rem"
                                        />
                                        <div className="upload-sec">
                                            <label className="custom-file-upload">
                                                Choose file
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageUpload}
                                                />
                                            </label>
                                            <span className="upload-text">
                                                Maximum upload size: 65 MB
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="setting-info">
                                    <div className="input-box-settings">
                                        <div className="input-container">
                                            <textarea
                                                id="about"
                                                placeholder="About your business"
                                                value={settingsAbout}
                                                onChange={(evt) =>
                                                    setAbout(evt.target.value)
                                                }
                                            ></textarea>
                                            <label htmlFor="about">About</label>
                                        </div>
                                    </div>

                                    <div className="input-box-select-flex">
                                        <div className="input-box-address">
                                            <div className="input-container">
                                                <input
                                                    id="address"
                                                    className="settings-address"
                                                    placeholder="Address"
                                                    value={settingsAddress}
                                                    onChange={(evt) =>
                                                        setAddress(
                                                            evt.target.value
                                                        )
                                                    }
                                                />
                                                <label htmlFor="address">
                                                    Address
                                                </label>
                                            </div>
                                        </div>

                                        <div className="input-box-address">
                                            <div className="input-container">
                                                <input
                                                    id="description"
                                                    className="settings-address"
                                                    placeholder="Business Description"
                                                    value={settingsDescription}
                                                    onChange={(evt) =>
                                                        setDescription(
                                                            evt.target.value
                                                        )
                                                    }
                                                />
                                                <label htmlFor="description">
                                                    Description
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-box-select-flex">
                                        <div className="input-box-address">
                                            <div className="input-container">
                                                <select
                                                    id="industry"
                                                    className="settings-address"
                                                    placeholder="Industry"
                                                    value={companyIndustry}
                                                    onChange={(e) =>
                                                        setCompanyIndustry(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    {industryOption.map(
                                                        (option) => (
                                                            <option
                                                                key={option}
                                                                value={option}
                                                            >
                                                                {option}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                <label htmlFor="industry">
                                                    Industry
                                                </label>
                                            </div>
                                        </div>

                                        <div className="input-box-address">
                                            <div className="input-container">
                                                <input
                                                    id="email"
                                                    className="settings-address"
                                                    placeholder="Email"
                                                    value={settingsEmail}
                                                    onChange={(evt) =>
                                                        setEmail(
                                                            evt.target.value
                                                        )
                                                    }
                                                />
                                                <label htmlFor="email">
                                                    E-mail
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-box-settings settings-website">
                                        <div className="input-container">
                                            <input
                                                id="website1"
                                                placeholder="Website 1"
                                                value={settingsWebsite1}
                                                onChange={(evt) =>
                                                    setWebsite1(
                                                        evt.target.value
                                                    )
                                                }
                                            />
                                            <label htmlFor="website1">
                                                Website 1
                                            </label>
                                        </div>
                                        <div className="input-container">
                                            <input
                                                id="website2"
                                                placeholder="Website2"
                                                value={settingsWebsite2}
                                                onChange={(evt) =>
                                                    setWebsite2(
                                                        evt.target.value
                                                    )
                                                }
                                            />
                                            <label htmlFor="website2">
                                                Website 2
                                            </label>
                                        </div>
                                    </div>

                                    <div className="settings-button">
                                        <button
                                            className="save-button"
                                            onClick={saveClick}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </main>

            <HelpButton />
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgreeBar={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                rtl={false}
                pauseOnHover
                theme="colored"
                transition={Bounce}
            />
        </React.Fragment>
    );
}
// ------------------------------------------------------------------------------------

// Quick Response page --------------------------------------------------------------
function QuickResponse() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { userToken, userId, profileId } = useContext(UserContext);
    const gridRef = useRef(null);
    const [gridKey, setGridKey] = useState(0);
    const [shortcut, setShortcut] = useState("");
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");
    const [textAreaHeight, setTextAreaHeight] = useState("auto");

    const [activeView, setActiveView] = useState("settings");
    const [selectedOption, setSelectedOption] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [isLoading, setIsLoading] = useState(true);
    const [quickFilter, setQuickFilter] = useState("");

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        if (isMobile) {
            setActiveView("content");
        }
    };

    const handleBackClick = () => {
        setActiveView("settings");
        setSelectedOption(null);
    };

    // Load data
    useEffect(() => {
        // Fetch created user
        const fetchCommand = async () => {
            setIsLoading(true);
            try {
                const response = await API.fetchCommand({ token: userToken });
                const formatData = response.map((data) => ({
                    id: data.id,
                    command: data.command,
                    text_response: data.text_response,
                    file_response:
                        data.file_response.split("/").pop() === "null"
                            ? "null"
                            : data.file_response,
                }));

                setRowData(formatData);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCommand();
    }, [userId]);

    // Create new account window
    const openModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(true);
        setShortcuts([{ id: Date.now(), shortcut: "", message: "" }]);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleInput = (e) => {
        let target = e.target;
        target.style.height = "auto";
        target.style.height = target.scrollHeight + "px";
    };

    // Display uploaded file
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

    // Remove uploaded file
    const handleRemoveFile = () => {
        setFile(null);
    };

    let fileUrl;
    if (file) {
        fileUrl = URL.createObjectURL(file);
    }

    const saveQuickResposne = async () => {
        const commandId = "COMMAND-" + Date.now();

        console.log(shortcut, message);
        const data = {
            token: userToken,
            command_id: commandId,
            command: shortcut,
            text_response: message,
            file_response: file,
        };

        const addQuickResponse = await API.addQuickResponse(data);
        if ("error" in addQuickResponse) {
            toast.error("Command already exists");
        } else {
            setIsModalOpen(false);
            try {
                const response = await API.fetchCommand({ token: userToken });
                const formatData = response.map((data) => ({
                    id: data.id,
                    command: data.command,
                    text_response: data.text_response,
                    file_response:
                        data.file_response.split("/").pop() === "null"
                            ? "null"
                            : data.file_response,
                }));

                setRowData(formatData);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
            toast.success("Command added successfully");
        }
    };

    const deleteCommandBtn = (params) => {
        const onClick = async () => {
            try {
                await API.deleteCommand({
                    token: userToken,
                    id: params.data.id,
                });

                setRowData((currentRowData) => {
                    // Correctly return the filtered array to update the state
                    return currentRowData.filter(
                        (object) => object.id !== params.data.id
                    );
                });
            } catch (error) {
                console.error(error);
            }
        };
        return (
            <button className="commandDeleteBtn" onClick={onClick}>
                Delete
            </button>
        );
    };

    const [gridOptions, setGridOptions] = useState({
        animateRows: true,
        onCellClicked: (params) => {
            if (params.colDef.field) {
                const cell = params.event.target.closest(".ag-cell");
                if (cell) {
                    if (params.node.data.isExpanded) {
                        // Collapse the row back to the original height
                        params.node.setRowHeight(45);
                        params.node.data.isExpanded = false;
                        cell.classList.remove("expanded-cell"); // Remove expanded class
                    } else {
                        // Expand the row to fit the content
                        const cellHeight = cell.scrollHeight;
                        params.node.setRowHeight(cellHeight);
                        params.node.data.cellHeight = cellHeight;
                        params.node.data.isExpanded = true;
                        cell.classList.add("expanded-cell"); // Add expanded class
                    }

                    // Trigger a height recalculation
                    params.api.onRowHeightChanged();
                }
            }
        },
        getRowHeight: (params) => {
            return params.node.data.isExpanded
                ? params.node.data.cellHeight
                : 45;
        },
    });

    const [rowData, setRowData] = useState([]);
    const [columnDef, setColumnDef] = useState(
        [
            { field: "id", resizable: false, hide: true },
            {
                field: "command",
                headerName: "Command",
                resizable: true,
                flex: 1,
            },
            {
                field: "text_response",
                headerName: "Text Response",
                resizable: true,
                flex: 1,
            },
            {
                field: "file_response",
                headerName: "File Response",
                resizable: true,
                flex: 1,
            },
            {
                field: "delete_btn",
                headerName: "Delete",
                cellRenderer: deleteCommandBtn,
                resizable: false,
                flex: 1,
            },
        ].map((col) => ({
            ...col,
            wrapText: true,
        }))
    );

    const onFilterTextBoxChanged = useCallback(() => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setQuickFilter(
                document.getElementById("filter-text-box").value
            );
        }
    }, []);

    const handleQuickFilterChange = (e) => {
        setQuickFilter(e.target.value.toLowerCase());
    };

    const filteredData = rowData.filter(
        (item) =>
            item.command.toLowerCase().includes(quickFilter) ||
            item.text_response.toLowerCase().includes(quickFilter) ||
            item.file_response.toLowerCase().includes(quickFilter)
    );

    const handleDelete = async (id) => {
        try {
            await API.deleteCommand({ token: userToken, id: id });
            setRowData((currentRowData) =>
                currentRowData.filter((object) => object.id !== id)
            );
            toast.success("Command deleted successfully");
        } catch (error) {
            console.error(error);
            toast.error("Failed to delete command");
        }
    };

    const [shortcuts, setShortcuts] = useState([]);
    const textareaRefs = useRef([]);

    useEffect(() => {
        textareaRefs.current = textareaRefs.current.slice(0, shortcuts.length);
    }, [shortcuts]);

    const handleShortcutChange = (index, field, value) => {
        const updatedShortcuts = [...shortcuts];
        updatedShortcuts[index][field] = value;
        setShortcuts(updatedShortcuts);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Quick Response </title>
            </Helmet>
            <Sidenav2 />
            <div className="sides">
                <SidenavSettings />
            </div>
            <div
                className={`settings-column2 ${
                    !isMobile || activeView === "content" ? "active" : ""
                }`}
            >
                <div className="settings-account-main">
                    <div className="quick-response-header">
                        {isMobile && (
                            <Link to="/main/settings" className="sett-link">
                                <FaArrowLeft />
                            </Link>
                        )}
                        <h1>Quick Response Commands</h1>
                        <button
                            className="settings-add-user-btn"
                            onClick={openModal}
                        >
                            Add Command
                        </button>
                    </div>
                    <hr />
                    <div className="quick-response-filter">
                        <input
                            type="text"
                            placeholder="Quick filter..."
                            className="quick-filter-input"
                            onChange={handleQuickFilterChange}
                        />
                    </div>
                    {isLoading ? (
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                        </div>
                    ) : filteredData.length === 0 ? (
                        <div className="no-data-message">
                            No quick response commands found.
                        </div>
                    ) : (
                        <div className="quick-response-list">
                            {filteredData.map((item) => (
                                <div key={item.id} className="response-card">
                                    <div className="response-details">
                                        <div className="response-card-header">
                                            <div className="status-dot"></div>
                                            <h3 className="command-name">
                                                {item.command}
                                            </h3>
                                        </div>

                                        <div className="response-content">
                                            <p className="text-response">
                                                {item.text_response}
                                            </p>
                                        </div>

                                        {item.file_response !== "null" && (
                                            <div className="file-response">
                                                <p>
                                                    <FaFileAlt />{" "}
                                                    {item.file_response}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="response-actions">
                                        <button
                                            onClick={() =>
                                                handleDelete(item.id)
                                            }
                                            className="response-delete-button"
                                        >
                                            <MdDelete />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        rtl={false}
                        pauseOnHover
                        theme="colored"
                        transition={Bounce}
                    />
                </div>

                {isModalOpen && (
                    <main
                        className={`test-modal-main ${
                            isModalOpen ? "open" : ""
                        }`}
                    >
                        <div className="modal-container">
                            <div className="modal">
                                <div className="test-modal-header">
                                    <FaArrowLeft
                                        style={{ fontSize: "1.5rem" }}
                                        className="test-modal-icon"
                                        onClick={closeModal}
                                    />
                                    <h2>New shortcut</h2>
                                </div>
                                <div className="test-modal-shortcut">
                                    <div className="shortcut-div visible">
                                        <div className="shortcut-info">
                                            <h3>Shortcut</h3>
                                            <div className="modal-quick-response">
                                                <input
                                                    type="text"
                                                    name="shortcut"
                                                    placeholder="Enter Shortcut"
                                                    value={shortcut}
                                                    onChange={(e) =>
                                                        setShortcut(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="modal-quick-response">
                                                <textarea
                                                    id="msg"
                                                    name="msg"
                                                    placeholder="Enter Message"
                                                    value={message}
                                                    onChange={(e) =>
                                                        setMessage(
                                                            e.target.value
                                                        )
                                                    }
                                                    style={{
                                                        height: textAreaHeight,
                                                        resize: "none",
                                                        userSelect: "none",
                                                    }}
                                                    onInput={handleInput}
                                                    draggable="false"
                                                />
                                            </div>
                                        </div>
                                        <label>File (Optional):</label>
                                        <div className="modal-quick-response quick-response-file">
                                            <input
                                                type="file"
                                                onChange={handleFileChange}
                                            />
                                            {file &&
                                                file.type.startsWith(
                                                    "image/"
                                                ) && (
                                                    <img
                                                        src={URL.createObjectURL(
                                                            file
                                                        )}
                                                        alt="Preview"
                                                        className="mediaFile-style"
                                                        width="65%"
                                                    />
                                                )}
                                            {file &&
                                                file.type ===
                                                    "application/pdf" && (
                                                    <object
                                                        data={URL.createObjectURL(
                                                            file
                                                        )}
                                                        type="application/pdf"
                                                        className="borderRadius"
                                                        width="65%"
                                                    ></object>
                                                )}
                                            {file &&
                                                file.type.startsWith(
                                                    "video/"
                                                ) && (
                                                    <video
                                                        controls
                                                        src={URL.createObjectURL(
                                                            file
                                                        )}
                                                        className="borderRadius"
                                                        width="65%"
                                                    ></video>
                                                )}
                                            {file && (
                                                <button
                                                    onClick={handleRemoveFile}
                                                    className="removeFileButton"
                                                >
                                                    Remove File
                                                </button>
                                            )}
                                        </div>
                                    </div>

                                    <div className="test-modal-add-btn">
                                        <button
                                            className="test-modal-btn"
                                            onClick={saveQuickResposne}
                                        >
                                            Save shortcut
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                )}
            </div>
        </React.Fragment>
    );
}
// ------------------------------------------------------------------------------------

// WhatsApp Template page -----------------------------------------------------------
function WhatsAppTemplate() {
    const { wabaId, userId, SystemUserToken } = useContext(UserContext);

    // Add template modal functions
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [templateCategory, setTemplateCategory] = useState("UTILITY");
    const [templateHeader, setTemplateHeader] = useState("");
    const [templateBody, setTemplateBody] = useState("");
    const [bodyParameters, setBodyParameters] = useState([]);
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState("");
    const [headerTextVisibility, setHeaderTextVisibility] = useState(true);
    const [headerFileVisibility, setHeaderFileVisibility] = useState(true);
    const [focusedInput, setFocusedInput] = useState(null);
    const buttonText = [
        { text: "Guest's Name", value: "guest_name" },
        { text: "Guest's Phone", value: "guest_phone" },
        { text: "Check-in date", value: "checkin_date" },
        { text: "Check-out date", value: "checkout_date" },
        { text: "Unit no", value: "unit_no" },
        { text: "Building", value: "building" },
        { text: "Main door passcode", value: 'maindoor_passcode'},
        { text: "Mailbox passcode", value: 'mailbox_passcode'},
        { text: "Wifi username", value: 'wifi_username'},
        { text: "Wifi password", value: 'wifi_password'},
        { text: "Parking", value: 'parking'},
    ];
    const headerInputRef = useRef(null);
    const bodyInputRef = useRef(null);

    // General functions
    const [activeView, setActiveView] = useState("settings");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    // Display template function
    const [templateList, setTemplateList] = useState([]);
    const [constTemplateList, setConstTemplateList] = useState([]);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewData, setPreviewData] = useState({});
    const [activeTag, setActiveTag] = useState("");

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const getWhatsAppTemplate = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(
                    "https://graph.facebook.com/v20.0/" +
                        wabaId +
                        "/message_templates?limit=250&fields=quality_score,name,components,status",
                    {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer " + SystemUserToken,
                        },
                    }
                );

                const resp = await response.json();
                console.log(resp);
                setTemplateList(resp?.data);
                setConstTemplateList(resp?.data);
            } catch (error) {
                console.error("Error fetching WhatsApp templates:", error);
                toast.error("Failed to fetch WhatsApp templates");
            } finally {
                setIsLoading(false);
            }
        };

        getWhatsAppTemplate();
    }, [userId, wabaId, SystemUserToken]);

    const createWhatsAppTemplate = async () => {
        setIsSaving(true);
        let count = 1;

        const replacePlaceholders = (text) => {
            let count = 1;

            // Use regular expression to find all placeholders and replace them with numbered ones
            return text?.replace(/{{\s*[^}]+\s*}}/g, () => `{{${count++}}}`);
        };

        if (!templateName) {
            toast.error("Please enter template name");
            setIsSaving(false);
            return;
        }

        if (!(templateBody && (!!templateHeader || !!file))) {
            toast.error("Text header or file cannot be empty");
            setIsSaving(false);
            return;
        }

        try {
            let components = [];

            // 1. Handle template file
            if (file !== null) {
                // Determine the file type and set the format accordingly
                const fileType = file.type.split("/")[0];
                let format = "";
                if (fileType === "image") {
                    format = "IMAGE";
                } else if (fileType === "video") {
                    format = "VIDEO";
                } else if (fileType === "application") {
                    format = "DOCUMENT";
                }

                const uploadResponse = await API.resumableUpload({
                    file_name: file["name"],
                    file_length: file["size"],
                    file_type: file["type"],
                    token: SystemUserToken,
                });
                const uploadSessionId = JSON.parse(uploadResponse)[
                    "id"
                ].replace(",", "");

                // 2. Upload file to WhatsApp resumable upload session to get the resumable upload id
                const handleData = new FormData();
                handleData.append("file", file);
                handleData.append("upload_id", uploadSessionId);
                handleData.append("waba_access_token", SystemUserToken);
                const uploadHandleResponse = await API.whatsappUploadHandle(
                    handleData
                );
                const uploadHandle = uploadHandleResponse["h"];

                components.push({
                    type: "HEADER",
                    format: format,
                    example: {
                        header_handle: [uploadHandle],
                    },
                });
            }

            // 2. Handle template header

            if (templateHeader !== "") {
                let headerComponent = {
                    type: "HEADER",
                    format: "TEXT",
                    text: replacePlaceholders(templateHeader),
                };

                const actualHeaderParameter =
                    templateHeader.match(/{{\s*([^}]+)\s*}}/);
                if (actualHeaderParameter !== null) {
                    headerComponent["example"] = {
                        header_text: [actualHeaderParameter[1]],
                    };
                }

                console.log(headerComponent);
                components.push(headerComponent);
            }

            // 3. Handle template body
            let bodyComponent = {
                type: "BODY",
                text: replacePlaceholders(templateBody),
            };
            if (bodyParameters !== null && Array.isArray(bodyParameters)) {
                bodyComponent["example"] = {
                    body_text: [bodyParameters],
                };
            }
            components.push(bodyComponent);
            console.log({
                name: templateName,
                language: "en_US",
                category: templateCategory,
                components: JSON.stringify(components),
            });

            // 4. Create whatsapp template
            const response = await fetch(
                "https://graph.facebook.com/v20.0/" +
                    wabaId +
                    "/message_templates",
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + SystemUserToken,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name: templateName,
                        language: "en_US",
                        category: templateCategory,
                        components: JSON.stringify(components),
                    }),
                }
            );
            const resp = await response.json();
            console.log(resp);
            if (resp["id"]) {
                // Fetch updated template list after successful creation
                const updatedResponse = await fetch(
                    "https://graph.facebook.com/v20.0/" +
                        wabaId +
                        "/message_templates?limit=250&fields=quality_score,name,components,status",
                    {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer " + SystemUserToken,
                        },
                    }
                );
                const updatedResp = await updatedResponse.json();
                setTemplateList(updatedResp?.data);
                setConstTemplateList(updatedResp?.data);

                toast.success("Template added successfully");
                closeModal();
            } else {
                toast.error(resp["error"]["error_user_msg"]);
            }
        } catch (error) {
            console.error("Error creating template:", error);
            toast.error("Failed to create template");
        } finally {
            setIsSaving(false);
        }
    };

    // Delete whatsapp template function
    const deleteWhatsappTemplate = async (templateName) => {
        const userConfirmed = window.confirm(
            "Are you sure you want to delete " + templateName + "?"
        );

        if (!userConfirmed) {
            return; // Exit the function if the user cancels the action
        }

        try {
            const response = await fetch(
                "https://graph.facebook.com/v20.0/" +
                    wabaId +
                    "/message_templates?name=" +
                    templateName,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + SystemUserToken,
                    },
                }
            );
            setTemplateList((template) => {
                return template.filter(
                    (object) => object.name !== templateName
                );
            });
            toast.success("Template delete successfully");
        } catch (error) {
            console.error(error);
            toast.error(error);
        }
    };

    const resetForm = () => {
        setTemplateName("");
        setTemplateCategory("UTILITY");
        setTemplateHeader("");
        setTemplateBody("");
        setBodyParameters([]);
        setFile(null);
        setHeaderTextVisibility(true);
        setHeaderFileVisibility(true);
        setFocusedInput(null);
    };

    const openModal = (e) => {
        e.stopPropagation();
        resetForm();
        setIsModalOpen(true);
    };
    const closeModal = () => {
        resetForm();
        setIsModalOpen(false);
    };

    // Display uploaded file
    const handleFileChange = useCallback((event) => {
        const selectedFile = event.target.files[0];
        const MAX_FILE_SIZE = 16 * 1024 * 1024; // 16MB in bytes

        if (selectedFile) {
            // Check file size
            if (selectedFile.size > MAX_FILE_SIZE) {
                toast.error(
                    "File size exceeds 16MB limit. Please choose a smaller file."
                );
                event.target.value = ""; // Reset file input
                return;
            }

            setFile(selectedFile);
            // Create URL only once when file is selected
            const url = URL.createObjectURL(selectedFile);
            setFileUrl(url);
            setHeaderFileVisibility(true);
            setHeaderTextVisibility(false);

            // Clean up previous URL if it exists
            return () => URL.revokeObjectURL(url);
        } else {
            setHeaderFileVisibility(true);
            setHeaderTextVisibility(true);
        }
    }, []);

    // Remove uploaded file
    const handleRemoveFile = useCallback(() => {
        if (fileUrl) {
            URL.revokeObjectURL(fileUrl);
            setFile(null);
            setHeaderFileVisibility(true);
            setHeaderTextVisibility(true);
        }
    }, [fileUrl]);

    const handleTemplateHeaderChange = useCallback((e) => {
        if (e.target.value !== "") {
            setHeaderTextVisibility(true);
            setHeaderFileVisibility(false);
        } else {
            setHeaderTextVisibility(true);
            setHeaderFileVisibility(true);
        }
        setTemplateHeader(e.target.value);
        const templateParameters = e.target.value.match(/{{(.*?)}}/g);
        if (templateParameters?.length > 1) {
            toast.error("There can only be one parameter in the header text");
        }
    }, []);

    const handleInputFocus = useCallback((inputType) => {
        setFocusedInput(inputType);
    }, []);

    const appendText = (text) => {
        if (focusedInput === "header") {
            const templateParameters = templateHeader.match(/{{(.*?)}}/g);
            if (templateParameters?.length < 1 || templateParameters === null) {
                const cursorPos = headerInputRef.current.selectionStart;
                const textBefore = templateHeader.substring(0, cursorPos);
                const textAfter = templateHeader.substring(cursorPos);

                const newText = textBefore + text + textAfter;
                setTemplateHeader(newText);

                // After state update, restore cursor position
                setTimeout(() => {
                    headerInputRef.current.focus();
                    const newCursorPos = cursorPos + text.length;
                    headerInputRef.current.setSelectionRange(
                        newCursorPos,
                        newCursorPos
                    );
                }, 0);
            } else {
                toast.error(
                    "There can only be one parameter in the header text"
                );
            }
        } else if (focusedInput === "body") {
            const cursorPos = bodyInputRef.current.selectionStart;
            const textBefore = templateBody.substring(0, cursorPos);
            const textAfter = templateBody.substring(cursorPos);

            const newText = textBefore + text + textAfter;
            setTemplateBody(newText);

            // Update parameters after inserting at cursor position
            const templateParameters = newText
                .match(/{{\s*(.*?)\s*}}/g)
                ?.map((param) => param.replace(/{{\s*|\s*}}/g, ""));
            setBodyParameters(templateParameters);

            // After state update, restore cursor position
            setTimeout(() => {
                bodyInputRef.current.focus();
                const newCursorPos = cursorPos + text.length;
                bodyInputRef.current.setSelectionRange(
                    newCursorPos,
                    newCursorPos
                );
            }, 0);
        }
    };

    const handleTemplateBodyChange = (e) => {
        setTemplateBody(e.target.value);
        const templateParameters = e.target.value
            .match(/{{\s*(.*?)\s*}}/g)
            ?.map((param) => param.replace(/{{\s*|\s*}}/g, ""));
        setBodyParameters(templateParameters);
    };

    // ------------------------------------------------------------------------------------

    // Filter whatsapp template function --------------------------------------------------
    const onFilterTextBoxChanged = (text) => {
        const filteredTemplateList = constTemplateList.filter((template) =>
            template.name.includes(text)
        );
        setTemplateList(filteredTemplateList);
    };
    // ------------------------------------------------------------------------------------

    // Format text function ---------------------------------------------------------------
    function replaceNumberedPlaceholders(text, values) {
        // Ensure values is a flat array
        const flatValues = values?.flat();

        return text.replace(/{{(\d+)}}/g, (match, number) => {
            const index = parseInt(number, 10) - 1;

            if (index < flatValues.length) {
                return `{{${flatValues[index]}}}`;
            }

            console.log("No replacement, keeping original:", match);
            return match;
        });
    }

    const TemplateText = ({ text, type, example }) => {
        // Split text by template pattern {{...}}
        if (!text) {
            return;
        }

        const formatText = replaceNumberedPlaceholders(text, example);
        console.log(formatText);
        const parts = formatText.split(/({{[^}]+}})/g);

        return (
            <pre
                style={{
                    fontWeight: type === "header" ? "bold" : "normal",
                    fontSize: type === "header" ? "1.25rem" : "1rem",
                }}
            >
                {parts.map((part, index) => {
                    // Check if this part matches template pattern
                    const match = part.match(/{{(.+?)}}/);

                    if (match) {
                        // Extract the content inside {{}}
                        const content = match[1];
                        return (
                            <span key={index} className="text-tag">
                                {content}
                            </span>
                        );
                    }

                    // If it's regular text, render with sparkles if present
                    return part
                        .split(/(✨)/)
                        .map((textPart, i) => (
                            <span key={`${index}-${i}`}>{textPart}</span>
                        ));
                })}
            </pre>
        );
    };
    // ------------------------------------------------------------------------------------

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | WhatsApp Template </title>
            </Helmet>
            <Sidenav2 />
            <div className="sides">
                <SidenavSettings />
            </div>
            <div
                className={`settings-column2 ${
                    !isMobile || activeView === "content" ? "active" : ""
                }`}
            >
                <div className="settings-account-main">
                    <div className="settings-header">
                        {isMobile && (
                            <Link to="/main/settings" className="sett-link">
                                <FaArrowLeft />
                            </Link>
                        )}
                        <h1>WhatsApp Template Messages</h1>
                        <button
                            className="add-whatsapp-template-btn"
                            onClick={openModal}
                        >
                            Add Template
                        </button>
                    </div>
                    <hr />

                    {/* Search bar, filter tag */}
                    <input
                        type="text"
                        id="filter-text-box"
                        className="quick-filter-input"
                        placeholder="Quick filter..."
                        onChange={(e) => onFilterTextBoxChanged(e.target.value)}
                    />

                    {isLoading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <div>
                            <div className="template-tag-list">
                                {[
                                    ...new Set(
                                        constTemplateList?.map(
                                            (data) => data.name.split("_")[0]
                                        )
                                    ),
                                ].map((name, index) => (
                                    <div
                                        className="template-tag"
                                        key={index}
                                        onClick={() => {
                                            if (
                                                !activeTag ||
                                                activeTag != name
                                            ) {
                                                onFilterTextBoxChanged(name);
                                                setActiveTag(name);
                                            }
                                        }}
                                    >
                                        {name}{" "}
                                        <IoCloseCircle
                                            style={{
                                                display:
                                                    activeTag === name
                                                        ? "block"
                                                        : "none",
                                            }}
                                            onClick={() => {
                                                setActiveTag("");
                                                onFilterTextBoxChanged("");
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="whatsapp-template-list">
                                {templateList?.map((data, index) => (
                                    <div
                                        id={index}
                                        className="template-container"
                                    >
                                        <div
                                            className="template-content"
                                            onClick={() => {
                                                setPreviewModal(true);
                                                setPreviewData(data);
                                                console.log(data);
                                            }}
                                        >
                                            <div className="template-container-header">
                                                <div
                                                    className="template-status"
                                                    style={{
                                                        backgroundColor:
                                                            data.status ===
                                                            "REJECTED"
                                                                ? "#C21807"
                                                                : data.status ===
                                                                  "PENDING"
                                                                ? "#FFDE21"
                                                                : "#5DBB63",
                                                    }}
                                                ></div>
                                                <h3>{data.name}</h3>
                                            </div>
                                            <p>{data.components[1]?.text}</p>
                                            <div className="template-tag">
                                                {data.name.split("_")[0]}
                                            </div>
                                        </div>
                                        <div className="function-btn-container">
                                            <MdDelete
                                                className="delete-template-btn"
                                                onClick={() =>
                                                    deleteWhatsappTemplate(
                                                        data.name
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgreeBar={false}
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        rtl={false}
                        pauseOnHover
                        theme="colored"
                        transition={Bounce}
                        style={{ zIndex: "9999999999999" }}
                    />
                </div>

                {/* Preview template modal */}
                {previewModal && (
                    <main className="whatsapp-template-modal">
                        <div className="template-preview-main">
                            <div className="template-preview-header">
                                <IoChevronBack
                                    className="template-back-btn"
                                    onClick={() => setPreviewModal(false)}
                                    style={{ fontSize: "1.5rem" }}
                                />
                                <h3>Preview message</h3>
                            </div>
                            <div className="template-preview-content">
                                <div className="template-header">
                                    {/* Display image if exists */}
                                    {previewData.components[0].format ===
                                        "IMAGE" && (
                                        <img
                                            src={
                                                previewData.components[0]
                                                    .example.header_handle[0]
                                            }
                                            width="100%"
                                            style={{
                                                border: "1px solid black",
                                                borderRadius: "15px",
                                            }}
                                        ></img>
                                    )}

                                    {previewData.components[0].format ===
                                        "DOCUMENT" && (
                                        <iframe
                                            src={
                                                previewData.components[0]
                                                    .example.header_handle[0]
                                            }
                                            frameborder="0"
                                        ></iframe>
                                    )}

                                    {/* Display header text if exists */}
                                    {previewData.components[0].format ===
                                        "TEXT" && (
                                        <TemplateText
                                            text={
                                                previewData.components[0]?.text
                                            }
                                            type="header"
                                            example={
                                                previewData.components[0]
                                                    ?.example?.header_text
                                            }
                                        />
                                    )}
                                </div>
                                <div className="template-body">
                                    <TemplateText
                                        text={previewData.components[1]?.text}
                                        type="body"
                                        example={
                                            previewData.components[1]?.example
                                                ?.body_text
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </main>
                )}

                {/* Add template modal */}
                {isModalOpen && (
                    <main
                        className={`test-modal-main ${
                            isModalOpen ? "open" : ""
                        }`}
                    >
                        <div className="settings-template-modal-container">
                            <div className="whatsapp-template-modal-content">
                                <div className="test-modal-header">
                                    <IoIosArrowBack
                                        style={{ fontSize: "1.5rem" }}
                                        className="test-modal-icon"
                                        onClick={closeModal}
                                    />
                                    <h2>Create new whatsapp template</h2>
                                </div>

                                <div className="settings-template-overview">
                                    {/* Actual add template */}
                                    <div className="add-template-container">
                                        <div className="add-template-body">
                                            {/* Template name & Category */}
                                            <div className="add-template-header">
                                                <div className="input-container">
                                                    <input
                                                        type="text"
                                                        id="templateName"
                                                        placeholder="Enter Template Name"
                                                        value={templateName}
                                                        onChange={(e) =>
                                                            setTemplateName(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor="templateName">
                                                        Enter template Name
                                                    </label>
                                                </div>
                                                <div className="input-container">
                                                    <select
                                                        type="text"
                                                        id="templateCategory"
                                                        placeholder="Select template category"
                                                        value={templateCategory}
                                                        onChange={(e) =>
                                                            setTemplateCategory(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option value="UTILITY">
                                                            UTILITY
                                                        </option>
                                                        <option value="MARKETING">
                                                            MARKETING
                                                        </option>
                                                        <option value="AUTHENTICATION">
                                                            AUTHENTICATION
                                                        </option>
                                                    </select>
                                                    <label htmlFor="templateCategory">
                                                        Select template category
                                                    </label>
                                                </div>
                                            </div>

                                            {/* Template file section */}
                                            <div
                                                style={{
                                                    display:
                                                        headerFileVisibility
                                                            ? "flex"
                                                            : "none",
                                                }}
                                                className="add-template-file"
                                            >
                                                <input
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    accept="image/*,video/*,.pdf,.doc,.docx"
                                                />
                                                <div className="file-input-info">
                                                    <span className="file-size-limit">
                                                        Maximum file size: 16MB
                                                    </span>
                                                </div>
                                                {file && (
                                                    <div className="template-file-preview">
                                                        {file.type.startsWith(
                                                            "image/"
                                                        ) && (
                                                            <img
                                                                src={fileUrl}
                                                                alt="Preview"
                                                                style={{
                                                                    maxWidth:
                                                                        "80%",
                                                                }}
                                                            />
                                                        )}
                                                        {file.type ===
                                                            "application/pdf" && (
                                                            <object
                                                                data={fileUrl}
                                                                type="application/pdf"
                                                                className="w-full h-64 rounded-lg"
                                                            >
                                                                <p>
                                                                    PDF preview
                                                                    not
                                                                    available
                                                                </p>
                                                            </object>
                                                        )}
                                                        {file.type.startsWith(
                                                            "video/"
                                                        ) && (
                                                            <video
                                                                controls
                                                                src={fileUrl}
                                                                style={{
                                                                    width: "80%",
                                                                }}
                                                            />
                                                        )}
                                                        <button
                                                            onClick={
                                                                handleRemoveFile
                                                            }
                                                            className="remove-file-button"
                                                        >
                                                            {" "}
                                                            x{" "}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>

                                            {/* Template content: First name last name etc */}
                                            <div className="add-template-text-btn">
                                                {buttonText.map(
                                                    (text, index) => (
                                                        <button
                                                            onClick={() =>
                                                                appendText(
                                                                    `{{${text.value}}}`
                                                                )
                                                            }
                                                        >
                                                            {text.text}
                                                        </button>
                                                    )
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    display:
                                                        headerTextVisibility
                                                            ? "block"
                                                            : "none",
                                                }}
                                                className="input-container"
                                            >
                                                <input
                                                    placeholder="Enter message header"
                                                    value={templateHeader}
                                                    onChange={
                                                        handleTemplateHeaderChange
                                                    }
                                                    ref={headerInputRef}
                                                    onFocus={() =>
                                                        handleInputFocus(
                                                            "header"
                                                        )
                                                    }
                                                />
                                                <label htmlFor="messageHeader">
                                                    Enter message header
                                                </label>
                                            </div>

                                            <div className="input-container">
                                                <textarea
                                                    id="message-body"
                                                    placeholder="Enter message body"
                                                    value={templateBody}
                                                    onChange={
                                                        handleTemplateBodyChange
                                                    }
                                                    style={{
                                                        height: "100px",
                                                        resize: "vertical",
                                                    }}
                                                    ref={bodyInputRef}
                                                    onFocus={() =>
                                                        handleInputFocus("body")
                                                    }
                                                />
                                                <label htmlFor="message-body">
                                                    Enter message body
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Template Preview Section */}
                                    <div className="add-template-preview">
                                        <div>
                                            {file && (
                                                <div className="template-file-preview">
                                                    {file.type.startsWith(
                                                        "image/"
                                                    ) && (
                                                        <img
                                                            src={fileUrl}
                                                            alt="Preview"
                                                            style={{
                                                                maxWidth: "80%",
                                                            }}
                                                        />
                                                    )}
                                                    {file.type ===
                                                        "application/pdf" && (
                                                        <object
                                                            data={fileUrl}
                                                            type="application/pdf"
                                                            className="w-full h-64 rounded-lg"
                                                        >
                                                            <p>
                                                                PDF preview not
                                                                available
                                                            </p>
                                                        </object>
                                                    )}
                                                    {file.type.startsWith(
                                                        "video/"
                                                    ) && (
                                                        <video
                                                            controls
                                                            src={fileUrl}
                                                            className="max-w-full h-auto rounded-lg"
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            <h3>{templateHeader}</h3>
                                            <pre>{templateBody}</pre>
                                        </div>
                                    </div>
                                </div>

                                {/* Add template button */}
                                <div className="settings-add-template-btn">
                                    <button
                                        onClick={createWhatsAppTemplate}
                                        disabled={isSaving}
                                    >
                                        {isSaving
                                            ? "Loading..."
                                            : "Save Template"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                )}
            </div>
        </React.Fragment>
    );
}

// ------------------------------------------------------------------------------------

// Mailbox passcode / Maindoor passcode management page -------------------------------
function PasscodeManagement() {
    const { userToken } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeView, setActiveView] = useState("content");
    const [passcodeData, setPasscodeData] = useState({});
    const [filteredPasscodeData, setFilteredPasscodeData] = useState([]);
    const [passcodeChanges, setPasscodeChanges] = useState({});

    const mergePasscodeData = (unitOption, passcodes) => {
        const merged = {};
        
        // Initialize structure from unitOption
        Object.entries(unitOption).forEach(([building, units]) => {
            merged[building] = units.map(unit => ({
                unit,
                mailbox_passcode: '',
                maindoor_passcode: '',
                wifi_username:  '',
                wifi_password: '',
            }));
        });
    
        // Merge in passcode data
        passcodes.forEach(passcode => {
            const building = passcode.building;
            if (merged[building]) {
                const unitIndex = merged[building].findIndex(u => u.unit === passcode.unit);
                if (unitIndex !== -1) {
                    merged[building][unitIndex] = {
                        ...merged[building][unitIndex],
                        mailbox_passcode: passcode.mailbox_passcode,
                        maindoor_passcode: passcode.maindoor_passcode,
                        wifi_username: passcode.wifi_username,
                        wifi_password: passcode.wifi_password
                    };
                }
            }
        });
    
        return merged;
    };

    
    // Fetch data from API
    useEffect(() => {
        if (userToken) {
            const fetchBuildingAndUnit = async () => {
                setIsLoading(true);
                try {
                    const data = await API.getChatFlowList(userToken);

                    // Fetch passcodes
                    const passcodeArray = await API.getPasscode({'token': userToken})
                    // Massflow data
                    const filteredData = data.filter((chatflow) => chatflow.type === "massflow" && chatflow.error_status === true).map((chatflow) => ({value: chatflow.id,label: chatflow.chatflow_name,}));

                    // Unit data
                    let mappedUnit = {};

                    const fetchUnitNodes = async (chatflow) => {
                        try {
                            const unitNodes = await API.getNodes({
                                token: userToken,
                                id: chatflow.value,
                                unit: true,
                            });

                            // Combine the text fields into a single array
                            const unitNodeComplete = unitNodes.reduce(
                                (acc, node) => {
                                    const nodeTextArray = JSON.parse(node.text);
                                    return [...acc, ...nodeTextArray];
                                },
                                []
                            );

                            mappedUnit[chatflow.label] = unitNodeComplete.map(
                                (node) => (node)
                            );
                        } catch (error) {
                            console.error(error);
                        }
                    };
                    await Promise.all(
                        filteredData.map((chatflow) => fetchUnitNodes(chatflow))
                    );

                    setFilteredPasscodeData(mergePasscodeData(mappedUnit, passcodeArray))
                    setPasscodeData(mergePasscodeData(mappedUnit, passcodeArray));
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchBuildingAndUnit();
        }
    }, [userToken]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleSearchInputChange = (event) => {
        console.log(passcodeData);
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    
        if (query.trim() === '') {
            setFilteredPasscodeData(passcodeData);
        } else {
            const filtered = Object.entries(passcodeData).reduce((acc, [key, units]) => {
                const matchedUnits = units.filter(unitObj =>
                    unitObj.unit.toLowerCase().includes(query)
                );
    
                if (matchedUnits.length > 0) {
                    acc[key] = matchedUnits; // Keep the same key (Face1, Face2, etc.)
                }
    
                return acc;
            }, {});
    
            setFilteredPasscodeData(filtered);
        }
    };

    const handlePasscodeChange = (building, unit, field, value) => {
        setFilteredPasscodeData(prevData => {
            const newData = {...prevData};
            const buildingUnits = newData[building];
            const unitIndex = buildingUnits.findIndex(u => u.unit === unit);
            
            if (unitIndex !== -1) {
                buildingUnits[unitIndex] = {
                    ...buildingUnits[unitIndex],
                    [field]: value
                };
            }
            
            return newData;
        });
        
        // Track changes for saving later while preserving existing changes
        setPasscodeChanges(prev => {
            const existingChange = prev[`${building}-${unit}`] || {};
            return {
                ...prev,
                [`${building}-${unit}`]: {
                    ...existingChange,
                    building,
                    unit,
                    [field]: value
                }
            };
        });
    };

    const savePasscodes = async() => {
        // Get all changes
        Object.values(passcodeChanges).forEach(async (change) => {
            const { building, unit, mailbox_passcode, maindoor_passcode, wifi_username, wifi_password } = change;

            const resp = await API.createPasscode({
                token: userToken, 
                unit: unit,
                building: building, 
                mailbox_passcode: mailbox_passcode,
                maindoor_passcode: maindoor_passcode,
                wifi_username: wifi_username,
                wifi_password: wifi_password,
            });

            // If create fails due to unique constraint, try update
            console.log(resp);
            if (resp?.non_field_errors?.[0]?.includes('unique set')) {
                console.log('UPDATE RAN')
                const resp = await API.updatePasscode({
                    token: userToken,
                    unit: unit,
                    building: building,
                    mailbox_passcode: mailbox_passcode,
                    maindoor_passcode: maindoor_passcode,
                    wifi_username: wifi_username,
                    wifi_password: wifi_password,
                });
            }
        });

        toast.success('Passcode updated successfully');
    };

    
    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Passcode Management </title>
            </Helmet>
            <Sidenav2 />
            <div className="sides">
                <SidenavSettings />
            </div>
            <div className={`settings-column2 ${ !isMobile || activeView === "content" ? "" : "active" }`}>
                    <main className="settings-account-main">
                        <div className="settings-notifications-header">
                            {isMobile && (
                                <Link to="/main/settings" className="sett-link">
                                    <FaArrowLeft />
                                </Link>
                            )}
                            <h1>Passcode Management</h1>
                        </div>

                        <div className="search-filter-container" >
                            <div className="input-container" style={{marginBottom: '0.5rem'}}>
                                <input type="text" id="searchInput" placeholder="Search Contacts" value={searchQuery} onChange={handleSearchInputChange} disabled={isLoading}/>
                                <label for="searchInput">Search Units</label>
                            </div>
                        </div>

                        <div className='save-passcode-btn'>
                            <button className="add-whatsapp-template-btn" onClick={savePasscodes}>
                                Save
                            </button>
                        </div>

                        <div className="contacts-table-container" style={{marginTop: '1rem'}}>
                            {isLoading ? (
                                <div className="loading-spinner-container">
                                    <div className="loading-spinner"></div>
                                </div>
                            ) : (
                                <table className="contacts-table">
                                    <thead>
                                        <tr>
                                            <th className="table-header">Building</th>
                                            <th className="table-header">Unit</th>
                                            <th className="table-header">Main Door Passcode</th>
                                            <th className="table-header">Mailbox Passcode</th>
                                            <th className="table-header">Wifi Username</th>
                                            <th className="table-header">Wifi Password</th>
                                            <th className="table-header">Parking</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(filteredPasscodeData).map(([building, units]) => {
                                        // First row shows building name
                                        return [
                                            <tr key={`${building}-header`} className="table-row">
                                                <td className="table-cell">
                                                    <div className="py-1">{building}</div>
                                                </td>
                                                <td className="table-cell">
                                                    <div className="py-1">{units[0].unit}</div>
                                                </td>
                                                <td className="table-cell">
                                                    <input 
                                                        type="text" 
                                                        className="table-input" 
                                                        value={units[0].maindoor_passcode} 
                                                        onChange={(e) => handlePasscodeChange(building, units[0].unit, 'maindoor_passcode', e.target.value)}
                                                        placeholder="Enter main door passcode"
                                                    />
                                                </td>
                                                <td className="table-cell">
                                                    <input 
                                                        type="text" 
                                                        className="table-input" 
                                                        value={units[0].mailbox_passcode} 
                                                        onChange={(e) => handlePasscodeChange(building, units[0].unit, 'mailbox_passcode', e.target.value)}
                                                        placeholder="Enter mailbox passcode"
                                                    />
                                                </td>
                                                <td className="table-cell">
                                                    <input 
                                                        type="text" 
                                                        className="table-input" 
                                                        value={units[0].wifi_username} 
                                                        onChange={(e) => handlePasscodeChange(building, units[0].unit, 'wifi_username', e.target.value)}
                                                        placeholder="Enter wifi username"
                                                    />
                                                </td>
                                                <td className="table-cell">
                                                    <input 
                                                        type="text" 
                                                        className="table-input" 
                                                        value={units[0].wifi_password} 
                                                        onChange={(e) => handlePasscodeChange(building, units[0].unit, 'wifi_password', e.target.value)}
                                                        placeholder="Enter wifi password"
                                                    />
                                                </td>
                                                <td className="table-cell">
                                                    <input 
                                                        type="text" 
                                                        className="table-input" 
                                                        value={units[0].parking} 
                                                        onChange={(e) => handlePasscodeChange(building, units[0].unit, 'parking', e.target.value)}
                                                        placeholder="Enter Parking"
                                                    />
                                                </td>
                                            </tr>,
                                            // Subsequent rows show empty building cell
                                            ...units.slice(1).map((unitData, index) => (
                                                <tr key={`${building}-${index + 1}`} className="table-row">
                                                    <td className="table-cell">
                                                        <div className="py-1"></div>
                                                    </td>
                                                    <td className="table-cell">
                                                        <div className="py-1">{unitData.unit}</div>
                                                    </td>
                                                    <td className="table-cell">
                                                        <input 
                                                            type="text"
                                                            className="table-input"
                                                            value={unitData.maindoor_passcode}
                                                            onChange={(e) => handlePasscodeChange(building, unitData.unit, 'maindoor_passcode', e.target.value)}
                                                            placeholder="Enter main door passcode"
                                                        />
                                                    </td>
                                                    <td className="table-cell">
                                                        <input 
                                                            type="text"
                                                            className="table-input"
                                                            value={unitData.mailbox_passcode}
                                                            onChange={(e) => handlePasscodeChange(building, unitData.unit, 'mailbox_passcode', e.target.value)}
                                                            placeholder="Enter mailbox passcode"
                                                        />
                                                    </td>
                                                    <td className="table-cell">
                                                        <input 
                                                            type="text" 
                                                            className="table-input" 
                                                            value={unitData.wifi_username} 
                                                            onChange={(e) => handlePasscodeChange(building, unitData.unit, 'wifi_username', e.target.value)}
                                                            placeholder="Enter wifi username"
                                                        />
                                                    </td>
                                                    <td className="table-cell">
                                                        <input 
                                                            type="text" 
                                                            className="table-input" 
                                                            value={unitData.wifi_password} 
                                                            onChange={(e) => handlePasscodeChange(building, unitData.unit, 'wifi_password', e.target.value)}
                                                            placeholder="Enter wifi password"
                                                        />
                                                    </td>
                                                    <td className="table-cell">
                                                        <input 
                                                            type="text" 
                                                            className="table-input" 
                                                            value={unitData.parking} 
                                                            onChange={(e) => handlePasscodeChange(building, unitData.unit, 'parking', e.target.value)}
                                                            placeholder="Enter Parking"
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        ];
                                    })}
                                    </tbody>
                                </table>
                            )}
                            </div>
                        </main>
                <ToastContainer position="bottom-center" autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme="colored" transition={Bounce}/>
            </div>
        </React.Fragment>
    );
}
// ------------------------------------------------------------------------------------

// Plans and Billing page -----------------------------------------------------------
function PlansAndBilling() {
    const [activeTab, setActiveTab] = useState("plans");
    const {
        userToken,
        userId,
        contactsCount,
        subscriptionPlan,
        translateFunction,
        chatGPTFunction,
    } = useContext(UserContext);
    const [contactsLength, setContactsLength] = useState(0);
    const [wabaCount, setWabaCount] = useState(0);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [userCount, setUserCount] = useState(0);
    const [userData, setUserData] = useState([]);
    const [activeView, setActiveView] = useState("settings");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const fetchAccountData = async () => {
            try {
                // Fetch the number of customer contacts
                const data = await API.getClientUserList(userToken);
                const dataLength = data.length;
                setContactsLength(dataLength);

                // Fetch user data
                const userDataResponse = await API.fetchSecondaryProfile({
                    user_id: userId,
                    token: userToken,
                });
                setUserData(userDataResponse);
                setUserCount(userDataResponse.length);

                // Fetch the number of WABA
                const wabaData = await API.getWabaId(userToken);
                setWabaCount(wabaData.length);

                if (subscriptionPlan) {
                    setCurrentPlan(subscriptionPlan);
                } else {
                    setCurrentPlan("Free"); // Default to "Free" if no main subscription is found
                }
            } catch (error) {
                console.error("Error fetching account data:", error);
                setCurrentPlan("Free"); // Set to "Free" if there's an error
            }
        };

        fetchAccountData();
    }, [userToken]);

    // Account Data Section -----------------------------------------------------------------
    const AccountData = () => {
        const plans = {
            Free: { name: "Free", contacts: 10, users: 1, whatsappAccounts: 1 },
            Small: {
                name: "Small",
                contacts: 200,
                users: Infinity,
                whatsappAccounts: 2,
                onboardingSupport: true,
                freeTrial: true,
            },
            Medium: {
                name: "Medium",
                contacts: 990,
                users: Infinity,
                whatsappAccounts: 6,
                onboardingSupport: true,
                customerSupport: "WhatsApp chat and email for customer support",
                freeTrial: true,
            },
            Large: {
                name: "Large",
                contacts: Infinity,
                users: Infinity,
                whatsappAccounts: Infinity,
                onboardingSupport: true,
                customerSupport: "WhatsApp chat and email for customer support",
                freeTrial: true,
            },
        };

        const renderProgressBar = (current, max) => {
            const percentage = (current / max) * 100;
            return (
                <div className="progress-bar">
                    <div
                        className="progress"
                        style={{ width: `${percentage}%` }}
                    ></div>
                </div>
            );
        };

        if (!currentPlan || !plans[currentPlan]) {
            return <div>Loading plan data...</div>;
        }

        return (
            <div className="account-data">
                <h2>Account Data</h2>
                <p>
                    Current Plan:{" "}
                    {currentPlan ? plans[currentPlan].name : "Loading..."}
                    {currentPlan === "Free" && " (Free Plan)"}
                </p>
                <div className="plan-data">
                    <h3>
                        {currentPlan ? plans[currentPlan].name : "Loading..."}{" "}
                        <span>Current Plan</span>
                    </h3>

                    <div class="plan-data-item">
                        <div class="plan-data-item-header">
                            <div class="plan-data-item-title">
                                Customer Contacts
                            </div>
                            <div class="plan-data-item-bar">
                                <div class="progress-bar">
                                    {renderProgressBar(
                                        contactsLength,
                                        plans[currentPlan]?.contacts
                                    )}
                                </div>
                                <div class="plan-data-item-details">
                                    Contact Saved: {contactsLength} /{" "}
                                    {plans[currentPlan]?.contacts}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="plan-data-item">
                        <div class="plan-data-item-header">
                            <div class="plan-data-item-title">User</div>
                            <div class="plan-data-item-bar">
                                <div class="progress-bar">
                                    {renderProgressBar(
                                        userCount,
                                        plans[currentPlan]?.users
                                    )}
                                </div>
                                <div className="plan-data-item-details">
                                    User Account Created: {userCount} /{" "}
                                    {plans[currentPlan]?.users}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="plan-data-item">
                        <div class="plan-data-item-header">
                            <div className="plan-data-item-title">
                                WhatsApp Business Account
                            </div>
                            <div class="plan-data-item-bar">
                                <div class="progress-bar">
                                    {renderProgressBar(
                                        wabaCount,
                                        plans[currentPlan]?.whatsappAccounts
                                    )}
                                </div>
                                <div className="plan-data-item-details">
                                    WhatsApp Business Account Created:{" "}
                                    {wabaCount} /{" "}
                                    {plans[currentPlan]?.whatsappAccounts}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    // ------------------------------------------------------------------------------------

    // Plans Section ---------------------------------------------------------------------
    const Plans = () => {
        const { subscriptionPlan, userToken } = useContext(UserContext);
        const planOrder = ["Free", "Small", "Medium", "Large"];
        const [userAddOn, setUserAddOn] = useState(1);
        const [accountAddOn, setAccountAddOn] = useState(1);
        const [contactAddOn, setContactAddOn] = useState(1);
        const [smallPrice, setSmallPrice] = useState("RM500");
        const [mediumPrice, setMediumPrice] = useState("RM900");
        const [largePrice, setLargePrice] = useState("RM1,300");

        const getButtonText = (planName) => {
            if (subscriptionPlan === planName) return "Cancel Plan";
            const currentIndex = planOrder.indexOf(currentPlan);
            const planIndex = planOrder.indexOf(planName);
            return planIndex > currentIndex ? "Upgrade Plan" : "Downgrade Plan";
        };

        const handleUserAddOnChange = (e) => {
            e.preventDefault();
            const value = e.target.value.replace(/\D/g, "");
            setUserAddOn(value);
        };

        const cancelSubscription = async (planName) => {
            const response = await API.cancelSubscription({
                token: userToken,
                plan_type: planName,
            });
            toast.success("Subscription cancelled successfully");
        };

        return (
            <div>
                <section className="pricing-section" id="pricing">
                    <div className="pricing-body-setting">
                        <div
                            className={
                                subscriptionPlan === "Free"
                                    ? "pricing-medium"
                                    : "pricing-free"
                            }
                        >
                            <div className="pricing-content-header">
                                <p>Free</p>
                                <h1>
                                    <span className="price-setting">RM 0</span>{" "}
                                    /month
                                </h1>
                                <button disabled={currentPlan !== "Free"}>
                                    {getButtonText("Free")}
                                </button>
                            </div>
                            <div className="pricing-content-body">
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    25 contacts / month
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Unlimited user
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />1
                                    WhatsApp Business Accounts
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Free onboarding support
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Basic support
                                </p>
                            </div>
                        </div>
                        {/* Small Package */}
                        <div
                            className={
                                subscriptionPlan === "Small"
                                    ? "pricing-medium"
                                    : "pricing-small"
                            }
                        >
                            <div className="pricing-content-header">
                                <p>Small</p>
                                <h1>
                                    <span className="price-setting">
                                        {" "}
                                        {smallPrice}
                                    </span>{" "}
                                    /month
                                </h1>
                                <form
                                    action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/"
                                    method="POST"
                                    style={{
                                        display:
                                            currentPlan === "Small"
                                                ? "none"
                                                : "block",
                                    }}
                                >
                                    <input
                                        type="hidden"
                                        name="user_id"
                                        value={userId}
                                    />
                                    <input
                                        type="hidden"
                                        name="plan"
                                        value="small_monthly"
                                    />
                                    <input
                                        type="hidden"
                                        name="unit"
                                        value="1"
                                    />
                                    <button
                                        id="plan-standard-button"
                                        disabled={
                                            currentPlan !== "Small" &&
                                            currentPlan !== "Free"
                                        }
                                    >
                                        {getButtonText("Small")}
                                    </button>
                                </form>
                                <button
                                    style={{
                                        display:
                                            currentPlan === "Small"
                                                ? "block"
                                                : "none",
                                    }}
                                    onClick={() =>
                                        cancelSubscription(currentPlan)
                                    }
                                >
                                    {getButtonText("Small")}
                                </button>
                            </div>
                            <div className="pricing-content-body">
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    200 contacts / month
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Unlimited user
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />2
                                    WhatsApp Business Accounts
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Free onboarding support
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Basic support
                                </p>
                            </div>
                        </div>

                        {/* Medium package */}
                        <div
                            className={
                                subscriptionPlan === "Medium"
                                    ? "pricing-medium"
                                    : "pricing-large"
                            }
                        >
                            <div className="pricing-content-header">
                                <div className="tier">
                                    <p>Medium</p>
                                    <div
                                        className="popular-tag"
                                        style={{
                                            display:
                                                subscriptionPlan === "Medium"
                                                    ? "block"
                                                    : "none",
                                        }}
                                    >
                                        Most Popular
                                    </div>
                                </div>
                                <h1>
                                    <span className="price-setting">
                                        {mediumPrice}
                                    </span>{" "}
                                    /month
                                </h1>
                                <form
                                    action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/"
                                    method="POST"
                                    style={{
                                        display:
                                            currentPlan === "Medium"
                                                ? "none"
                                                : "block",
                                    }}
                                >
                                    <input
                                        type="hidden"
                                        name="user_id"
                                        value={userId}
                                    />
                                    <input
                                        type="hidden"
                                        name="plan"
                                        value="medium_monthly"
                                    />
                                    <input
                                        type="hidden"
                                        name="unit"
                                        value="1"
                                    />
                                    <button
                                        id="plan-standard-button"
                                        disabled={
                                            currentPlan !== "Medium" &&
                                            currentPlan !== "Free"
                                        }
                                    >
                                        {getButtonText("Medium")}
                                    </button>
                                </form>
                                <button
                                    style={{
                                        display:
                                            currentPlan === "Medium"
                                                ? "block"
                                                : "none",
                                    }}
                                    onClick={() =>
                                        cancelSubscription(currentPlan)
                                    }
                                >
                                    {getButtonText("Medium")}
                                </button>
                            </div>
                            <div className="pricing-content-body">
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    990 contacts / month
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Unlimited user
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />2
                                    WhatsApp Business Accounts
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Free onboarding support
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Advanced support
                                </p>
                            </div>
                        </div>

                        {/* Large package */}
                        <div
                            className={
                                subscriptionPlan === "Large"
                                    ? "pricing-medium"
                                    : "pricing-large"
                            }
                        >
                            <div className="pricing-content-header">
                                <div className="tier">
                                    <p>Large</p>
                                </div>
                                <h1>
                                    <span className="price-setting">
                                        {largePrice}
                                    </span>{" "}
                                    /month
                                </h1>
                                <form
                                    action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/"
                                    method="POST"
                                    style={{
                                        display:
                                            currentPlan === "Large"
                                                ? "none"
                                                : "block",
                                    }}
                                >
                                    <input
                                        type="hidden"
                                        name="user_id"
                                        value={userId}
                                    />
                                    <input
                                        type="hidden"
                                        name="plan"
                                        value="large_monthly"
                                    />
                                    <input
                                        type="hidden"
                                        name="unit"
                                        value="1"
                                    />
                                    <button
                                        id="plan-standard-button"
                                        disabled={
                                            currentPlan !== "Large" &&
                                            currentPlan !== "Free"
                                        }
                                    >
                                        {getButtonText("Large")}
                                    </button>
                                </form>
                                <button
                                    style={{
                                        display:
                                            currentPlan === "Large"
                                                ? "block"
                                                : "none",
                                    }}
                                    onClick={() =>
                                        cancelSubscription(currentPlan)
                                    }
                                >
                                    {getButtonText("Large")}
                                </button>
                            </div>
                            <div className="pricing-content-body">
                                <p>
                                    <IoIosCheckmark className="tick-icon" />{" "}
                                    Unlimited contacts
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Unlimited user
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />2
                                    WhatsApp Business Accounts
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Free onboarding support
                                </p>
                                <p>
                                    <IoIosCheckmark className="tick-icon" />
                                    Priority support
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Add on section */}
                <section
                    className="add-on-plan-section"
                    style={{
                        display: subscriptionPlan === "Free" ? "none" : "block",
                    }}
                >
                    <div className="add-on-header">
                        <h2>Add On Packages</h2>
                    </div>
                    <div className="add-on-div">
                        <div className="add-on-content">
                            <div className="add-on-card">
                                <div className="add-on-detail">
                                    <h2>User add on</h2>
                                    <div className="add-on-unit">
                                        <p>
                                            <b>RM 250</b> per user
                                        </p>
                                    </div>
                                </div>
                                <div className="add-on-button">
                                    <input
                                        type="number"
                                        min="1"
                                        value={userAddOn}
                                        onChange={handleUserAddOnChange}
                                    />
                                    <form
                                        action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/"
                                        method="POST"
                                    >
                                        <input
                                            type="hidden"
                                            name="user_id"
                                            value={userId}
                                        />
                                        <input
                                            type="hidden"
                                            name="plan"
                                            value="user"
                                        />
                                        <input
                                            type="hidden"
                                            name="unit"
                                            value={userAddOn}
                                        />
                                        <button>Purchase add-on</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="add-on-content">
                            <div className="add-on-card">
                                <div className="add-on-detail">
                                    <h2>Account add on</h2>
                                    <div className="add-on-unit">
                                        <p>
                                            <b>RM 400</b> per account
                                        </p>
                                    </div>
                                </div>
                                <div className="add-on-button">
                                    <input
                                        type="number"
                                        min="1"
                                        value={accountAddOn}
                                        onChange={(e) =>
                                            setAccountAddOn(e.target.value)
                                        }
                                    />
                                    <form
                                        action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/"
                                        method="POST"
                                    >
                                        <input
                                            type="hidden"
                                            name="user_id"
                                            value={userId}
                                        />
                                        <input
                                            type="hidden"
                                            name="plan"
                                            value="account"
                                        />
                                        <input
                                            type="hidden"
                                            name="unit"
                                            value={accountAddOn}
                                        />
                                        <button>Purchase add-on</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="add-on-content">
                            <div className="add-on-card">
                                <div className="add-on-detail">
                                    <h2>Contact add on</h2>
                                    <div className="add-on-unit">
                                        <p>
                                            <b>RM 1.00</b> per contact
                                        </p>
                                    </div>
                                </div>
                                <div className="add-on-button">
                                    <input
                                        type="number"
                                        min="1"
                                        value={contactAddOn}
                                        onChange={(e) =>
                                            setContactAddOn(e.target.value)
                                        }
                                    />
                                    <form
                                        action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/"
                                        method="POST"
                                    >
                                        <input
                                            type="hidden"
                                            name="user_id"
                                            value={userId}
                                        />
                                        <input
                                            type="hidden"
                                            name="plan"
                                            value="contact"
                                        />
                                        <input
                                            type="hidden"
                                            name="unit"
                                            value={contactAddOn}
                                        />
                                        <button>Purchase add-on</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };
    // ------------------------------------------------------------------------------------

    // Actual component -----------------------------------------------------------------
    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Plans And Billing </title>
            </Helmet>
            <Sidenav2 />
            <div className="sides">
                <SidenavSettings />
            </div>
            <div
                className={`settings-column2 ${
                    !isMobile || activeView === "content" ? "active" : ""
                }`}
            >
                <main className="plans-and-billing-main">
                    <div className="plans-and-billing-header">
                        <div className="settings-header">
                            {isMobile && (
                                <Link to="/main/settings" className="sett-link">
                                    <FaArrowLeft />
                                </Link>
                            )}
                            <h1>Plans and Billing</h1>
                        </div>
                        <div className="plans-and-billing-nav">
                            <div
                                className="plans-and-billing-navitem"
                                onClick={() => setActiveTab("plans")}
                            >
                                Plans
                            </div>
                            <div
                                className="plans-and-billing-navitem"
                                onClick={() => setActiveTab("accountData")}
                            >
                                Account Data
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div className="plans-and-billing-content">
                        {activeTab === "plans" ? <Plans /> : <AccountData />}
                    </div>
                </main>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgreeBar={false}
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                    rtl={false}
                    pauseOnHover
                    theme="colored"
                    transition={Bounce}
                />
            </div>
        </React.Fragment>
    );
}
// ------------------------------------------------------------------------------------

// Password and Security page --------------------------------------------------------
function PasswordAndSecurity() {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const { userToken } = useContext(UserContext);

    const [activeView, setActiveView] = useState("settings");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleBackClick = () => {
        setActiveView("settings");
    };

    const changePassword = () => {
        if (newPassword !== newPassword2) {
            toast.error("New Passwords do not match");
            return;
        } else {
            const data = {
                token: userToken,
                old_password: currentPassword,
                new_password: newPassword,
            };
            changePasswordAPI(data);
        }
    };

    const changePasswordAPI = async (body) => {
        const data = await API.changePassword(body);
        if (data["message"] === "Password changed successfully") {
            toast.success("Password changed successfully");
        } else {
            toast.error("Old Password is incorrect");
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Password And Security </title>
            </Helmet>
            <Sidenav2 />
            <div className="sides">
                <SidenavSettings />
            </div>
            <div
                className={`settings-column2 ${
                    !isMobile || activeView === "content" ? "active" : ""
                }`}
            >
                <main className="password-and-security-main">
                    <div className="password-and-security-header">
                        {isMobile && (
                            <Link to="/main/settings" className="sett-link">
                                <FaArrowLeft />
                            </Link>
                        )}
                        <h1>Password and Security</h1>
                    </div>
                    <hr />
                    <div className="password-and-security-content">
                        <div className="password-and-security-content-box">
                            <h2>Change Password</h2>
                            <p>
                                Raw passwords are not stored, so there is no way
                                for us to see this user's password
                            </p>
                            <div className="password-and-security-input">
                                <div className="cur-pass">
                                    <label htmlFor="currentPassword">
                                        Current Password
                                    </label>
                                    <input
                                        id="currentPassword"
                                        type="password"
                                        title="Current Password"
                                        placeholder="Current Password"
                                        value={currentPassword}
                                        onChange={(e) =>
                                            setCurrentPassword(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="new-pass">
                                    <label htmlFor="newPassword">
                                        New Password
                                    </label>
                                    <input
                                        id="newPassword"
                                        type="password"
                                        title="New Password"
                                        placeholder="New Password"
                                        value={newPassword}
                                        onChange={(e) =>
                                            setNewPassword(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="confirm-pass">
                                    <label htmlFor="newPassword2">
                                        Confirm New Password
                                    </label>
                                    <input
                                        id="newPassword2"
                                        type="password"
                                        title="Confirm New Password"
                                        placeholder="Confirm New Password"
                                        value={newPassword2}
                                        onChange={(e) =>
                                            setNewPassword2(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <button
                                className="password-and-security-button"
                                onClick={changePassword}
                            >
                                Change Password
                            </button>
                        </div>
                    </div>
                </main>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgreeBar={false}
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                    rtl={false}
                    pauseOnHover
                    theme="colored"
                    transition={Bounce}
                />
            </div>
        </React.Fragment>
    );
}

function Chatbot() {
    const { userToken, wabaId } = useContext(UserContext);
    const [chatbots, setChatbots] = useState([]);
    const [selectedChatbot, setSelectedChatbot] = useState({ id: "default" });
    const [assistantName, setAssistantName] = useState("default");
    const [instructions, setInstructions] = useState("");
    const [originalName, setOriginalName] = useState("default");
    const [originalInstructions, setOriginalInstructions] = useState("");
    const { profileId } = useContext(UserContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [activeView, setActiveView] = useState("settings");
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // Add new state variables for persona and QNA
    const [persona, setPersona] = useState("");
    const [qna, setQna] = useState([]);
    const [originalPersona, setOriginalPersona] = useState("");
    const [originalQna, setOriginalQna] = useState([]);

    // Add new state for format selection
    const safeParse = (item) => {
        try {
            // return JSON.parse(item.replace(/'/g, '"')); // Fix single quotes if needed
            return JSON.parse(item); // Fix single quotes if needed
        } catch (error) {
            console.error("Failed to parse item:", item, error);
            return null; // Return null if parsing fails
        }
    };
    
    const selectChatbot = (chatbot) => {

        // Add null checks and default values
        if (!chatbot) {
            console.error("No chatbot data provided");
            return;
        }

        setSelectedChatbot(chatbot);
        setAssistantName(chatbot.chatbot_name || "");
        setInstructions(chatbot.chatbot_role || "");
        setPersona(chatbot.chatbot_persona || "");

        // Initialize QNA with empty array if null/undefined
        const qnaData = chatbot.chatbot_qna || [];
        // Ensure QNA is always an array
        setQna(qnaData);


        // Set original values
        setOriginalName(chatbot.chatbot_name || "");
        setOriginalInstructions(chatbot.chatbot_role || "");
        setOriginalPersona(chatbot.chatbot_persona || "");
        setOriginalQna(Array.isArray(qnaData) ? qnaData : []);
    };

    useEffect(() => {
        console.log(wabaId);
        if (profileId) {
            setIsLoading(true);
            API.getChatbot(profileId)
                .then((response) => {
                    console.log("Chatbots:", response);
                    if (response && Array.isArray(response)) {
    
                        // Ensure chatbots belonging to the current wabaId
                        const relevantChatbots = response.filter(
                            (bot) => bot.waba_id === wabaId || bot.waba_id === null
                        );
    
                        // Update chatbot list
                        setChatbots(relevantChatbots);
    
                        // Find default chatbot for the given wabaId
                        const defaultChatbot = relevantChatbots.find(
                            (chatbot) => chatbot.chatbot_name === "default" && chatbot.waba_id === wabaId
                        );
    
                        // If no default chatbot with waba_id, find any other default chatbot
                        const fallbackChatbot = defaultChatbot ?? relevantChatbots.find(
                            (chatbot) => chatbot.chatbot_name === "default"
                        );
    
                        if (fallbackChatbot) {
                            selectChatbot(fallbackChatbot);
                        } else {
                            // Set default state if no chatbot is found
                            setSelectedChatbot({ id: "default" });
                            setAssistantName("default");
                            setInstructions("");
                            setOriginalName("default");
                            setOriginalInstructions("");
                            setOriginalPersona("");
                            setOriginalQna([]);
                        }
                    } else {
                        setChatbots([]);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching chatbots:", error);
                    setChatbots([]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [profileId, wabaId]);
    

    const fetchChatbots = async (selectedChatbot) => {
        API.getChatbot(profileId)
            .then((response) => {
                if (response && Array.isArray(response)) {
                    setChatbots(response);
                    const chatbotToSelect = response.find(
                        (chatbot) => chatbot.id === selectedChatbot.id
                    );
                    if (chatbotToSelect) {
                        selectChatbot(chatbotToSelect);
                    }
                } else {
                    setChatbots([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching chatbots:", error);
                setChatbots([]);
            });
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        if (newName && newName.toLowerCase() === "default") {
            return;
        }
        setAssistantName(newName);
    };

    const handleInstructionsChange = (e) => {
        setInstructions(e.target.value);
    };

    const handleChatbotChange = (e) => {
        const chatbotId = e.target.value;

        // if (
        //     selectedChatbot &&
        //     (assistantName !== originalName ||
        //         instructions !== originalInstructions ||
        //         persona !== originalPersona ||
        //         JSON.stringify(qna) !== JSON.stringify(originalQna))
        // ) {
        //     if (
        //         !window.confirm(
        //             "You have unsaved changes. Do you want to discard them?"
        //         )
        //     ) {
        //         e.preventDefault();
        //         return;
        //     }
        // }

        if (chatbotId === "new") {
            setSelectedChatbot(null);
            setAssistantName(""); // Set blank name for new chatbot
            setInstructions("");
            setPersona("");
            setQna([]);
            setOriginalName("");
            setOriginalInstructions("");
            setOriginalPersona("");
            setOriginalQna([]);
        } else if (chatbotId === "default") {
            setSelectedChatbot({ id: "default" });
            setAssistantName("default");
            setInstructions("");
            setPersona("");
            setQna([]);
            setOriginalName("default");
            setOriginalInstructions("");
            setOriginalPersona("");
            setOriginalQna([]);
        } else {
            const chatbot = chatbots.find((c) => c.id === parseInt(chatbotId));
            if (chatbot) {
                selectChatbot(chatbot);
            }
        }
    };

    const isNameUnique = (name) => {
        return !chatbots.some(
            (chatbot) =>
                chatbot.chatbot_name === name &&
                chatbot.id !== selectedChatbot?.id
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!selectedChatbot && !assistantName.trim()) {
            toast.error("Assistant name is required for new chatbot");
            return;
        }

        // if (!isNameUnique(assistantName)) {
        //     toast.error(
        //         "Chatbot name must be unique. Please choose another name."
        //     );
        //     return;
        // }

        const chatbotData = {
            chatbot_id: selectedChatbot ? selectedChatbot.id : null,
            chatbot_name: assistantName,
            chatbot_role: instructions,     
            chatbot_persona: persona,
            chatbot_qna: qna,
            profileId: profileId,
        };

        if (selectedChatbot) {
            // Update existing chatbot
            API.modifyChatbot(chatbotData)
                .then((response) => {
                    toast.success("Assistant settings updated successfully!");
                    fetchChatbots(selectedChatbot);
                })
                .catch((error) => {
                    toast.error("Failed to update assistant settings.");
                    console.log(error);
                });
        } else {
            // Create new chatbot
            API.modifyChatbot(chatbotData)
                .then((response) => {
                    toast.success("New Assistant created successfully!");
                    const updatedChatbots = [...chatbots, response];
                    setChatbots(updatedChatbots);
                    selectChatbot(response);
                })
                .catch((error) => {
                    toast.error("Failed to create new assistant.");
                    console.log(error);
                });
        }

        setIsSubmit(!isSubmit);
    };

    // Add these functions after handleSubmit
    const handleAddQA = () => {
        setQna([...qna, { question: "", answer: "" }]);
    };

    const handleDeleteQA = (index) => {
        const newQna = qna.filter((_, i) => i !== index);
        setQna(newQna);
    };

    const handleQnaChange = (index, field, value) => {
    
        // Ensure qna is an array of objects
        const updatedQna = qna.map((item, i) => {
            // If item is a string, try to parse it; otherwise, ensure it's an object
            let parsedItem = typeof item === "string" ? safeParse(item) : item;
            if (!parsedItem || typeof parsedItem !== "object") parsedItem = {};
    
            // Update only the targeted index
            return i === index ? { ...parsedItem, [field]: value } : parsedItem;
        });

        setQna(updatedQna);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | AI Prompt Page</title>
            </Helmet>
            <Sidenav2 />
            <div className="sides">
                <SidenavSettings />
            </div>
            <div className={`settings-column2 ${!isMobile || activeView === "content" ? "active" : ""}`}>
                <div className="settings-account-main">
                    <div className="settings-header">
                        {isMobile && (
                            <Link to="/main/settings" className="sett-link">
                                <FaArrowLeft />
                            </Link>
                        )}
                        <h1>Chatbot Assistant</h1>
                    </div>
                    <hr />
    
                    {isLoading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className="chatbot-settings">
                            <h2 className="chatbot-subtitle">1. Settings</h2>
                            <div className="chatbot-form-group">
                                <label htmlFor="chatbotSelect" className="chatbot-label">Select Chatbot:</label>
                                <select
                                    id="chatbotSelect"
                                    value={selectedChatbot ? (selectedChatbot.id === "default" ? "default" : selectedChatbot.id) : "new"}
                                    onChange={handleChatbotChange}
                                    className="chatbot-select"
                                >
                                    <option value="new">+ New Chatbot</option>
                                    {chatbots && chatbots.length > 0
                                        ? chatbots.map((chatbot) => (
                                            <option key={chatbot.id} value={chatbot.id}>
                                                {chatbot.chatbot_name || "Untitled Assistant"}
                                            </option>
                                        ))
                                        : null}
                                </select>
                            </div>
                            <div className="chatbot-form-group">
                                <label htmlFor="assistantName" className="chatbot-label">Assistant name:</label>
                                <input
                                    id="assistantName"
                                    type="text"
                                    value={assistantName || ""}
                                    onChange={handleNameChange}
                                    placeholder="default"
                                    className="chatbot-input"
                                    disabled={assistantName?.toLowerCase() === "default"}
                                />
                            </div>
    

                            <div className="chatbot-settings-format2">
                                <div className="chatbot-form-group">
                                    <label htmlFor="persona" className="chatbot-label">Persona:</label>
                                    <textarea
                                        id="persona"
                                        value={persona}
                                        onChange={(e) => setPersona(e.target.value)}
                                        placeholder="Enter persona instructions..."
                                        className="chatbot-textarea"
                                    />
                                </div>
                                <div className="chatbot-form-group">
                                    <label htmlFor="instructions" className="chatbot-label">Instructions:</label>
                                    <textarea
                                        id="instructions"
                                        value={instructions}
                                        onChange={handleInstructionsChange}
                                        placeholder="Enter instructions for the model..."
                                        className="chatbot-textarea"
                                    />
                                </div>

                                <div className="chatbot-form-group">
                                    <label className="chatbot-label">Q&A List:</label>
                                    {qna?.map((pair, index) => {
                                        return(
                                            <div key={index} className="qna-pair">
                                                <div className="qna-header">
                                                    <span>Q&A Pair #{index + 1}</span>
                                                    <button type="button" className="delete-qa-btn" onClick={() => handleDeleteQA(index)}>
                                                        <MdDelete />
                                                    </button>
                                                </div>
                                                <div className="qna-input-group">
                                                    <label className="qna-label">Question:</label>
                                                    <textarea
                                                        placeholder="Enter question..."
                                                        value={pair.question || ""}
                                                        onChange={(e) =>
                                                            handleQnaChange(index, "question", e.target.value)
                                                        }
                                                        className="qna-textarea"
                                                        style={{ whiteSpace: "pre-wrap" }}
                                                    />
                                                </div>
                                                <div className="qna-input-group">
                                                    <label className="qna-label">Answer:</label>
                                                    <textarea
                                                        placeholder="Enter answer..."
                                                        value={pair.answer || ""}
                                                        onChange={(e) =>
                                                            handleQnaChange(index, "answer", e.target.value)
                                                        }
                                                        className="qna-textarea"
                                                        style={{ whiteSpace: "pre-wrap" }}
                                                    />
                                                </div>
                                            </div>
                                        )})}
                                    <div className="test-modal-add" onClick={handleAddQA}>
                                        <IoHomeSharp style={{ fontSize: "1.5rem" }} />
                                        <p>Add question and answer</p>
                                    </div>
                                </div>
                            </div>
    
                            <button type="submit" className="chatbot-button chatbot-button-primary">
                                Done
                            </button>
                        </form>
                    )}
    
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        rtl={false}
                        pauseOnHover
                        theme="colored"
                        transition={Bounce}
                    />
                </div>
            </div>
        </React.Fragment>
    );    
}

function NotificationAndAccountSettings() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeView, setActiveView] = useState("settings");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [accounts, setAccounts] = useState([]);
    const [availableTaskTypes, setAvailableTaskTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Add user states
    
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [massFlowOption, setMassFlowOption] = useState([]);
    const {
        userId,
        userToken,
        notAllowTaskTypeNotification,
        profileId,
        setNotAllowTaskTypeNotification,
        whatsappNotification,
        setWhatsappNotification,
        subscriptionPlan,
        todoListNotification,
    } = useContext(UserContext);

    // Change single building state to multiple buildings
    const [selectedBuildings, setSelectedBuildings] = useState([]); // For new user creation

    // Define the buildings options including 'All'
    const buildingOptions = [
        ...massFlowOption.map((building) => ({
            value: building,
            label: building,
        })),
    ];

    // Notification functions ------------------------------------------------------------------------------------------------
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // Fetch created user
                // When fetching the profile, handle the buildings array
                const fetchSecondaryProfile = async () => {
                    try {
                        const response = await API.fetchSecondaryProfile({
                            user_id: userId,
                            token: userToken,
                        });

                        const formatData = response.map((data) => {
                            // Transform buildings array into react-select format

                            const selectedBuildings = data.buildings.map(
                                (building) => ({
                                    value: building,
                                    label:
                                        building === "All"
                                            ? "All Buildings"
                                            : building,
                                })
                            );
                            return {
                                id: data.id,
                                username: data.user.username,
                                email: data.user.email,
                                buildings: selectedBuildings,
                                not_allowed_todo_notifications:data.not_allowed_todo_notifications,
                                allow_whatsapp_notifications:data.allow_whatsapp_notifications,
                            };
                        });

                        setAccounts(formatData);
                    } catch (error) {
                        console.error(
                            "Error fetching secondary profiles:",
                            error
                        );
                        toast.error("Failed to load accounts");
                    }
                };

                const fetchTaskTypes = async () => {
                    try {
                        const types = await API.getTaskTypes({
                            token: userToken,
                        });
                        const taskTypes = types.map((type) => type.task_type);
                        setAvailableTaskTypes(taskTypes);
                    } catch (error) {
                        console.error(
                            "An error occurred while fetching task types:",
                            error
                        );
                        throw error;
                    }
                };

                const fetchFlowList = async () => {
                    try {
                        const data = await API.getChatFlowList(userToken);
                        setMassFlowOption([
                            "All",
                            ...data
                                .filter(
                                    (chatflow) => chatflow.type === "massflow"
                                )
                                .map((chatflow) => chatflow.chatflow_name),
                        ]);
                    } catch (error) {
                        console.error(error);
                        throw error;
                    }
                };

                // Execute all fetches in parallel
                await Promise.all([
                    fetchFlowList(),
                    fetchTaskTypes(),
                    fetchSecondaryProfile(),
                ]);
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failed to load notification settings");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [userId, userToken]);

    const handleTaskTypeToggle = (accountId, taskType) => {
        setAccounts((prevAccounts) =>
            prevAccounts.map((account) => {
                if (account.id === accountId) {
                    // Ensure not_allowed_todo_notifications is always an array
                    const currentNotAllowed =
                        account.not_allowed_todo_notifications || [];
                    let updatedNotAllowed;
                    if (currentNotAllowed.includes(taskType)) {
                        // Remove the task type if it's already in the array
                        updatedNotAllowed = currentNotAllowed.filter(
                            (type) => type !== taskType
                        );
                    } else {
                        // Add the task type if it's not in the array
                        updatedNotAllowed = [...currentNotAllowed, taskType];
                    }
                    API.updateAccountTodoNotifications({
                        account_id: accountId,
                        not_allowed_task_types: updatedNotAllowed,
                        token: userToken,
                    });

                    return {
                        ...account,
                        not_allowed_todo_notifications: updatedNotAllowed,
                    };
                }
                return account;
            })
        );
    };

    // Handle building selection change
    const handleBuildingChange = async (accountId, selectedOptions) => {
        try {
            // If trying to add other buildings when 'All' is already selected
            const currentAccount = accounts.find(
                (account) => account.id === accountId
            );
            const hasAll = currentAccount.buildings.some(
                (building) => building.value === "All"
            );
            const isAddingOthers =
                selectedOptions.length > currentAccount.buildings.length;

            if (hasAll && isAddingOthers) {
                toast.warning(
                    "'All Buildings' is already selected. Please deselect it first to choose specific buildings."
                );
                return;
            }
            // If 'All' is being newly selected, clear other selections
            const newSelection = selectedOptions.find(
                (option) => option.value === "All"
            )
                ? [{ value: "All", label: "All Buildings" }]
                : selectedOptions;
            // Update local state
            setAccounts((prevAccounts) =>
                prevAccounts.map((account) =>
                    account.id === accountId
                        ? { ...account, buildings: newSelection }
                        : account
                )
            );
            // Prepare buildings data for API
            const buildingsForApi = newSelection.map((option) => option.value);
            console.log(buildingsForApi);
            // Send update to API
            const response = await API.updateSecondaryProfile({
                profile_id: accountId,
                buildings: buildingsForApi,
                token: userToken,
            });
            toast.success("Buildings updated successfully");
        } catch (error) {
            console.error("Error updating buildings:", error);
            toast.error("Failed to update buildings");
        }
    };

    const handleUserTaskTypeToggle = (taskType) => {
        setNotAllowTaskTypeNotification((prevNotAllowed) => {
            let updatedNotAllowed;
            if (prevNotAllowed.includes(taskType)) {
                updatedNotAllowed = prevNotAllowed.filter(
                    (type) => type !== taskType
                );
            } else {
                updatedNotAllowed = [...prevNotAllowed, taskType];
            }
            console.log(
                `Toggled ${taskType} for user. Updated notAllowTaskTypeNotification:`,
                updatedNotAllowed
            );
            API.updateAccountTodoNotifications({
                account_id: profileId,
                not_allowed_task_types: updatedNotAllowed,
                token: userToken,
            });
            localStorage.setItem(
                "notAllowTaskTypeNotification",
                updatedNotAllowed
            );
            return updatedNotAllowed;
        });
    };

    const handleSecondaryAccountWhatsappToggle = (accountId) => {
        setAccounts((prevAccounts) =>
            prevAccounts.map((account) => {
                if (account.id === accountId) {
                    const updatedAccount = {
                        ...account,
                        allow_whatsapp_notifications:
                            !account.allow_whatsapp_notifications,
                    };
                    API.updateAccountTodoNotifications({
                        account_id: accountId,
                        allow_whatsapp_notifications:
                            updatedAccount.allow_whatsapp_notifications,
                        token: userToken,
                    });
                    return updatedAccount;
                }
                return account;
            })
        );
    };

    const handleWhatsappToggle = () => {
        setWhatsappNotification(!whatsappNotification);
        console.log("Whatsapp Notification: ", !whatsappNotification);
        API.updateAccountTodoNotifications({
            account_id: profileId,
            allow_whatsapp_notifications: !whatsappNotification,
            token: userToken,
        });
    };
    // --------------------------------------------------------------------------------------------------------------------------------

    // Register user function  ---------------------------------------------------------------------------------------------------------
    // Create new account window
    const openModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(true);
        // Reset form fields when opening modal
        setSelectedBuildings([]);
        setEmail("");
        setUserName("");
        setPassword("");
        setConfirmPassword("");
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // register user button function
    const registerUser = async (event) => {
        event.preventDefault();

        if (
            !userName ||
            !email ||
            !password ||
            selectedBuildings.length === 0 ||
            !confirmPassword
        ) {
            toast.error("Please fill in all fields");
            return;
        }

        if (!validateEmail(email)) {
            toast.error("Please enter a valid email address");
            return;
        }

        if (!validateString(userName)) {
            toast.error(
                "Username must contain only letters, numbers, and @/./+/-/_ characters."
            );
            return;
        }

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        const emailResponse = await API.checkUserExist({ email: email });
        if (emailResponse["exists"] === true) {
            toast.error("A user with that email already exists.");
            return;
        }

        const response = await sendRegisterEmail(email, userName, password);
        console.log(response);
        if (response && typeof response === "object") {
            if (
                response["username"] &&
                response["username"][0] ===
                    "A user with that username already exists."
            ) {
                toast.error("A user with that username already exists.");
                return;
            } else if (response["password"] && response["password"].length > 0) {
                toast.error(response["password"][0]); // Show the actual error message from Django
                return;    
            }
        }

        // Update profile creation to handle multiple buildings
        const responseUserId = response["id"];
        const buildings = selectedBuildings.map((building) => building.value);
        console.log(buildings);
        const createProfile = await API.addProfile({
            user_id: responseUserId,
            buildings: buildings,
            related_user: userId,
        });
        console.log(createProfile);

        toast.success(
            "Registration email has been sent. Please check your email to verify your account."
        );
        closeModal();
    };


    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function validateString(input) {
        const regex = /^[a-zA-Z\d@.+\-_]+$/;
        return regex.test(input);
    }

    // Send email API obtained from api-service.js
    const sendRegisterEmail = async (
        inputEmail,
        inputUserName,
        inputPassword
    ) => {
        const response = await API.sendRegisterEmail({
            email: inputEmail,
            username: inputUserName,
            password: inputPassword,
            re_password: inputPassword,
        });
        return response;
    };

    // ------------------------------------------------------------------------------------

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Notifications & User Page</title>
            </Helmet>
            <Sidenav2 />
            <div className="sides">
                <SidenavSettings />
            </div>
            <div
                className={`settings-column2 ${
                    !isMobile || activeView === "content" ? "active" : ""
                }`}
            >
                <div className="settings-account-main">
                    <div className="settings-notifications-header">
                        {isMobile && (
                            <Link to="/main/settings" className="sett-link">
                                <FaArrowLeft />
                            </Link>
                        )}
                        <h1>Notification & Account Settings</h1>
                        <button
                            className="settings-add-user-btn"
                            onClick={(e) => openModal(e)}
                        >
                            Add User
                        </button>
                    </div>

                    <hr />

                    {isLoading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <>
                            <div className="notification-settings-content">
                                <CheckNotificationSubscription />
                            </div>

                            {/* User Account Notification Settings */}
                            <div className="user-notification-panel">
                                <h2 className="notification-panel-title">
                                    Your Notification Settings
                                </h2>
                                <div className="switch-div-noti">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={whatsappNotification}
                                            onChange={() =>
                                                handleWhatsappToggle()
                                            }
                                        />
                                        <span className="slider-btn"></span>
                                    </label>
                                    <p style={{ fontSize: "1rem" }}>
                                        WhatsApp Notifications
                                    </p>
                                </div>
                                <div className="notification-options">
                                    <h4 className="notification-category">
                                        Task Type Notifications:
                                    </h4>
                                    <div className="settings-task-type-grid">
                                        {availableTaskTypes.map(
                                            (taskType, index) => (
                                                <div
                                                    key={index}
                                                    className="settings-task-type-option"
                                                >
                                                    <div className="switch-div-noti">
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    !notAllowTaskTypeNotification.includes(
                                                                        taskType
                                                                    )
                                                                }
                                                                onChange={() =>
                                                                    handleUserTaskTypeToggle(
                                                                        taskType
                                                                    )
                                                                }
                                                            />
                                                            <span className="slider-btn"></span>
                                                        </label>
                                                        <p
                                                            className="truncate-text"
                                                            style={{
                                                                fontSize:
                                                                    "1rem",
                                                                maxWidth: "60%",
                                                            }}
                                                        >
                                                            {taskType}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>

                            <hr className="notification-divider" />

                            {/* Secondary Accounts Notification Settings */}
                            <h2 className="notification-panel-title">
                                Secondary Accounts Notification Settings
                            </h2>
                            {accounts.length === 0 ? (
                                <div className="no-accounts-message">
                                    <PiLinkBreakBold className="no-accounts-icon" />
                                    <span>No secondary accounts found</span>
                                </div>
                            ) : (
                                accounts.map((account, index) => (
                                    <div className="secondary-accounts-panel">
                                        <div
                                            key={account.id}
                                            className="account-notification-card"
                                        >
                                            <div className="secondary-account-header">
                                                <h3 className="account-name">
                                                    {account.username}
                                                </h3>
                                                {/* <MdDelete style={{fontSize:'1.75rem'}} onClick={}/> */}
                                            </div>
                                            <p className="account-detail">
                                                Email: {account.email}
                                            </p>
                                            <div className="input-container">
                                                <Select
                                                    isMulti
                                                    className="modal-select"
                                                    placeholder="Select buildings"
                                                    options={buildingOptions}
                                                    value={account.buildings}
                                                    onChange={(selected) =>
                                                        handleBuildingChange(
                                                            account.id,
                                                            selected
                                                        )
                                                    }
                                                />
                                                <label htmlFor="accountBuilding"></label>
                                            </div>
                                            <div className="account-notification-options">
                                                <div className="switch-div">
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                account.allow_whatsapp_notifications
                                                            }
                                                            onChange={() =>
                                                                handleSecondaryAccountWhatsappToggle(
                                                                    account.id
                                                                )
                                                            }
                                                        />
                                                        <span className="slider-btn"></span>
                                                    </label>
                                                    <p
                                                        className="truncate-text"
                                                        style={{
                                                            fontSize: "1rem",
                                                        }}
                                                    >
                                                        WhatsApp Notifications
                                                    </p>
                                                </div>
                                                <h4 className="notification-category">
                                                    Task Type Notifications:
                                                </h4>
                                                <div className="settings-task-type-grid">
                                                    {availableTaskTypes.map(
                                                        (taskType, index) => (
                                                            <div
                                                                key={index}
                                                                className="switch-div settings-task-type-option"
                                                            >
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            !account.not_allowed_todo_notifications.includes(
                                                                                taskType
                                                                            )
                                                                        }
                                                                        onChange={() =>
                                                                            handleTaskTypeToggle(
                                                                                account.id,
                                                                                taskType
                                                                            )
                                                                        }
                                                                    />
                                                                    <span className="slider-btn"></span>
                                                                </label>
                                                                <p
                                                                    className="truncate-text"
                                                                    style={{
                                                                        fontSize:
                                                                            "1rem",
                                                                    }}
                                                                >
                                                                    {taskType}
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}

                            <ToastContainer
                                position="bottom-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                closeOnClick
                                pauseOnFocusLoss
                                draggable
                                rtl={false}
                                pauseOnHover
                                theme="colored"
                                transition={Bounce}
                            />
                        </>
                    )}
                </div>
            </div>

            {isModalOpen && (
                <div className="account-modal">
                    <div className="account-modal-content">
                        <div className="modal-header">
                            <FaArrowLeft
                                className="modal-backbtn"
                                onClick={closeModal}
                                size={"1.5rem"}
                            />
                            <h2>Create new user</h2>
                        </div>
                        <div className="modal-title">
                            <h2>Select Building</h2>
                            <div className="settings-account-select">
                                <div className="input-container">
                                    <Select
                                        isMulti
                                        className="modal-select"
                                        placeholder="Select buildings"
                                        options={buildingOptions} // Use buildingOptions instead of mapping massFlowOption directly
                                        value={selectedBuildings}
                                        onChange={(selected) => {
                                            // If "All" is selected, clear other selections
                                            if (
                                                selected.some(
                                                    (option) =>
                                                        option.value === "All"
                                                )
                                            ) {
                                                setSelectedBuildings([
                                                    {
                                                        value: "All",
                                                        label: "All Buildings",
                                                    },
                                                ]);
                                            } else {
                                                setSelectedBuildings(selected);
                                            }
                                        }}
                                    />
                                    <label htmlFor="accountBuilding"></label>
                                </div>
                            </div>
                            <hr />
                            <div className="settings-account-details">
                                <h2>Account Details</h2>
                                <div className="modal-inline">
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="modal-inline">
                                    <input
                                        type="text"
                                        name="username"
                                        placeholder="Enter Username"
                                        value={userName}
                                        onChange={(e) =>
                                            setUserName(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="modal-inline">
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Enter Password"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="modal-inline">
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="settings-account-btn">
                            <button onClick={registerUser}>Create User</button>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

// ------------------------------------------------------------------------------------
export {
    Settings,
    General,
    PlansAndBilling,
    PasswordAndSecurity,
    QuickResponse,
    WhatsAppTemplate,
    PasscodeManagement,
    Chatbot,
    NotificationAndAccountSettings,
};
