import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { API } from "../api-service";
import { IoIosArrowBack } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { IoHomeSharp } from "react-icons/io5";
import Select from "react-select";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { UserContext } from "../user-context";

const StyledDateTimePicker = styled(DatePicker)`
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: none;

    /* Remove browser autocomplete styling */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        -webkit-text-fill-color: inherit !important;
        transition: background-color 5000s ease-in-out 0s;
    }

    &:focus {
        margin: 0;
        outline: none;
    }
`;

export const handleInboxSetting = async ({ phone, userToken, setEditContactName, setEditClientUserId, setCurrentParticipantPhoneNumber, setEditBuildings, setExistingContactData, setIsModalOpen, setIsEditModalOpen, setSelectedTags}) => {
    if (!userToken) return;

    try {
        // Fetch client data based on phone number
        const clientData = await API.getExistsUser({
            token: userToken,
            phone_number: phone.replace(/\s+/g, "").replace("+", ""),
        });

        if (!clientData) {
            toast.error("Contact not found");
            return;
        }

        const getUserName = (input) => {
            if (typeof input !== "string") return ""; // Ensure input is a string
        
            // Regular expression to capture everything after the last meaningful number/date/unit
            const regex = /(?:.*\b\d{1,2}\/\d{1,2}-\d{1,2}\/\d{1,2}\b|\S*\d+)?\s*(\S.*)/;
            const match = input.match(regex);
        
            return match && match[1].trim() ? match[1].trim() : "";
        };
        
        console.log(phone);
        setCurrentParticipantPhoneNumber([phone]);
        setEditClientUserId(clientData.id);
        setEditContactName(getUserName(clientData.name));

        const safeParseJSON = (str) => {
            if (!str) return [];
            try {
                const cleanStr = str.replace(/'/g, '"');
                return JSON.parse(cleanStr);
            } catch (e) {
                console.error("Error parsing JSON:", str, e);
                return [];
            }
        };

        const unit = safeParseJSON(clientData.unit_no)[0] || "";
        const checkInDate = safeParseJSON(clientData.checkin_datetime)[0] || null;
        const checkOutDate = safeParseJSON(clientData.checkout_datetime)[0] || null;
        
        // Fetch building data
        const data = await API.getChatFlowList(userToken);
        const filteredData = data.filter(
            (chatflow) =>
                chatflow.type === "massflow" && chatflow.error_status === true
        );

        // Create the formattedBuildings array
        const formattedBuildings = [];

        for (const chatflow of filteredData) {
            const unitNodes = await API.getNodes({
                token: userToken,
                id: chatflow.id,
                unit: true,
            });
            const unitNodeComplete = unitNodes.reduce((acc, node) => {
                const nodeTextArray = JSON.parse(node.text);
                return [...acc, ...nodeTextArray];
            }, []);

            if (unitNodeComplete.includes(unit)) {
                const formattedBuilding = {
                    visible: true,
                    unit: unit,
                    template: {
                        label: chatflow.chatflow_name,
                        value: chatflow.id,
                    },
                    checkIn: checkInDate ? new Date(checkInDate) : null,
                    checkOut: checkOutDate ? new Date(checkOutDate) : null,
                    sendCheckInAgain: false,
                    sendCheckOutAgain: false,
                    sendCheckIn: false,
                    sendCheckOut: false,
                };

                formattedBuildings.push(formattedBuilding);
            }
        }

        // if (clientData.labels && Array.isArray(clientData.labels)) {
        //     setSelectedTags(clientData.labels);
        //   } else if (clientData.label_ids) {
        //     try {
        //       const labelIds = typeof clientData.label_ids === 'string' 
        //         ? JSON.parse(clientData.label_ids.replace(/'/g, '"')) 
        //         : clientData.label_ids;
        //       setSelectedTags(Array.isArray(labelIds) ? labelIds : []);
        //     } catch (e) {
        //       console.error("Error parsing label IDs:", e);
        //       setSelectedTags([]);
        //     }
        //   } else {
        //     setSelectedTags([]);
        // }

        setEditBuildings(formattedBuildings);
        setExistingContactData(clientData);

        setIsModalOpen(false);
        setTimeout(() => {
            setIsEditModalOpen(true);
        }, 100);
    } catch (error) {
        console.error("Error in handleInboxSetting:", error);
        toast.error("Failed to load contact information");
    }
};

const EditModal = ({ isEditModalOpen, setIsEditModalOpen, editContactName, setEditContactName, editBuildings, massFlowOption, unitOption, handleEditTemplateChange, handleEditUnitChange, handleEditCheckInDateChange, handleEditSendCheckIn, handleEditCheckOutDateChange, handleEditSendCheckOut, handleEditSendCheckInAgain, handleEditSendCheckOutAgain, deleteEditBuilding, handleEditAddBuilding, editContact, isButtonDisabled, labelTag, handleTagClick, selectedTags, setNewColor, setNewLabel, newLabel, setShowLabelInput, showLabelInput, allowedColors, newColor, handleAddLabel,}) => {
    if (!isEditModalOpen) return null;

    return (
        <main className={`test-modal-main open`}>
            <div className="modal-container">
                <div className="modal">
                    <div className="test-modal-header">
                        <IoIosArrowBack style={{ fontSize: "1.5rem" }} className="test-modal-icon" onClick={() => setIsEditModalOpen(false)}/>
                        <h2>Edit contact</h2>
                    </div>
                    <div className="test-modal-contact">
                        <h3>Contact details</h3>
                        <div className="input-container">
                            <input type="text" id="myInput" value={editContactName} placeholder="Enter contact name" onChange={(e) => setEditContactName(e.target.value) }/>
                            <label htmlFor="myInput">Enter contact name</label>
                        </div>
                        <h3>Labels</h3>
                            <div className='contact-label-container'>
                            {labelTag?.map((tag) => (
                                    <div
                                        key={tag.id}
                                        className="label-tag-ibx"
                                        onClick={() => handleTagClick(tag)}
                                        style={{
                                            backgroundColor: selectedTags.includes(tag.id) ? tag.label_color : "#f0f0f0",
                                        }}
                                    >
                                        {tag.label_name}
                                    </div>
                                ))}
                                {showLabelInput ? (
                                    <div className="add-label-input">
                                        <div className='input-container' style={{margin: '0'}}>
                                            <input type="text" id='addlabel' value={newLabel} onChange={(e) => setNewLabel(e.target.value)} placeholder="Enter label name"/>
                                            <label htmlFor="addlabel">Enter label name</label>
                                        </div>
                                        <div className="color-picker">
                                            {allowedColors.map((color) => (
                                                <div key={color} className="color-option" style={{backgroundColor: color, border: newColor === color ? "2px solid black" : "none", width: "24px", height: "24px", display: "inline-block", cursor: "pointer", margin: "4px", borderRadius: "50%", }} onClick={() => setNewColor(color)}>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='label-button-container'>
                                            <button onClick={() => setShowLabelInput(false)} className='label-cancel-btn'>Cancel</button>
                                            <button onClick={handleAddLabel} className='label-add-btn'>Add</button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="add-label" onClick={() => setShowLabelInput(true)}>
                                        + Add label
                                    </div>
                                )}
                            </div>
                        {editBuildings?.map((building, index) => (
                            <div key={index} className={`building-div ${ building.visible ? "visible" : "" }`}>
                                <div className="building-header">
                                    <h3>
                                        {building.template?.label ? building.template.label : `Building ${index + 1}`}
                                        {building.unit ? building.unit : ""}
                                    </h3>
                                    <MdDelete className="delete-icon" onClick={() => deleteEditBuilding( index, building.id ) } style={{ fontSize: "1.5rem" }}/>
                                </div>
                                <div className="building-info">
                                    <Select className="modal-select" placeholder="Select building" options={massFlowOption} value={ building.template?.value ? { label: building.template.label, value: building.template.value, } : null } onChange={(selectedOption) => handleEditTemplateChange( index, selectedOption )}/>
                                    <Select className="modal-select" placeholder="Select unit" options={ unitOption[building.template?.label] } value={ building.unit ? { label: building.unit, value: building.unit, } : null } onChange={(selectedOption) => handleEditUnitChange( index, selectedOption )}/>
                                    <div className="test-modal-date">
                                        <div>
                                            <label htmlFor="checkin">Check In</label>
                                            <StyledDateTimePicker id="checkin" selected={building['checkIn'] ? new Date(building['checkIn']) : null}  onChange={(date) => handleEditCheckInDateChange( index, date ) } dateFormat="MMMM d, yyyy" placeholderText="Check in" autoComplete="off" customInput={<input autoComplete="off" />} />
                                            <div className="switch-div">
                                                <p>Resend check-in?</p>
                                                <label className="switch">
                                                    <input type="checkbox" id="toggleButton" value={ building.sendCheckInAgain } onChange={( selectedOption ) => handleEditSendCheckInAgain( index, selectedOption )}/>
                                                    <span className="slider-btn"></span>
                                                </label>
                                            </div>
                                            <div className="switch-div">
                                                <p>Send check-in now?</p>
                                                <label className="switch">
                                                    <input type="checkbox" id="toggleButton" value={ building.sendCheckIn } onChange={( selectedOption ) => handleEditSendCheckIn( index, selectedOption )}/>
                                                    <span className="slider-btn"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="checkout">Check Out</label>
                                            <StyledDateTimePicker id="checkout" selected={building['checkOut'] ? new Date(building['checkOut']) : null}  onChange={(date) => handleEditCheckOutDateChange( index, date )} dateFormat="MMMM d, yyyy" placeholderText="Check out" autoComplete="off" customInput={<input autoComplete="off" />}/>
                                            <div className="switch-div">
                                                <p>Resend check-out?</p>
                                                <label className="switch">
                                                    <input type="checkbox" id="toggleButton" value={ building.sendCheckInAgain } onChange={(selectedOption) => handleEditSendCheckOutAgain( index, selectedOption)}/>
                                                    <span className="slider-btn"></span>
                                                </label>
                                            </div>
                                            <div className="switch-div">
                                                <p>Send check-out now?</p>
                                                <label className="switch">
                                                    <input type="checkbox" id="toggleButton" value={ building.sendCheckIn } onChange={(selectedOption) => handleEditSendCheckOut( index, selectedOption)}/>
                                                    <span className="slider-btn"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="test-modal-add" onClick={handleEditAddBuilding}>
                            <IoHomeSharp style={{ fontSize: "1.5rem" }} />
                            <p>Add building</p>
                        </div>

                        <div className="test-modal-add-btn">
                            <button className="test-modal-btn" onClick={editContact} disabled={isButtonDisabled}>Confirm edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

const useAddContact = ({ userToken, contactName, phoneNumber, phoneError, buildings, waPhoneNumberId, userId, SystemUserToken, wabaId, waPhoneNumber, setIsButtonDisabled, isButtonDisabled, setEditClientUserId, setCurrentParticipantPhoneNumber, setEditContactName, setEditBuildings, setExistingContactData, setIsModalOpen, setIsEditModalOpen, selectedTags}) => {

    const addContactButton = async () => {

        console.time("addContactButton"); // Start timing

        // 1. Validation part
        if (!contactName || !phoneNumber) {
            toast.error("Please fill in all the fields");
            return;
        }
        if (phoneError) {
            toast.error("Invalid phone number");
            return;
        }
        if (buildings.length === 0) {
            toast.error("Please add at least one building");
            return;
        }
        
        // Prepare data to send to API while validating form
        let checkin = [];
        let checkout = [];
        let unit = [];
        let templateLabel = [];
        let templateValue = [];
        let checkInBoolean = [];
        let checkOutBoolean = [];

        for (const building of buildings) {
            if (!building.template || !building.unit || !building.checkIn || !building.checkOut) {
                toast.error("Please fill in all the fields");
                return;
            }

            // Check date validation
            const checkIn = new Date(building.checkIn);
            const checkOut = new Date(building.checkOut);
            if (checkIn >= checkOut) {
                toast.error("Check-out date must be after check-in date");
                return;
            }

            checkin.push(new Date(building.checkIn));
            checkout.push(new Date(building.checkOut));
            unit.push(building.unit);
            templateLabel.push(building.template.label);
            templateValue.push(building.template.value);
            checkInBoolean.push(building.sendCheckIn);
            checkOutBoolean.push(building.sendCheckOut);
        }

        try {
            if (!isButtonDisabled) {
                setIsButtonDisabled(true);
                const existingContact = await API.getExistsUser({ token: userToken, phone_number: phoneNumber.replace(/\s+/g, "").replace("+", ""),});

                // Open edit modal if contact already exists
                if (existingContact && existingContact.id) {
                    toast.error( "This contact already exists in the system. Opening the edit modal now." );
                    await handleInboxSetting({
                        phone: phoneNumber.replace(/\s+/g, "").replace("+", ""),
                        userToken,
                        setEditClientUserId,
                        setCurrentParticipantPhoneNumber,
                        setEditContactName,
                        setEditBuildings,
                        setExistingContactData,
                        setIsModalOpen,
                        setIsEditModalOpen,
                    });
                    setIsButtonDisabled(false);
                    return;
                }

                let formattedContactName = "";
                for (let i = 0; i < templateLabel.length; i++) {
                    if (i > 0) {
                        formattedContactName += ", ";
                    }
                    let checkInDate = checkin[i];
                    let checkOutDate = checkout[i];
                    let checkInDay = checkInDate?.getDate().toString().padStart(2, "0");
                    const checkInMonth = (checkInDate.getMonth() + 1).toString().padStart(2, "0");
                    const formattedCheckInDate = `${checkInDay}/${checkInMonth}`;
                    let checkOutDay = checkOutDate?.getDate().toString().padStart(2, "0");
                    const checkOutMonth = (checkOutDate.getMonth() + 1).toString().padStart(2, "0");
                    const formattedCheckOutDate = `${checkOutDay}/${checkOutMonth}`;

                    formattedContactName += `${templateLabel[i]} ${unit[i]} ${formattedCheckInDate}-${formattedCheckOutDate}`;
                }

                formattedContactName += ` ${contactName}`;

                try {
                    const response = await API.addNewContacts({
                        SystemUserToken: SystemUserToken,
                        token: userToken,
                        phone: phoneNumber.replace(/\s+/g, "").replace("+", ""),
                        waPhoneNumberId: Array.isArray(waPhoneNumberId) ? waPhoneNumberId[0] : waPhoneNumberId,
                        username: formattedContactName,
                        userId,
                        SystemUserToken,
                        checkin_datetime: checkin,
                        checkout_datetime: checkout,
                        checkin_list: checkInBoolean,
                        checkout_list: checkOutBoolean,
                        unit_no: unit,
                        chatflow_id: templateValue,
                        wabaId,
                        label_ids: selectedTags
                    });
                    console.log('API response:', response);
                    console.log('Selected tags', selectedTags);
                    if ( response === "User Successfully Added" || response === "Contact added") {
                        setIsButtonDisabled(false);
                        setIsModalOpen(false);
                        toast.success("Contact added successfully");
                        console.timeEnd('addContactButton')
                        let checkInSent = false;
                        let checkOutSent = false;

                        // Send message immediately if slide button is checked
                        // 1. Check in list
                        for ( let index = 0; index < checkInBoolean.length; index++) {
                            if (checkInBoolean[index]) {
                                checkInSent = true;
                                await API.massFlowAlgorithm({
                                    user_id: userId,
                                    index,
                                    chatflow_id: templateValue[index],
                                    unit: unit[index],
                                    waba_id: wabaId,
                                    phone_number_id: Array.isArray(waPhoneNumberId) ? waPhoneNumberId[0] : waPhoneNumberId,
                                    to_phone_number: phoneNumber.replace(/\s+/g, "").replace("+", ""),
                                    type: "checkInNode",
                                });
                            }
                        }

                        // 2. Check out list
                        for ( let index = 0; index < checkOutBoolean.length; index++) {
                            if (checkOutBoolean[index]) {
                                checkOutSent = true;
                                await API.massFlowAlgorithm({
                                    user_id: userId,
                                    index,
                                    chatflow_id: templateValue[index],
                                    unit: unit[index],
                                    waba_id: wabaId,
                                    phone_number_id: Array.isArray(waPhoneNumberId) ? waPhoneNumberId[0] : waPhoneNumberId,
                                    to_phone_number: phoneNumber.replace(/\s+/g, "").replace("+", ""),
                                    from_phone_number: waPhoneNumber,
                                    type: "checkOutNode",
                                });
                            }
                        }
                        setIsButtonDisabled(false);

                        if (checkInSent) {
                            toast.success("Check-in message sent successfully");
                        }
                        if (checkOutSent) {
                            toast.success("Check-out message sent successfully");
                        }
                        setIsButtonDisabled(false);
                        // window.location.reload();
                    } else {
                        toast.error(response || 'Failed to add contact');
                        setIsButtonDisabled(false);
                        setIsModalOpen(false);
                    }
                } catch (error) {
                    setIsButtonDisabled(false);
                    toast.error(error.message || "An error occurred");
                    console.timeEnd("addContactButton");
                }
            }
        } catch (error) {
            console.error(error);
            setIsButtonDisabled(false);
            toast.error("Failed to process contact");
            console.timeEnd("addContactButton"); // End timing
        }
    };

    return addContactButton;
};

const AddContactButton = ({ onClick, disabled, children }) => {
    return (
        <div className="test-modal-add-btn">
            <button className="test-modal-btn" onClick={onClick} disabled={disabled}> 
                {children || "Add contact"} 
            </button>
        </div>
    );
};

export { useAddContact, AddContactButton, EditModal };
