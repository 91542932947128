import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { UserContext } from "../user-context";
import { FaArrowLeft } from "react-icons/fa";
import Sidenav from "../components/Sidenav";
import { useAddContact, AddContactButton, EditModal } from '../components/AddContactButton';
import { AgGridReact } from 'ag-grid-react';
import {Helmet} from 'react-helmet';
import Select from 'react-select';
import { API } from "../api-service";
import HelpButton from '../components/HelpButton.js';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { MdDelete } from "react-icons/md";
import { IoHomeSharp } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { format, isToday, isBefore, isAfter, parseISO, isWithinInterval } from 'date-fns';
import { Search, Settings, Filter, Plus } from 'lucide-react';

import '../components/css/ContactModal.css';
import 'react-phone-input-2/lib/material.css';
import 'react-phone-input-2/lib/bootstrap.css';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import './css/Contacts.css';

import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";


ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
]);

const StyledDateTimePicker = styled(DatePicker)`
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: none;
    background-color: white;

    /* Remove browser autocomplete styling */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        -webkit-text-fill-color: inherit !important;
        transition: background-color 5000s ease-in-out 0s;
    }

    &:focus {
        margin: 0;
        outline: none;
    }
`;



function Contacts() {

    // Maintain state
    const { userToken, userId, wabaId, waPhoneNumber, SystemUserToken, subscriptionPlan, waPhoneNumberId } = useContext(UserContext);
    const [massFlowData, setMassFlowData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [massFlowOption, setMassFlowOption] = useState([]);
    const [unitOption, setUnitOption] = useState([]);
    const [contactName, setContactName] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [currentParticipantPhoneNumber, setCurrentParticipantPhoneNumber] = useState();
    const [existingContactData, setExistingContactData] = useState([]);

    // Edit modal state
    // Components for edit contact
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [unitList, setUnitList] = useState({});
    const [editBuildings, setEditBuildings] = useState([]);
    const [checkEditBuildings, setCheckEditBuildings] = useState([]);
    const [editClientUserId, setEditClientUserId] = useState('');
    const [editPhoneNumber, setEditPhoneNumber] = useState('');
    const [editContactName, setEditContactName] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [contactToDelete, setContactToDelete] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [labelTag, setLabelTag] = useState([]);
    const [showLabelInput, setShowLabelInput] = useState(false);
    const [newLabel, setNewLabel] = useState("");
    const [newColor, setNewColor] = useState("");
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [filterModal, setFilterModal] = useState(false);
    const [currentParticipantLabels, setCurrentParticipantLabels] = useState([]);
    const [overallTag, setOverallTag] = useState([]);
    
    // Select button state
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [buildings, setBuildings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const closeModal = () => {
        setIsModalOpen(false);
    };

    // TODO: Update this with alvin and add Addon amount from user
    const planContactLimit = {
        'Free': 10,
        'Small': 200,
        'Medium': 990,
        'Large': Infinity
    }

    // Delete contact button
    const deleteContactBtn = params => {
        const onClick = async () => {
            try {
                await API.removeChatRoom({ 'token': userToken, 'id': params.data.id });
                await API.deleteClientUser({ 'token': userToken, 'id': params.data.id });

                setRowData(currentRowData => {
                    // Correctly return the filtered array to update the state
                    return currentRowData.filter(object => object.id !== params.data.id);
                });
            } catch (error) {
                console.error(error);
            }
        };
        return <button className='contactDeleteBtn' onClick={onClick}>Delete</button>;
    };

    const handleDeleteContact = async () => {
        try {
          await API.removeChatRoom({ token: userToken, id: contactToDelete.id });
          await API.deleteClientUser({ token: userToken, id: contactToDelete.id });
      
          // Update your state to remove the deleted contact
          setRowData(currentRowData =>
            currentRowData.filter(contact => contact.id !== contactToDelete.id)
          );
          setShowDeleteConfirmation(false);
        } catch (error) {
          console.error('Error deleting contact:', error);
        }
      };
      
      const handleDeleteButton = (contact) => {
        setContactToDelete(contact);
        setShowDeleteConfirmation(true);
      };

      const handleEditContact = async (contact) => {
        // Regex function to extract username
        const getUserName = (input) => {
            if (typeof input !== "string") return ""; // Ensure input is a string
        
            // Regular expression to capture everything after the last meaningful number/date/unit
            const regex = /(?:.*\b\d{1,2}\/\d{1,2}-\d{1,2}\/\d{1,2}\b|\S*\d+)?\s*(\S.*)/;
            const match = input.match(regex);
        
            return match && match[1].trim() ? match[1].trim() : "";
        };
      
        try {
            setIsEditModalOpen(true);
            setEditClientUserId(contact.id);
            setEditPhoneNumber(contact.phone_number);
            setEditContactName(getUserName(contact.name));
        
            const chatflow_label_list = contact.building;
            const chatflow_value_list = contact.chatflow_id;
            const checkin_list = contact.checkin_datetime;
            const checkout_list = contact.checkout_datetime;
            const sendCheckIn_list = Array.isArray(contact.checkin_list) 
                ? contact.checkin_list 
                : JSON.parse(contact.checkin_list?.replace(/'/g, '"'));
            const sendCheckOut_list = Array.isArray(contact.checkout_list) 
                ? contact.checkout_list 
                : JSON.parse(contact.checkout_list?.replace(/'/g, '"'));
            const unit_list = JSON.parse(contact.unit_no?.replace(/'/g, '"'));
        
            let building_list = [];
            for (let i = 0; i < chatflow_label_list.length; i++) {
                building_list.push({
                'id': Date.now() + i,
                'visible': true,
                'checkIn': new Date(checkin_list[i]),
                'checkOut': new Date(checkout_list[i]),
                'unit': unit_list[i],
                'checkin_status': false,
                'checkout_status': false,
                'sendCheckIn': false,
                'sendCheckOut': false,
                'template': {
                    'label': chatflow_label_list[i], 
                    'value': chatflow_value_list[i] 
                }
                });
            }
            
            setCheckEditBuildings(building_list);
            setEditBuildings(building_list);
            } catch (error) {
                console.error('Error preparing edit data:', error);
            }
      };

    // // Set table definition
    const [rowData, setRowData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredContacts, setFilteredContacts] = useState([]);
    
    // Simplified search handler without toLowerCase
    const handleSearchInputChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        
        if (query.trim() === '') {
            setFilteredContacts(rowData);
        } else {
            const filtered = rowData?.filter(contact => {
                // Convert building array to string
                const buildingString = contact?.building?.join(', ') || '';
                // Clean unit_no string from array format
                const unitNo = contact?.unit_no?.replace(/[\[\]']/g, '') || '';
                
                return (
                    (contact?.name || '').toLowerCase().includes(query) ||
                    buildingString.toLowerCase().includes(query) ||
                    unitNo.toLowerCase().includes(query) ||
                    (contact?.phone_number || '').toLowerCase().includes(query)
                );
            });
            setFilteredContacts(filtered);
        }
    };

    const safeJSONParse = (data, fallback = []) => {
        try {
            return JSON.parse(data?.replace(/'/g, '"') || "[]");
        } catch {
            return fallback;
        }
    };
    
    // Update your useEffect to initialize filteredContacts when rowData changes
    useEffect(() => {
        setFilteredContacts(rowData);
    }, [rowData]);

    // Get ClientUser and MassFlow data when page loads
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await API.getClientUserList(userToken);
                const chatFlowList = await API.getChatFlowList(userToken);
                console.log('CHATFLOWLIST: ', chatFlowList);


                console.time("JSON PARSE CLIENT USER");
                const formattedData = await Promise.all(data?.map(async (contact) => {
                    let chatflowIds = [];
                    let checkinDates = [];
                    let checkoutDates = [];
                    let checkin_list = [];
                    let checkout_list = [];
                    try {
                        chatflowIds = JSON.parse(contact.chatflow_id || '[]');
                    } catch (error) {
                        console.error('Error parsing chatflow_id:', error);
                    }
                    try {
                        checkinDates = safeJSONParse(contact.checkin_datetime);
                    } catch (error) {
                        console.error('Error parsing checkin_datetime:', error);
                    }
                    try {
                        checkoutDates = safeJSONParse(contact.checkout_datetime);
                    } catch (error) {
                        console.error('Error parsing checkout_datetime:', error);
                    }
                    try {
                        checkin_list = safeJSONParse(contact.checkin_list);
                    } catch (error) {
                        console.error('Error parsing checkin_list', error);
                    }
                    try {
                        checkout_list = safeJSONParse(contact.checkout_list);
                    } catch (error) {
                        console.error('Error parsing checkout_list', error);
                    }

                    const buildingArray = chatflowIds.map((chatflow_id) => {
                        const chatFlow = chatFlowList.find(item => item.id === chatflow_id)
                        const chatFlowName = chatFlow ? chatFlow.chatflow_name : null;
                        return chatFlowName;
                    })
        
                    const formattedCheckIn = checkinDates.map(dateString => {
                        const date = new Date(dateString);
                        const year = date.getFullYear();
                        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                        const day = date.getDate().toString().padStart(2, '0');
                        return `${year}-${month}-${day}`;
                    });
        
                    const formattedCheckOut = checkoutDates.map(dateString => {
                        const date = new Date(dateString);
                        const year = date.getFullYear();
                        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                        const day = date.getDate().toString().padStart(2, '0');
                        return `${year}-${month}-${day}`;
                    });
                    return {
                        id: contact.id,
                        building: buildingArray,
                        name: contact.name,
                        phone_number: contact.phone_number,
                        unit_no: contact.unit_no,
                        checkin_status: contact.checkin_status,
                        checkout_status: contact.checkout_status,
                        checkin_list: checkin_list,
                        checkout_list: checkout_list,
                        checkin_datetime: formattedCheckIn,
                        checkout_datetime: formattedCheckOut,
                        chatflow_id: JSON.parse(contact.chatflow_id),
                    };
                }));
                console.timeEnd("JSON PARSE CLIENT USER");
                setRowData(formattedData);
                
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchBuildingAndUnit = async() => {
            try {
                const data= await API.getChatFlowList(userToken);
                // Massflow data
                let filteredData = []
                try {
                    filteredData = data?.filter(chatflow => chatflow.type === 'massflow' && chatflow.error_status===true).map(chatflow => ({value: chatflow.id, label: chatflow.chatflow_name}))
                    setMassFlowData(data?.filter(chatflow => chatflow.type === 'massflow'));
                    setMassFlowOption(filteredData);
                } catch(error) {
                    console.error(error);
                }

                // Unit data
                let mappedUnit = {};
                let oriUnitList = {};

                const fetchUnitNodes = async (chatflow) => {
                    try {
                        const unitNodes = await API.getNodes({ 'token': userToken, 'id': chatflow.value, 'unit': true });
                        
                        // Combine the text fields into a single array
                        const unitNodeComplete = unitNodes.reduce((acc, node) => {
                            const nodeTextArray = JSON.parse(node.text);
                            return [...acc, ...nodeTextArray];
                        }, []);
                        
                        oriUnitList[chatflow.label] = unitNodeComplete;
                        mappedUnit[chatflow.label] = unitNodeComplete.map(node => ({ value: node, label: node }));
                    } catch (error) {
                        console.error(error);
                        throw error;
                    }
                };

                await Promise.all(filteredData.map(chatflow => fetchUnitNodes(chatflow)));
                setUnitOption(mappedUnit);
                setUnitList(oriUnitList);
            } catch(error) {
                console.error(error);
                throw(error);
            }
        }

        fetchBuildingAndUnit();
        fetchData();
    }, [userToken]);

    const fetchLabels = async () => {
        try {
          const labels = await API.listLabel({ token: userToken });
          setLabelTag(labels);
        } catch (error) {
          console.error('Error fetching labels:', error);
        }
      };

    // Add contacts window
    const openModal = async(e) => {
        e.stopPropagation();
        // Check profile contact
        try {
            const response = await API.fetchProfile(userToken);
            const contactUsed = response.contact_used;
            if (contactUsed >= planContactLimit[subscriptionPlan]) {
                toast.error('You have reached the maximum number of contacts this month! Please consider add-ons or wait next month');
                return;
            }
        } catch(error) {
            console.error(error);
        }
        await fetchLabels();
        setIsModalOpen(true);
    };

    
    // Contact modal state handling -------------------------------------------------------------------
    const deleteBuilding = (index, id) => {
        setBuildings(prevBuildings => 
            prevBuildings.map(building => {
                console.log(building.id, id);
                return building.id === id ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleAddBuilding = () => {
        setBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false}]);
        setTimeout(() => {
            setBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true, sendCheckIn: false, sendCheckOut: false }
                )
            );
        }, 1);
    }

    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    const handleTemplateChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleUnitChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleCheckInDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleCheckOutDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const handleSendCheckIn = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckIn: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleSendCheckOut = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOut: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    // button function
    const addContactButton = useAddContact({ userToken, contactName, phoneNumber, phoneError, buildings, waPhoneNumberId, userId, SystemUserToken, wabaId, waPhoneNumber, setIsButtonDisabled, isButtonDisabled, setEditClientUserId, setCurrentParticipantPhoneNumber, setEditContactName, setEditBuildings, setExistingContactData, setIsModalOpen, setIsEditModalOpen, selectedTags, setSelectedTags });

    const handleTagClick = (tag) => {
        setSelectedTags((prevSelected) =>
            prevSelected.includes(tag.id)
                ? prevSelected.filter((tagId) => tagId !== tag.id)
                : [...prevSelected, tag.id]
        );
        console.log('label_ids being sent to API:', selectedTags);
    };

    const allowedColors = ["#FFD29D", "#FFB6A0", "#A3C4F3","#B5EAD7","#C3B1E1","#F7C6C7","#E0E0E0","#FBE192","#A2D2FF","#D4A5A5"];

    const handleAddLabel = async() => {
        if (newLabel && newColor) {

            const response = await API.createLabel({'label_name': newLabel, 'label_color': newColor, 'token': userToken});
            console.log(response);
            setLabelTag([...labelTag, { label: newLabel, color: newColor }]);
            setNewLabel("");
            setNewColor("");
            setShowLabelInput(false);
        }
    };
    
    // -----------------------------------------------------------------------------------------------

    // Function for edit contact state handling ---------------------------------
    const deleteEditBuilding = (index, id) => {
        setEditBuildings(prevBuildings => 
            prevBuildings.map(building => {
                return building.id === id + index ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setEditBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleEditAddBuilding = () => {
        setEditBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false}]);
        setTimeout(() => {
            setEditBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true, sendCheckIn: false, sendCheckOut: false,}
                )
            );
        }, 1);
    }

    const handleEditTemplateChange = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleEditUnitChange = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleEditCheckInDateChange = (index, date) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleEditCheckOutDateChange = (index, date) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const handleEditSendCheckIn = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckIn: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleEditSendCheckOut = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOut: selectedOption.target.checked
            };
            return updatedBuildings;
        }); 
    }


    const editContact = async() => {

        // Validations
        if (editBuildings.length === 0) {
            toast.error('Please add at least one building');
            return;
        }
        

        // Prepare data to send to API while validating form
        let checkin = [];
        let checkout = [];
        let unit = [];
        let templateLabel = [];
        let templateValue = [];
        let checkInBoolean = [];
        let checkOutBoolean = [];
        let checkInList = [];
        let checkOutList = [];
        let status = true;
        editBuildings.map(building => {

            // Validations
            if (!building.template || !building.unit || !building.checkIn || !building.checkOut) {
                toast.error('Please fill in all the fields');
                status = false;
                return;
            }
            
            if (!unitList[building.template.label].includes(building.unit)) {
                status = false;
                toast.error(`Please update unit for ${building.template.label}`);
                return;
            }

            // Check date validation
            const checkIn = new Date(building.checkIn);
            const checkOut = new Date(building.checkOut);
            if (checkIn >= checkOut) {
                status = false;
                toast.error('Check-out date must be after check-in date');
                return;
            }

            // If data no problem, append to list for API data preparation
            checkin.push(checkIn);
            checkout.push(checkOut);
            unit.push(building.unit);
            templateLabel.push(building.template.label);
            templateValue.push(building.template.value);
            checkInBoolean.push(building.sendCheckIn);
            checkOutBoolean.push(building.sendCheckOut);
        });

        // If everything goes well and pass the validation, then only edit contact
        if (!status) {
            return;
        }
        // Set contact Name
        // Initialize the formatted contact name
        let formattedContactName = "";
        // Iterate over the arrays and format the contact name
        for (let i = 0; i < templateLabel.length; i++) {
            if (i > 0) {
                formattedContactName += ", ";
            }
                // Reformat date and add user into contact
            let checkInDate = checkin[i];
            let checkOutDate = checkout[i];
            let checkInDay = checkInDate.getDate().toString().padStart(2, '0');
            const checkInMonth = (checkInDate.getMonth() + 1).toString().padStart(2, '0'); 
            const formattedCheckInDate = `${checkInDay}/${checkInMonth}`;
            let checkOutDay = checkOutDate.getDate().toString().padStart(2, '0');
            const checkOutMonth = (checkOutDate.getMonth() + 1).toString().padStart(2, '0'); 
            const formattedCheckOutDate = `${checkOutDay}/${checkOutMonth}`;

            formattedContactName += `${templateLabel[i]} ${unit[i]} ${formattedCheckInDate}-${formattedCheckOutDate}`;
        }

        // Append the contact name
        formattedContactName += ` ${editContactName}`;

        // Call API to edit contact
        try {
            const response = await API.editClientUser({
                'token': userToken,
                'id': editClientUserId,
                'name': formattedContactName,
                'unit_no': unit,
                'checkin_datetime': checkin,
                'checkout_datetime': checkout,
                'checkin_status': false,
                'checkout_status': false,
                'checkin_list': checkInBoolean,
                'checkout_list': checkOutBoolean,
                'chatflow_id': templateValue,
            });

            // Update row data with the new data
            let chatflowIds = [];
            let checkinDates = [];
            let checkoutDates = [];
            try {
                chatflowIds = JSON.parse(response.chatflow_id || '[]');
            } catch (error) {
                console.error('Error parsing chatflow_id:', error);
            }
            try {
                checkinDates = JSON.parse(response.checkin_datetime.replace(/'/g, '"') || '[]');
            } catch (error) {
                console.error('Error parsing checkin_datetime:', error);
            }
            try {
                checkoutDates = JSON.parse(response.checkout_datetime.replace(/'/g, '"') || '[]');
            } catch (error) {
                console.error('Error parsing checkout_datetime:', error);
            }

            const buildingArray = await Promise.all(
                chatflowIds.map(async (chatflow_id) => {
                    const chatflow = await API.getChatFlow({ 'token': userToken, 'id': chatflow_id });
                    return chatflow.chatflow_name;
                })
            );

            const formattedCheckIn = checkinDates.map(dateString => {
                const date = new Date(dateString);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            });

            const formattedCheckOut = checkoutDates.map(dateString => {
                const date = new Date(dateString);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            });

            const data = {
                id: response.id,
                building: buildingArray,
                name: response.name,
                phone_number: response.phone_number,
                unit_no: response.unit_no,
                checkin_datetime: formattedCheckIn,
                checkout_datetime: formattedCheckOut,
                checkin_list: response.checkin_list,
                checkout_list: response.checkout_list,
                checkin_status: response.checkin_status,
                checkout_status: response.checkout_status,
                chatflow_id: JSON.parse(response.chatflow_id),
            };
            setRowData(currentRowData => {
                return currentRowData.map(object => 
                    object.id === response.id ? { ...object, ...data } : object
                );
            });

            let checkInSent = false;
            let checkOutSent = false;
            // Send message immediately if slide button is checked
            // 1. Check in list
            checkInBoolean.map(async(checkIn, index) => {
                if (checkIn) {
                    checkInSent = true;
                    // Send checkin massflow message;
                    const resp = await API.massFlowAlgorithm({
                        user_id: userId,
                        index,
                        chatflow_id: templateValue[index],
                        unit: unit[index],
                        waba_id: wabaId,
                        phone_number_id: Array.isArray(waPhoneNumberId) ? waPhoneNumberId[0] : waPhoneNumberId,
                        to_phone_number: editPhoneNumber.replace(/[\s-]+/g, "").replace("+", ""),
                        type: "checkInNode",
                    })
                }
            })

            // 2. Check out list
            checkOutBoolean.map(async(checkOut, index) => {
                if (checkOut) {
                    checkOutSent = true;
                    // Send checkout massflow message
                    const resp = await API.massFlowAlgorithm({
                        user_id: userId,
                        index,
                        chatflow_id: templateValue[index],
                        unit: unit[index],
                        waba_id: wabaId,
                        phone_number_id: Array.isArray(waPhoneNumberId) ? waPhoneNumberId[0] : waPhoneNumberId,
                        to_phone_number: editPhoneNumber.replace(/[\s-]+/g, "").replace("+", ""),
                        type: "checkOutNode",
                    })
                }
            })
            
            setIsEditModalOpen(false);
            toast.success('Contact updated successfuly');
            closeModal();
        } catch (error) {
            console.error(error);
            toast.error('Something wrong happened')
        }
    }
    // --------------------------------------------------------------------------

    const getStatus = (checkinDate, checkoutDate) => {
        if (!checkinDate || !checkoutDate) return {
            text: "No Dates",
            colors: {
                background: '#f0f0f0',
                text: '#666666'
            }
        };
        
        const now = new Date();
        const checkin = parseISO(checkinDate);
        const checkout = parseISO(checkoutDate);
        
        if (isBefore(now, checkin)) {
            return {
                text: "Not Check In",
                colors: {
                    background: '#e8f4ff',
                    text: '#2b6cb0'
                }
            };
        }
        
        if (isToday(checkin)) {
            return {
                text: "Check In Today",
                colors: {
                    background: '#e6ffee',
                    text: '#0e9f6e'
                }
            };
        }
        
        if (isWithinInterval(now, { start: checkin, end: checkout })) {
            return {
                text: "Checked In",
                colors: {
                    background: '#e6ffe6',
                    text: '#059669'
                }
            };
        }
        
        if (isToday(checkout)) {
            return {
                text: "Check Out Today",
                colors: {
                    background: '#fff3e6',
                    text: '#c05621'
                }
            };
        }
        
        if (isAfter(now, checkout)) {
            return {
                text: "Checked Out",
                colors: {
                    background: '#f7e8ff',
                    text: '#de3aed'
                }
            };
        }
        
        return {
            text: "Unknown",
            colors: {
                background: '#f0f0f0',
                text: '#666666'
            }
        };
    };
      

    // Sliding button logic -----------------------------------------------------

    // --------------------------------------------------------------------------

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Contacts Page</title>
            </Helmet>
            <Sidenav />
            <div className="contacts-page-wrapper">
                {/* Header Section */}
                <div className="contacts-header-container">
                    <h1 className="contacts-title">Contacts</h1>
                    <div className="contacts-header-buttons">
                        <button onClick={openModal} className="new-contact-button">
                            <Plus className="button-icon" />
                            New Contact
                        </button>
                    </div>
                </div>

                {/* Search and Filter Bar */}
                <div className="search-filter-container">
                    <div className="input-container">
                        <input type="text" id="searchInput" placeholder="Search Contacts" value={searchQuery} onChange={handleSearchInputChange} disabled={isLoading}/>
                        <label for="searchInput">Search Contacts</label>
                    </div>
                </div>

                {/* Contacts Table */}
                <div className="contacts-table-container">
                    {isLoading ? (
                        <div className="loading-spinner-container">
                            <div className="loading-spinner"></div>
                        </div>
                    ) : (
                        <table className="contacts-table">
                            <thead>
                                <tr>
                                    <th className="table-header">Name</th>
                                    <th className="table-header">Building</th>
                                    <th className="table-header">Unit</th>
                                    <th className="table-header">Phone Number</th>
                                    <th className="table-header">Check In</th>
                                    <th className="table-header">Check Out</th>
                                    <th className="table-header">Status</th>
                                    <th className="table-header">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredContacts?.map((contact, index) => {
                                const status = getStatus(
                                    contact.checkin_datetime?.[0],
                                    contact.checkout_datetime?.[0]
                                );

                                return (
                                    <tr key={index} className="table-row">
                                    <td className="table-cell">
                                        <div className="contact-name-cell">
                                        <div className="contact-avatar">
                                            {contact.name?.split(' ').pop().charAt(0)}
                                        </div>
                                        <span className="contact-name">{contact.name?.split(' ').pop()}</span>
                                        </div>
                                    </td>
                                    <td className="table-cell">
                                        {contact.building?.map((building, index) => (
                                        <div key={index} className="py-1">{building}</div>
                                        ))}
                                    </td>
                                    <td className="table-cell">
                                        {contact.unit_no?.split(',').map((unit, index) => {
                                        const cleanUnit = unit.replace(/[\[\]'"]/g, '').trim();
                                        return (
                                            <div key={index} className="py-1">
                                            {cleanUnit}
                                            </div>
                                        );
                                        })}
                                    </td>
                                    <td className="table-cell">
                                        <div className="contact-tag-container">
                                        <div className="contact-tag">
                                            {contact.phone_number}
                                        </div>
                                        </div>
                                    </td>
                                    <td className="table-cell">
                                        {contact.checkin_datetime?.map((date, i) => (
                                        <div key={i}>{date}</div>
                                        ))}
                                    </td>
                                    <td className="table-cell">
                                        {contact.checkout_datetime?.map((date, i) => (
                                        <div key={i}>{date}</div>
                                        ))}
                                    </td>
                                    <td className="table-cell">
                                    <span className="status-tag-contact" style={{ backgroundColor: status.colors.background, color: status.colors.text }}>
                                        {status.text}
                                    </span>
                                </td>
                                    <td className="table-cell">
                                        <div className="contact-action-buttons">
                                        <button className="contact-edit-button" onClick={() => handleEditContact(contact)}>
                                            Edit
                                        </button>
                                        <button className="contact-delete-button" onClick={() => handleDeleteButton(contact)}>
                                            Delete
                                        </button>
                                        </div>
                                    </td>
                                    </tr>
                                );
                                })}
                            </tbody>
                            </table>
                    )}
                </div>
                <ToastContainer style={{zIndex: '100000001'}}position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            </div>
            {isModalOpen && (
                <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div className='test-modal-header'>
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={()=>setIsModalOpen(false)}/>
                                <h2>Create new contact</h2>
                            </div>
                            <div className='test-modal-contact'>
                                <h3>Contact details</h3>
                                <div className="input-container">
                                    <input type="text" id="myInput" value={contactName} placeholder="Enter contact name" onChange={(e) => setContactName(e.target.value)}/>
                                    <label htmlFor="myInput">Enter contact name</label>
                                </div>
                                <div className="input-container">
                                    <input type="tel" id="phoneInput" placeholder="Enter phone number" value={phoneNumber} onChange={handlePhoneChange}/>
                                    <label htmlFor="phoneInput">Enter phone number</label>
                                    {phoneError && <div className="error-message">{phoneError}</div>}
                                </div>
                                <h3>Labels</h3>
                                <div className='contact-label-container'>
                                {labelTag?.map((tag) => (
                                        <div
                                            key={tag.id}
                                            className="label-tag-ibx"
                                            onClick={() => handleTagClick(tag)}
                                            style={{
                                                backgroundColor: selectedTags.includes(tag.id) ? tag.label_color : "#f0f0f0",
                                            }}
                                        >
                                            {tag.label_name}
                                        </div>
                                    ))}
                                    {showLabelInput ? (
                                        <div className="add-label-input">
                                            <div className='input-container' style={{margin: '0'}}>
                                                <input type="text" id='addlabel' value={newLabel} onChange={(e) => setNewLabel(e.target.value)} placeholder="Enter label name"/>
                                                <label htmlFor="addlabel">Enter label name</label>
                                            </div>
                                            <div className="color-picker">
                                                {allowedColors.map((color) => (
                                                    <div key={color} className="color-option" style={{backgroundColor: color, border: newColor === color ? "2px solid black" : "none", width: "24px", height: "24px", display: "inline-block", cursor: "pointer", margin: "4px", borderRadius: "50%", }} onClick={() => setNewColor(color)}>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='label-button-container'>
                                                <button onClick={() => setShowLabelInput(false)} className='label-cancel-btn'>Cancel</button>
                                                <button onClick={handleAddLabel} className='label-add-btn'>Add</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="add-label" onClick={() => setShowLabelInput(true)}>
                                            + Add label
                                        </div>
                                    )}
                                </div>

                                {buildings?.map((building, index) => (
                                    <div key={index}className={`building-div ${building.visible ? 'visible' : ''}`}>
                                        <div className='building-header'>
                                            <h3>{buildings[index]['template']?.label ? buildings[index]['template']['label'] : `Building ${index + 1}`} {buildings[index]['unit'] ? buildings[index]['unit'] : ''}</h3>
                                            <MdDelete className='delete-icon' onClick={() => deleteBuilding(index, building.id)} style={{ fontSize: '1.5rem' }} />
                                        </div>
                                        <div className='building-info'>
                                            <Select className='modal-select' placeholder={'Select building'} options={massFlowOption} value={buildings[index]['template']?.value ? {'label': buildings[index]['template']?.label, 'value': buildings[index]['template']?.value} : null} onChange={(selectedOption) => handleTemplateChange(index, selectedOption)} />
                                            <Select className='modal-select' placeholder={'Select unit'} options={unitOption[buildings[index]['template']?.label]} value={buildings[index]['unit'] ? {'label': buildings[index]['unit'], 'value': buildings[index]['unit']} : null} onChange={(selectedOption) => handleUnitChange(index, selectedOption)} />
                                            <div className='test-modal-date'>
                                                <div>
                                                    <label htmlFor='checkin'>Check In</label>
                                                    <StyledDateTimePicker id='checkin' selected={buildings[index]['checkIn']} onChange={(date) => handleCheckInDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check in" autoComplete="off" customInput={<input autoComplete="off" />}/>
                                                    <div className='switch-div'>
                                                        <label className="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckIn']} onChange={(selectedOption) => handleSendCheckIn(index, selectedOption)}/>
                                                            <span className="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-in details now</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor='checkout'>Check Out</label>
                                                    <StyledDateTimePicker id='checkout' selected={buildings[index]['checkOut']} onChange={(date) => handleCheckOutDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check out" autoComplete="off" customInput={<input autoComplete="off" />}/>
                                                    <div className='switch-div'>
                                                        <label className="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckOut']} onChange={(selectedOption) => handleSendCheckOut(index, selectedOption)}/>
                                                            <span className="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-out details now</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                ))}
        
                                <div className={'test-modal-add'} onClick={handleAddBuilding} style={{marginTop: '1.5rem'}}>
                                    <IoHomeSharp style={{fontSize:'1.5rem'}}/>
                                    <p>Add building</p>
                                </div>
        
                                <AddContactButton onClick={addContactButton} disabled={isButtonDisabled}>
                                    Add contact
                                </AddContactButton>
                            </div>
                        </div>
                    </div>
                </main>
            )}

            {showDeleteConfirmation && (
                <div className="delete-confirmation-modal">
                    <div className="delete-modal">
                    <h3>Are you sure you want to delete this contact?</h3>
                    <div className="delete-modal-buttons">
                        <button class="delete-yes" onClick={handleDeleteContact}>Yes</button>
                        <button class="delete-no" onClick={() => setShowDeleteConfirmation(false)}>No</button>
                    </div>
                    </div>
                </div>
            )}

            <EditModal isEditModalOpen={isEditModalOpen} setIsEditModalOpen={setIsEditModalOpen} editContactName={editContactName} setEditContactName={setEditContactName} editBuildings={editBuildings} massFlowOption={massFlowOption} unitOption={unitOption} handleEditTemplateChange={handleEditTemplateChange} handleEditUnitChange={handleEditUnitChange} handleEditCheckInDateChange={handleEditCheckInDateChange} handleEditSendCheckIn={handleEditSendCheckIn} handleEditCheckOutDateChange={handleEditCheckOutDateChange} handleEditSendCheckOut={handleEditSendCheckOut} deleteEditBuilding={deleteEditBuilding} handleEditAddBuilding={handleEditAddBuilding} editContact={editContact} isButtonDisabled={isButtonDisabled} />

            <HelpButton />
        </React.Fragment>
    )
}

export default Contacts;
