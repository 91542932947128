import Sidenav2 from "../components/Sidenav.js";
import React, { useEffect, useState, useContext } from "react";
import "./css/Broadcast.css";
import { Helmet } from "react-helmet";
import { UserContext } from "../user-context.js";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { API } from "../api-service.js";
import { FiFilter } from "react-icons/fi";

function Broadcast() {
    const [activeView, setActiveView] = useState("settings");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [isLoading, setIsLoading] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [massFlowOption, setMassFlowOption] = useState([]);
    const [massFlow, setMassFlow] = useState('');
    const [checkInOut, setCheckInOut] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const {
        userToken,
        profileId,
        userId,
        wabaId,
        SystemUserToken,
        waPhoneNumberId,
    } = useContext(UserContext);

    const [whatsappImageUrl, setWhatsappImageUrl] = useState(null);

    // Filter states
    const [selectedBuilding, setSelectedBuilding] = useState("");
    const [selectedUnit, setSelectedUnit] = useState("");
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [buildingUnitMap, setBuildingUnitMap] = useState({});
    const [dateFilter, setDateFilter] = useState({
        checkInStart: "",
        checkInEnd: "",
        checkOutStart: "",
        checkOutEnd: "",
    });

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        fetchTemplates();
        fetchContacts();
    }, [userToken]);

    useEffect(() => {
        const buildingMap = {};
        const buildings = new Set();

        contacts.forEach((contact) => {
            if (contact.building && Array.isArray(contact.building)) {
                contact.building.forEach((building) => {
                    if (building) {
                        buildings.add(building);
                        if (!buildingMap[building]) {
                            buildingMap[building] = new Set();
                        }
                        if (contact.unit_no) {
                            buildingMap[building].add(contact.unit_no);
                        }
                    }
                });
            }
        });

        setBuildingOptions([...buildings].sort());
        setBuildingUnitMap(buildingMap);
    }, [contacts]);

    useEffect(() => {
        if (selectedBuilding) {
            const units = buildingUnitMap[selectedBuilding] || new Set();
            setUnitOptions([...units].sort());
        } else {
            setUnitOptions([]);
            setSelectedUnit("");
        }
    }, [selectedBuilding, buildingUnitMap]);

    useEffect(() => {
        let filtered = [...contacts];

        if (selectedBuilding) {
            filtered = filtered.filter((contact) => {
                if (!contact.building || !Array.isArray(contact.building))
                    return false;
                const hasBuilding = contact.building.includes(selectedBuilding);
                if (selectedUnit) {
                    return hasBuilding && contact.unit_no === selectedUnit;
                }
                return hasBuilding;
            });
        }

        if (dateFilter.checkInStart) {
            filtered = filtered.filter((contact) => {
                return contact.checkin_datetime.includes(
                    dateFilter.checkInStart
                );
            });
        }

        if (dateFilter.checkOutEnd) {
            filtered = filtered.filter((contact) => {
                return contact.checkout_datetime.includes(
                    dateFilter.checkOutEnd
                );
            });
        }

        setFilteredContacts(filtered);
    }, [contacts, selectedBuilding, selectedUnit, dateFilter]);

    const safeJSONParse = (data, fallback = []) => {
        try {
            return JSON.parse(data?.replace(/'/g, '"') || "[]");
        } catch {
            return fallback;
        }
    };

    const handleDateFilterChange = (e) => {
        const { name, value } = e.target;
        setDateFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleTemplateChange = (evt) => {
        const selectedTemp = templateList.find(
            (t) => t.name === evt.target.value
        );
        setSelectedTemplate(selectedTemp);
        setWhatsappImageUrl(null);
    };

    const handleBroadcast = async () => {
        if (!selectedTemplate && !massFlow) {
            toast.error("Please select a template or flow");
            return;
        }
        if (selectedContacts.length === 0) {
            toast.error("Please select at least one contact");
            return;
        }

        setIsSending(true);
        let successCount = 0;
        let failCount = 0;

        try {
            for (const contact of selectedContacts) {
                const templateMessage = {
                    wabaId: wabaId,
                    waPhoneNumberId: Array.isArray(waPhoneNumberId) ? waPhoneNumberId[0] : waPhoneNumberId,
                    SystemUserToken: SystemUserToken,
                    toWaNumber: contact.phone_number.replace(/\s+/g, "").replace("+", ""),
                    templateName: selectedTemplate.name,
                    imageUrl: whatsappImageUrl || null,
                    chatRoomId: null,
                };

                const massFlowData = {
                    'user_id': userId,
                    'chatflow_id': massFlow,
                    'index': 0,
                    'unit': safeJSONParse(contact.unit_no)[0],
                    'waba_id': wabaId,
                    'phone_number_id': Array.isArray(waPhoneNumberId) ? waPhoneNumberId[0] : waPhoneNumberId,
                    'to_phone_number': contact.phone_number.replace(/\s+/g, "").replace("+", ""),
                    'type': checkInOut, 
                }
                try {
                    if (selectedTemplate) {
                        const response = await fetch(
                            "https://streamhost-django-80bc172b3b26.herokuapp.com/api/send-whatsapp-template/",
                            {
                                method: "POST",
                                headers: {
                                    Authorization: "Token " + userToken,
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(templateMessage),
                            }
                        );
                        const data = await response.json();
                        if (response.ok) {
                            successCount++;
                        } else {
                            failCount++;
                        }
                    }

                    if (massFlow) {
                        const response = await API.massFlowAlgorithm(massFlowData);
                        if (response.ok) {
                            successCount++;
                        } else {
                            failCount++;
                        }
                    }

                } catch (error) {
                    failCount++;
                }
            }

            if (successCount > 0) {
                toast.success(`Successfully sent to ${successCount} contacts`);
            }
            if (failCount > 0) {
                toast.error(`Failed to send to ${failCount} contacts`);
            }

            setSelectedTemplate("");
            setWhatsappImageUrl(null);
            setSelectedContacts([]);
        } catch (error) {
            console.error(error);
            toast.error("Broadcast failed");
        } finally {
            setIsSending(false);
        }
    };

    const fetchTemplates = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(
                "https://graph.facebook.com/v20.0/" +
                    wabaId +
                    "/message_templates?fields=name,components,status",
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + SystemUserToken,
                    },
                }
            );
            const data = await response.json();
            setTemplateList(
                data.data.filter((template) => template.status === "APPROVED")
            );
        } catch (error) {
            toast.error("Failed to fetch templates");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchContacts = async () => {
        try {
            const data = await API.getClientUserList(userToken);
            const chatFlowList = await API.getChatFlowList(userToken);
            setMassFlowOption(chatFlowList);

            const formattedData = await Promise.all(
                data?.map(async (contact) => {
                    let chatflowIds = [];
                    let checkinDates = [];
                    let checkoutDates = [];
                    let checkin_list = [];
                    let checkout_list = [];
                    try {
                        chatflowIds = JSON.parse(contact.chatflow_id || "[]");
                    } catch (error) {
                        console.error("Error parsing chatflow_id:", error);
                    }
                    try {
                        checkinDates = safeJSONParse(contact.checkin_datetime);
                    } catch (error) {
                        console.error("Error parsing checkin_datetime:", error);
                    }
                    try {
                        checkoutDates = safeJSONParse(
                            contact.checkout_datetime
                        );
                    } catch (error) {
                        console.error(
                            "Error parsing checkout_datetime:",
                            error
                        );
                    }
                    try {
                        checkin_list = safeJSONParse(contact.checkin_list);
                    } catch (error) {
                        console.error("Error parsing checkin_list", error);
                    }
                    try {
                        checkout_list = safeJSONParse(contact.checkout_list);
                    } catch (error) {
                        console.error("Error parsing checkout_list", error);
                    }

                    const buildingArray = chatflowIds.map((chatflow_id) => {
                        const chatFlow = chatFlowList.find(
                            (item) => item.id === chatflow_id
                        );
                        const chatFlowName = chatFlow
                            ? chatFlow.chatflow_name
                            : null;
                        return chatFlowName;
                    });

                    const formattedCheckIn = checkinDates.map((dateString) => {
                        const date = new Date(dateString);
                        const year = date.getFullYear();
                        const month = (date.getMonth() + 1)
                            .toString()
                            .padStart(2, "0"); // Months are zero-based
                        const day = date.getDate().toString().padStart(2, "0");
                        return `${year}-${month}-${day}`;
                    });

                    const formattedCheckOut = checkoutDates.map(
                        (dateString) => {
                            const date = new Date(dateString);
                            const year = date.getFullYear();
                            const month = (date.getMonth() + 1)
                                .toString()
                                .padStart(2, "0"); // Months are zero-based
                            const day = date
                                .getDate()
                                .toString()
                                .padStart(2, "0");
                            return `${year}-${month}-${day}`;
                        }
                    );
                    return {
                        id: contact.id,
                        building: buildingArray,
                        name: contact.name,
                        phone_number: contact.phone_number,
                        unit_no: contact.unit_no,
                        checkin_status: contact.checkin_status,
                        checkout_status: contact.checkout_status,
                        checkin_list: checkin_list,
                        checkout_list: checkout_list,
                        checkin_datetime: formattedCheckIn,
                        checkout_datetime: formattedCheckOut,
                        chatflow_id: JSON.parse(contact.chatflow_id),
                    };
                })
            );
            console.timeEnd("JSON PARSE CLIENT USER");
            setContacts(formattedData);
            setFilteredContacts(formattedData);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCancelFilter = () => {
        setSelectedBuilding("");
        setSelectedUnit("");
        setDateFilter({
            checkInStart: "",
            checkInEnd: "",
            checkOutStart: "",
            checkOutEnd: "",
        });
    };

    const handleSelectAll = () => {
        setSelectedContacts([...filteredContacts]);
    };

    const handleCancelAll = () => {
        setSelectedContacts([]);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Broadcast</title>
            </Helmet>
            <Sidenav2 />
            <div className="broadcast-main">
                <h1>Broadcast Messages</h1>
                <div className="broadcast-container">
                    <div className="broadcast-section">
                        <div style={{'display': massFlow ? 'none': 'block', marginBottom: '1.5rem'}}>
                            <h2>Select Template</h2>
                            <select
                                value={selectedTemplate?.name || ""}
                                onChange={handleTemplateChange}
                                className="template-select"
                            >
                                <option value="">Select a template</option>
                                {templateList.map((template) => (
                                    <option
                                        key={template.name}
                                        value={template.name}
                                    >
                                        {template.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        
                        <div style={{'display': selectedTemplate ? 'none': 'block', marginBottom: '1.5rem'}}>
                            <h2>Select Massflow</h2>
                            <select
                                value={massFlow}
                                onChange={(e) => setMassFlow(e.target.value)}
                                className="template-select"
                            >
                                <option value="">Select a flow</option>
                                {massFlowOption.map((chatflow) => (
                                    <option
                                        key={chatflow.id}
                                        value={chatflow.id}
                                    >
                                        {chatflow.chatflow_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div style={{'display': massFlow ? 'block': 'none'}}>
                            <h2>Check-in / out</h2>
                            <select
                                value={checkInOut}
                                onChange={(e) => setCheckInOut(e.target.value)}
                                className="template-select"
                            >
                                <option value="">Select send check-in or check-out instruction</option>
                                    <option key={"checkin"} value={"checkInNode"}>
                                        Check-in
                                    </option>
                                    <option key={"checkout"} value={"checkOutNode"}>
                                        Check-out
                                    </option>
                            </select>
                        </div>
                    </div>

                    <div className="broadcast-section">
                        <h2>Select Contacts</h2>
                        <div className="filter-section">
                            <div className="filter-row">
                                <div className="filter-column">
                                    <label>Building</label>
                                    <select
                                        value={selectedBuilding}
                                        onChange={(e) => {
                                            setSelectedBuilding(e.target.value);
                                            setSelectedUnit("");
                                        }}
                                        className="filter-select"
                                    >
                                        <option value="">All Buildings</option>
                                        {buildingOptions.map((building) => (
                                            <option
                                                key={building}
                                                value={building}
                                            >
                                                {building}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="filter-column">
                                    <label>Unit</label>
                                    <select
                                        value={selectedUnit}
                                        onChange={(e) =>
                                            setSelectedUnit(e.target.value)
                                        }
                                        className="filter-select"
                                        disabled={!selectedBuilding}
                                    >
                                        <option value="">All Units</option>
                                        {unitOptions.map((unit) => (
                                            <option key={unit} value={unit}>
                                                {unit}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="filter-column">
                                    <label>Check-in Period</label>
                                    <input
                                        type="date"
                                        name="checkInStart"
                                        value={dateFilter.checkInStart}
                                        onChange={handleDateFilterChange}
                                        className="date-input"
                                        placeholder="dd/mm/yyyy"
                                    />
                                </div>

                                <div className="filter-column">
                                    <label>Check-out Period</label>
                                    <input
                                        type="date"
                                        name="checkOutEnd"
                                        value={dateFilter.checkOutEnd}
                                        onChange={handleDateFilterChange}
                                        className="date-input"
                                        placeholder="dd/mm/yyyy"
                                    />
                                </div>

                                <div className="filter-column filter-button-column">
                                    <button
                                        onClick={handleCancelFilter}
                                        className="filter-button apply-filter"
                                    >
                                        Cancel Filter
                                    </button>
                                </div>
                            </div>

                            <div className="selection-buttons">
                                <button
                                    onClick={handleSelectAll}
                                    className="select-button"
                                >
                                    Select All
                                </button>
                                <button
                                    onClick={handleCancelAll}
                                    className="cancel-button"
                                    disabled={selectedContacts.length === 0}
                                >
                                    Cancel All
                                </button>
                            </div>
                        </div>

                        {isLoading ? (
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <div className="contacts-grid">
                                {filteredContacts.map((contact) => (
                                    <div
                                        key={contact.id}
                                        className={`contact-item ${
                                            selectedContacts.includes(contact)
                                                ? "selected"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            setSelectedContacts((prev) =>
                                                prev.includes(contact)
                                                    ? prev.filter(
                                                          (c) => c !== contact
                                                      )
                                                    : [...prev, contact]
                                            );
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedContacts.includes(
                                                contact
                                            )}
                                            readOnly
                                        />
                                        <span>{contact.name}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="broadcast-actions">
                        <button
                            onClick={handleBroadcast}
                            disabled={
                                isSending ||
                                (massFlow ? !checkInOut : !selectedTemplate) ||
                                selectedContacts.length === 0
                            }
                            className="broadcast-button"
                        >
                            {isSending ? "Sending..." : "Send Broadcast"}
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                rtl={false}
                pauseOnHover
                theme="colored"
                transition={Bounce}
            />
        </React.Fragment>
    );
}

export default Broadcast;
