import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { API } from "../api-service";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";

import "./css/Sublogin.css";
import { toast, ToastContainer, Bounce } from "react-toastify";

function SubLogin() {
    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userToken, setUserToken] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [massFlowOption, setMassFlowOption] = useState([]);
    const [unitOption, setUnitOption] = useState({});
    const [selectedChatflow, setSelectedChatflow] = useState("");
    const [selectedUnit, setSelectedUnit] = useState("");
    const { direct_link, waba_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const setUpLoginInfo = async () => {
            try {
                const response = await API.getDirectLinkUser({
                    direct_link: direct_link,
                });
                setUserId(response["user"]["id"]);
                const token = await API.getToken({
                    user_id: response["user"]["id"],
                });
                setUserToken(token["token"]);
                const chatFlowList = await API.getChatFlowList(token["token"]);
                const filteredData = chatFlowList
                    .filter(
                        (chatflow) =>
                            chatflow.type === "massflow" &&
                            chatflow.error_status === true
                    )
                    .map((chatflow) => ({
                        value: chatflow.id,
                        label: chatflow.chatflow_name,
                    }));
                setMassFlowOption(filteredData);

                const mappedUnit = {};
                const oriUnitList = {};

                await Promise.all(
                    filteredData.map(async (chatflow) => {
                        const response = await API.getNodes({
                            token: token["token"],
                            id: chatflow.value,
                            unit: true,
                        });
                        const unitNodes = response?.filter(
                            (node) => node.node_type === "unitNode"
                        );

                        const unitNodeComplete = unitNodes.reduce(
                            (acc, node) => {
                                const nodeTextArray = JSON.parse(node.text);
                                return [...acc, ...nodeTextArray];
                            },
                            []
                        );

                        oriUnitList[chatflow.label] = unitNodeComplete;
                        mappedUnit[chatflow.label] = unitNodeComplete.map(
                            (node) => ({ value: node, label: node })
                        );
                    })
                );

                setUnitOption(mappedUnit);
            } catch (error) {
                console.error(error);
            }
        };


        const checkWhatsApp = async () => {
            const response = await API.getDirectLinkUser({
                direct_link: direct_link,
            });
            const token = await API.getToken({
                user_id: response["user"]["id"],
            });
            const waba_list = await API.getWabaId(token['token']);
            const waba = waba_list.find(waba => waba.waba_id === waba_id);
            console.log(waba);
            if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                const whatsappLink = `whatsapp://send?phone=${waba.display_phone_number.replace(/\D/g, "").replace(/^0/, "")}`;
                window.location.href = whatsappLink;
    
                setTimeout(() => {
                    if (document.visibilityState === "visible") {
                        // If still on page after redirect attempt, WhatsApp is not installed
                        console.log("WhatsApp not detected, continue login process.");
                    }
                }, 3000);
            }
        };
    
        checkWhatsApp();
        setUpLoginInfo();
    }, [direct_link]);

    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== "+") {
            input = "+" + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError("");
                } else {
                    setPhoneError("Invalid phone number");
                }
            } else {
                setPhoneError("");
            }
        } catch (error) {
            setPhoneError("Invalid phone number");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (phoneError || !phoneNumber) {
            toast.error('Please fill in all the fields')
            return;
        }

        // Get or create client user
        let client_user = await API.getClientUser({
            token: userToken,
            phone_number: phoneNumber.replace(/\s+/g, "").replace("+", ""),
            waba_id: waba_id
        });
        if ("error" in client_user) {
            client_user = await API.addClientUser({
                token: userToken,
                phone_number: phoneNumber.replace(/\s+/g, "").replace("+", ""),
                name: 'Guest',
                checkin_datetime: null,
                checkout_datetime: null,
                unit_no: null,
                wabaId: waba_id,
                checkin_status: true,
                checkout_status: true,
                chatflow_id: null,
            });
        }

        navigate(
            "/sub/streamhost-inbox/" +
                userId +
                "/" +
                phoneNumber +
                "/" +
                waba_id +
                "/"
        );
    };

    return (
        <div className="slp-container">
            <div className="slp-header">
                <img src={require("../assets/header_title.png")} alt="Company Logo" className="slp-logo" />
                <h1 className="slp-title">Login</h1>
            </div>

            <div className="slp-card">
                <form onSubmit={handleSubmit} className="slp-form">
                    <div className="slp-form-group">
                        <label htmlFor="phoneNumber" className="slp-label">
                            Phone Number
                        </label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            className="slp-input"
                            value={phoneNumber}
                            onChange={(e) => handlePhoneChange(e)}
                            placeholder="Enter your phone number"
                            required
                        />
                        {phoneError && (
                            <div className="slp-error-message">
                                {phoneError}
                            </div>
                        )}
                    </div>

                    <button type="submit" className="slp-button">
                        Login
                    </button>
                </form>
            </div>
            <ToastContainer style={{zIndex: '100000001'}} position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} /> 
        </div>
    );
}

export { SubLogin };
