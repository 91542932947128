import React, { useState, useContext, useEffect, useRef } from "react";
import {Helmet} from 'react-helmet';
import { RiAddLine } from "react-icons/ri";
import { MdDelete, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineDateRange } from "react-icons/md";
import { FiCheck, FiMessageSquare } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import Sidenav2 from "../components/Sidenav";
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { API } from '../api-service.js';
import { UserContext } from '../user-context.js';
import HelpButton from '../components/HelpButton.js';
import { Bounce, ToastContainer, toast} from 'react-toastify';
import './css/ToDoList.css';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';

function ToDoList() {

    const { userToken, updateTaskCounts, waPhoneNumberId, wabaId, SystemUserToken } = useContext(UserContext);
    const [expandedSections, setExpandedSections] = useState({});
    const [newTaskText, setNewTaskText] = useState('');
    const navigate = useNavigate();

    // Reservation tasks
    const [activeReservationBtn, setActiveReservationBtn] = useState('');
    const [activeReservationList, setActiveReservationList] = useState([]);
    const [checkInList, setCheckInList] = useState([]);
    const [checkOutList, setCheckOutList] = useState([]);
    const [currentlyHostingList, setCurrentlyHostingList] = useState([]);
    const [upcomingList, setUpcomingList] = useState([]);
    const [isAddingTaskType, setIsAddingTaskType] = useState(false);
    const [taskType, setTaskType] = useState('');

    // ToDoList tasks
    const [taskTypes, setTaskTypes] = useState(['No Whatsapp']);
    const [customColors, setCustomColors] = useState({'No Whatsapp' : '#653239'});
    const [tasks, setTasks] = useState([]);
    const [taskCounts, setTaskCounts] = useState({});
    const [searchQuery, setSearchQuery] = useState('');

    // Phone number 
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    const [selectedPhoneNumberList, setSelectedPhoneNumberList] = useState([]);
    const [whatsappTemplateList, setWhatsappTemplateList] = useState([]);
    const [phoneError, setPhoneError] = useState('');
    const [activePhoneTaskType, setActivePhoneTaskType] = useState('No Whatsapp');

    const [selectedTaskType, setSelectedTaskType] = useState('No Whatsapp');
    const [hasUndoneAbove, setHasUndoneAbove] = useState(false);
    const [hasUndoneBelow, setHasUndoneBelow] = useState(false);
    const gridRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isTasksLoading, setIsTasksLoading] = useState(false);
    const [showDeleteTaskTypeConfirmation, setShowDeleteTaskTypeConfirmation] = useState(false);
    const [buildingFilter, setBuildingFilter] = useState('');
    const [unitFilter, setUnitFilter] = useState('');
    const [dateFilter, setDateFilter] = useState({
        checkIn: '',
        checkOut: ''
    });
    const [availableBuildings, setAvailableBuildings] = useState([]);
    const [availableUnits, setAvailableUnits] = useState([]);

    // Add this function to get unique buildings and units
    const updateAvailableFilters = (reservations) => {
        const buildings = new Set();
        const units = new Set();
        
        reservations.forEach(reservation => {
            reservation.building?.forEach(building => buildings.add(building));
            reservation.unitName?.forEach(unit => units.add(unit));
        });

        setAvailableBuildings(Array.from(buildings).sort());
        setAvailableUnits(Array.from(units).sort());
    };

    // Load initial data
    useEffect(() => {
        if (userToken) {
            loadTasks();
            loadTaskTypes();
            loadPhoneNumbers();
            loadWhatsappTemplates();
            loadReservations(userToken);
            setActiveReservationBtn('Checking in');
        }
    }, [userToken]);

    // API calls
    const loadTasks = async () => {
        try {
            setIsLoading(true);
            const taskData = await API.getTasks({ token: userToken });
            setTasks(taskData.filter(task => !task.completed));
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading tasks:', error);
            setIsLoading(false);
        }
    };

    const loadTaskTypes = async () => {
        try {
            const types = await API.getTaskTypes({ token: userToken });
            // Reset taskTypes to include only 'No Whatsapp' first
            setTaskTypes(['No Whatsapp']);
            
            // Then add any additional types
            if (types && types.length > 0) {
                const typeNames = types.map(t => t.task_type);
                setTaskTypes(prev => {
                    // Filter out any duplicates
                    const uniqueTypes = [...new Set([...prev, ...typeNames])];
                    return uniqueTypes;
                });
                
                // Update custom colors
                const colorMap = {'No Whatsapp': '#653239'};
                types.forEach(type => {
                    if (type.task_color) {
                        colorMap[type.task_type] = type.task_color;
                    }
                });
                setCustomColors(colorMap);
            }
        } catch (error) {
            console.error('Error loading task types:', error);
        }
    };

    const loadPhoneNumbers = async () => {
        try {
            const numbers = await API.getTaskTypePhone({ token: userToken });
            setPhoneNumberList(numbers);
        } catch (error) {
            console.error('Error loading phone numbers:', error);
        }
    };

    const loadWhatsappTemplates = async () => {
        try {
            const response = await fetch(`https://graph.facebook.com/v20.0/${wabaId}/message_templates?limit=250&fields=name&status=APPROVED`, {
                headers: { 'Authorization': `Bearer ${SystemUserToken}` }
            });
            const data = await response.json();
            setWhatsappTemplateList(data.data?.map(t => ({
                value: t.name,
                label: t.name
            })) || []);
        } catch (error) {
            console.error('Error loading templates:', error);
        }
    };

    useEffect(() => {
        if (tasks.length > 0 && taskTypes.length > 0) {
          const counts = {};
          taskTypes.forEach(type => {
            counts[type] = tasks.filter(task => task.task_type === type).length;
          });
          
          let maxCount = 0;
          let taskTypeWithMostTasks = '';
          
          Object.keys(counts).forEach(type => {
            if (counts[type] > maxCount) {
              maxCount = counts[type];
              taskTypeWithMostTasks = type;
            }
          });
          
          if (taskTypeWithMostTasks && maxCount > 0) {
            setExpandedSections(prev => ({...prev, [taskTypeWithMostTasks]: true}));
            setActivePhoneTaskType(taskTypeWithMostTasks);
          }
        }
      }, [tasks, taskTypes]);

    // Event handlers
    const handleTaskTypeChange = (type) => {
        setSelectedTaskType(type);
    };

    const handleAddTask = async () => {
        if (!newTaskText.trim()) return;
        
        try {
            const taskId = 'TASK-' + Date.now();
            await API.addTask({
                token: userToken,
                task_id: taskId,
                task_title: newTaskText,
                task_type: selectedTaskType
            });
            
            setNewTaskText('');
            loadTasks();
        } catch (error) {
            console.error('Error adding task:', error);
            toast.error('Failed to add task');
        }
    };

    const toggleSectionExpanded = (type) => {
        setExpandedSections(prev => ({...prev, [type]: !prev[type]}));
        if (!expandedSections[type]) {
          setActivePhoneTaskType(type);
        }
      };

    const handleAddPhoneNumber = async (taskTypeForPhone) => {
        if (!phoneNumber || phoneError) {
            toast.error(phoneError || 'Please enter a valid phone number');
            return;
        }
        
        try {
            const formattedNumber = phoneNumber.replace(/\s+/g, "").replace("+", "");
            const response = await API.checkWhatsappPhoneNumber({
            token: userToken,
            phone_number: formattedNumber,
            phone_number_id: waPhoneNumberId,
            waba_id: wabaId,
            system_token: SystemUserToken
            });
        
            if (response?.valid) {
            await API.addTaskTypePhone({
                token: userToken,
                phone_number: formattedNumber,
                task_type: taskTypeForPhone
            });
            loadPhoneNumbers();
            setPhoneNumber('');
            toast.success('Phone number added successfully');
            } else {
            toast.error('Invalid WhatsApp number');
            }
        } catch (error) {
            console.error('Error adding phone number:', error);
            toast.error('Failed to add phone number');
        }
    };

    const handleRemovePhoneNumber = async (id) => {
        try {
            await API.deleteTaskTypePhone({ token: userToken, id });
            loadPhoneNumbers();
            toast.success('Phone number removed');
        } catch (error) {
            console.error('Error removing phone number:', error);
            toast.error('Failed to remove phone number');
        }
    };

    const handleCompleteTask = async (taskId) => {
        try {
            const now = new Date().toISOString().replace('T', ' ').replace('Z', '+00:00');
            await API.updateTask({
                token: userToken,
                id: taskId,
                complete_date: now,
                completed: true
            });
            // Update tasks locally to immediately reflect the change
            setTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
        } catch (error) {
            console.error('Error completing task:', error);
            toast.error('Failed to complete task');
        }
    };

    // Enable scroll horizontally
    useEffect(() => {
        const reservationBody = document.querySelector('.reservation-body');
        if (reservationBody) {
            reservationBody.addEventListener('wheel', (event) => {
                if (event.deltaY !== 0) {
                    event.preventDefault();
                    reservationBody.scrollLeft += event.deltaY;
                }
            });
        }
    }, []);

    useEffect(() => {
        const counts = {};
        taskTypes.forEach(type => {
            counts[type] = tasks.filter(task => task.task_type === type).length;
        });

        if (window.updateSidenavTaskCounts) {
            window.updateSidenavTaskCounts(counts);
        }
        setTaskCounts(counts);
    }, [tasks, taskTypes]);

    // Separate function to load reservations
    const loadReservations = async (token) => {
        if (!token) return;

        try {
            const today = new Date();
            const clientUserData = await API.getClientUserList(token);
            
            // Get all unique chatflow IDs first
            const allChatflowIds = new Set();
            clientUserData.forEach(user => {
                const chatflowIds = JSON.parse(user.chatflow_id?.replace(/'/g, '"') || '[]');
                chatflowIds.forEach(id => allChatflowIds.add(id));
            });

            // Fetch all chatflow data at once
            const chatflowMap = new Map();
            await Promise.all(Array.from(allChatflowIds).map(async (id) => {
                const chatflow = await API.getChatFlow({'token': token, 'id': id});
                chatflowMap.set(id, chatflow.chatflow_name);
            }));

            // Process check-in list
            const processReservations = (user, filterFn) => {
                const checkin_datetime_list = JSON.parse(user.checkin_datetime?.replace(/'/g, '"') || '[]');
                const checkout_datetime_list = JSON.parse(user.checkout_datetime?.replace(/'/g, '"') || '[]');
                const building_list = JSON.parse(user.chatflow_id?.replace(/'/g, '"') || '[]');
                const unit_list = JSON.parse(user.unit_no?.replace(/'/g, '"') || '[]');
                
                const matchingIndices = [];
                for (let i = 0; i < checkin_datetime_list.length; i++) {
                    if (filterFn(new Date(checkin_datetime_list[i]), new Date(checkout_datetime_list[i]))) {
                        matchingIndices.push(i);
                    }
                }

                return {
                    name: user.name,
                    phone_number: user.phone_number,
                    checkin_datetime: matchingIndices.map(i => formatDateToDDMM(checkin_datetime_list[i])),
                    checkout_datetime: matchingIndices.map(i => formatDateToDDMM(checkout_datetime_list[i])),
                    building: matchingIndices.map(i => chatflowMap.get(building_list[i])),
                    unitName: matchingIndices.map(i => unit_list[i])
                };
            };

            // Process all lists with their respective filters
            const checkInList = clientUserData
                .map(user => processReservations(user, (checkin, _) => 
                    isSameDay(checkin, today)))
                .filter(reservation => reservation.checkin_datetime.length > 0);

            const checkOutList = clientUserData
                .map(user => processReservations(user, (_, checkout) => 
                    isSameDay(checkout, today)))
                .filter(reservation => reservation.checkin_datetime.length > 0);

            const hostingList = clientUserData
                .map(user => processReservations(user, (checkin, checkout) => 
                    checkin <= today && checkout >= today))
                .filter(reservation => reservation.checkin_datetime.length > 0);

            const upcomingList = clientUserData
                .map(user => processReservations(user, (checkin, _) => 
                    checkin > today))
                .filter(reservation => reservation.checkin_datetime.length > 0);

            setCheckInList(checkInList);
            setCheckOutList(checkOutList);
            setCurrentlyHostingList(hostingList);
            setUpcomingList(upcomingList);
            setActiveReservationList(checkInList);
            updateAvailableFilters([...checkInList, ...checkOutList, ...currentlyHostingList, ...upcomingList]);

        } catch (error) {
            console.error('Error loading reservations:', error);
        }
    };

    const getFilteredReservations = (reservations, filters) => {
        const { building, unit, dates } = filters;
        
        return reservations.filter(reservation => {
            let hasMatch = false;
    
            for (let i = 0; i < reservation.building?.length; i++) {
                const matchesBuilding = !building || 
                                      reservation.building[i]?.toLowerCase() === building.toLowerCase();
    
                const matchesUnit = !unit || 
                                  reservation.unitName[i]?.toLowerCase() === unit.toLowerCase();
    
                // Date filtering
                let matchesDates = true;
                if (dates.checkIn || dates.checkOut) {
                    const [checkInDay, checkInMonth] = reservation.checkin_datetime[i].split('/');
                    const [checkOutDay, checkOutMonth] = reservation.checkout_datetime[i].split('/');
                    
                    const currentYear = new Date().getFullYear();
                    const reservationCheckIn = new Date(currentYear, parseInt(checkInMonth) - 1, parseInt(checkInDay));
                    const reservationCheckOut = new Date(currentYear, parseInt(checkOutMonth) - 1, parseInt(checkOutDay));
    
                    if (dates.checkIn) {
                        const filterCheckIn = new Date(dates.checkIn);
                        // Check for exact date match for check-in
                        matchesDates = matchesDates && 
                            reservationCheckIn.getDate() === filterCheckIn.getDate() &&
                            reservationCheckIn.getMonth() === filterCheckIn.getMonth();
                    }
    
                    if (dates.checkOut) {
                        const filterCheckOut = new Date(dates.checkOut);
                        // Check for exact date match for check-out
                        matchesDates = matchesDates && 
                            reservationCheckOut.getDate() === filterCheckOut.getDate() &&
                            reservationCheckOut.getMonth() === filterCheckOut.getMonth();
                    }
                }
    
                if (matchesBuilding && matchesUnit && matchesDates) {
                    hasMatch = true;
                    break;
                }
            }
    
            return hasMatch;
        });
    };

    useEffect(() => {
        const listDict = {
            'Checking in': checkInList,
            'Checking out': checkOutList,
            'Currently hosting': currentlyHostingList,
            'Upcoming': upcomingList
        };
        
        const currentList = listDict[activeReservationBtn] || [];
        
        const filtered = getFilteredReservations(currentList, {
            building: buildingFilter,
            unit: unitFilter,
            dates: dateFilter
        });
        
        setActiveReservationList(filtered);
    }, [buildingFilter, unitFilter, dateFilter, activeReservationBtn, checkInList, checkOutList, currentlyHostingList, upcomingList]);

    const handleReservationClicked = (type) => {
        setActiveReservationBtn(type);
    };
    
    const handleBuildingFilterChange = (e) => {
        setBuildingFilter(e.target.value);
    };
    
    const handleUnitFilterChange = (e) => {
        setUnitFilter(e.target.value);
    };
    
    const handleDateFilterChange = (type, value) => {
        setDateFilter(prev => ({
            ...prev,
            [type]: value
        }));
    };

    const resetFilters = () => {
        setBuildingFilter('');
        setUnitFilter('');
        setDateFilter({
            checkIn: '',
            checkOut: ''
        });
    };

    // Helper function to check if two dates are the same day
    const isSameDay = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    };

    // Add task type
    const addNewTaskType = async (newType) => {
        // Task type validation
        if (newType === '') {
            toast.error('Task type cannot be empty');
            return;
        }

        if (taskTypes.includes(newType)) {
            toast.error('Task type already exists');
            return;
        }

        // Add task type to database
        try {
            await API.addTaskType({'token': userToken, 'task_type': newType});
            setTaskTypes(prevTypes => [...prevTypes, newType]);
            setIsAddingTaskType(false);
            toast.success('Task type added successfully');
        } catch (error) {
            console.error('Error adding task type:', error);
            toast.error('Failed to add task type');
        }
    };

    // Delete task type
    const deleteTaskType = async (task_type) => {
        try {
            const response = await API.deleteTaskType({'token': userToken, 'task_type': task_type});
            setTaskTypes(prevTypes => prevTypes.filter(type => type !== task_type));
            toast.success('Task type deleted successfully');
            setShowDeleteTaskTypeConfirmation(false);
        } catch (error) {
            console.error('Error deleting task type:', error);
            if (error.message && error.message.includes('Unexpected end of JSON input')) {
                setTaskTypes(prevTypes => prevTypes.filter(type => type !== task_type));
                toast.success('Task type deleted successfully');
                setShowDeleteTaskTypeConfirmation(false);
            } else {
                toast.error(`Failed to delete task type: ${error.message || 'Unknown error'}`);
            }
        }
    };

    // Regex function
    const getUserName = (input) => {
        if (typeof input !== "string") return ""; // Ensure input is a string
    
        // Regular expression to capture everything after the last meaningful number/date/unit
        const regex = /(?:.*\b\d{1,2}\/\d{1,2}-\d{1,2}\/\d{1,2}\b|\S*\d+)?\s*(\S.*)/;
        const match = input.match(regex);
    
        return match && match[1].trim() ? match[1].trim() : "";
    };

    function formatDateToDDMM(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        return `${day}/${month}`;
    }

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const checkUndoneTasksVisibility = () => {
        if (!gridRef.current) return;

        const grid = gridRef.current;
        const cards = grid.querySelectorAll('.task-type-card');
        let foundUndoneAbove = false;
        let foundUndoneBelow = false;
        const gridTop = grid.scrollTop;
        const gridBottom = gridTop + grid.clientHeight;

        cards.forEach((card) => {
            const cardTop = card.offsetTop;
            const cardBottom = cardTop + card.clientHeight;
            const taskCount = parseInt(card.querySelector('.task-count-badge')?.textContent || '0');

            if (taskCount > 0) {
                if (cardBottom < gridTop) foundUndoneAbove = true;
                if (cardTop > gridBottom) foundUndoneBelow = true;
            }
        });

        setHasUndoneAbove(foundUndoneAbove);
        setHasUndoneBelow(foundUndoneBelow);
    };

    useEffect(() => {
        const grid = gridRef.current;
        if (grid) {
            grid.addEventListener('scroll', checkUndoneTasksVisibility);
            window.addEventListener('resize', checkUndoneTasksVisibility);
            checkUndoneTasksVisibility();
        }

        return () => {
            if (grid) {
                grid.removeEventListener('scroll', checkUndoneTasksVisibility);
                window.removeEventListener('resize', checkUndoneTasksVisibility);
            }
        };
    }, [taskTypes, taskCounts]);

    // Apply search filter to task types, only once
    const filteredTaskTypes = taskTypes
        .filter(type => type.toLowerCase().includes(searchQuery.toLowerCase()))
        .sort((a, b) => (taskCounts[b] || 0) - (taskCounts[a] || 0));

    // Download excel function
    const downloadReservationsExcel = () => {
        // Get only the currently active reservation list
        let reservationsToDownload = [];
        
        if (activeReservationBtn === 'Checking in') {
            reservationsToDownload = checkInList.map(r => ({...r, type: 'Checking In'}));
        } else if (activeReservationBtn === 'Checking out') {
            reservationsToDownload = checkOutList.map(r => ({...r, type: 'Checking Out'}));
        } else if (activeReservationBtn === 'Currently hosting') {
            reservationsToDownload = currentlyHostingList.map(r => ({...r, type: 'Currently Hosting'}));
        } else if (activeReservationBtn === 'Upcoming') {
            reservationsToDownload = upcomingList.map(r => ({...r, type: 'Upcoming'}));
        }
        
        // Flatten the data structure
        const flattenedData = reservationsToDownload.flatMap(reservation => 
            reservation.checkin_datetime.map((_, index) => ({
                Type: reservation.type,
                Name: reservation.name,
                'Phone Number': reservation.phone_number,
                'Check In': reservation.checkin_datetime[index],
                'Check Out': reservation.checkout_datetime[index],
                Building: reservation.building[index],
                Unit: reservation.unitName[index]
            }))
        );
    
        // Create a new workbook and add the data
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(flattenedData);
        XLSX.utils.book_append_sheet(wb, ws, "Reservations");
    
        // Generate and download the Excel file
        XLSX.writeFile(wb, "Reservations.xlsx");
    };

    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    const navigateInbox = (phoneNumber) => {
        navigate('/main/inbox/' + phoneNumber + '/');
    }

    return (
        <React.Fragment>

            <Helmet>
                <title>StreamHost | To-Do List</title>
            </Helmet>

            <Sidenav2/>
            <div className="todo-container">
                <h1 className="todo-title">To-Do List</h1>
                
                {/* Search and Add Task */}
                <div className="search-container">
                <div className="search-box">
                    <input
                    type="text"
                    placeholder="Search tasks..."
                    className="search-input-todo"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    />
                </div>
                
                <div className="add-task-container">
                    <select 
                    value={selectedTaskType}
                    onChange={(e) => handleTaskTypeChange(e.target.value)}
                    className="task-type-select"
                    >
                    {taskTypes.map(type => (
                        <option key={type} value={type}>{type}</option>
                    ))}
                    </select>

                    <div className="phone-size-todo">
                    <input
                    type="text"
                    placeholder="Add a new task..."
                    className="new-task-input"
                    value={newTaskText}
                    onChange={(e) => setNewTaskText(e.target.value)}
                    />
                    
                    <button
                    onClick={handleAddTask}
                    className="add-button-todo"
                    >
                    <span>+</span> Add
                    </button>
                    </div>
                </div>
                </div>

                {isTasksLoading && (
                    <div className="task-loader-container">
                        <div className="task-loader"></div>
                    </div>
                )}

                <div className="task-categories-container" ref={gridRef}>
                    <div className="add-task-type-card" onClick={() => setIsAddingTaskType(true)}>
                        <RiAddLine className="add-icon-button" size={24} />
                    </div>
                    {isAddingTaskType && (
                            <div className='add-task-type-modal'>
                                <h3>Add new task type</h3>
                                <input type='text' placeholder='Task title' maxLength={40} value={taskType} onChange={(evt) => setTaskType(evt.target.value)}/>
                                <div className='add-task-type-btn-container'>
                                    <button className="add-button-todo" onClick={() => addNewTaskType(taskType)}>Confirm</button>
                                    <button className="cancel-button-todo" onClick={() => setIsAddingTaskType(false)}>Cancel</button>
                                </div>
                            </div>
                     )}
                {!isTasksLoading && filteredTaskTypes.map(type => {
                    const typeTasks = tasks.filter(t => t.task_type === type);
                    const isExpanded = expandedSections[type];
                    
                    return (
                        <div key={type} className="task-section">
                        <div 
                            className="section-header"
                            onClick={() => toggleSectionExpanded(type)}
                        >
                            <div className="section-title">
                            <span>{type}</span>
                            <span className="task-count">({typeTasks.length})</span>
                            {typeTasks.length > 0 && (
                                    <span className="notification-todo-dot"></span>
                                )}
                            </div>
                            <div className="section-indicators">
                                {isExpanded ? <MdKeyboardArrowUp className="arrow-icon" /> : <MdKeyboardArrowDown className="arrow-icon" />}
                            </div>
                        </div>

                        {isExpanded && (
                            <div className="section-content">
                            {/* Phone Numbers Section */}
                            <div className="phone-numbers-section">
                                <h3 className="phone-numbers-title">Phone Numbers</h3>
                                <div className="phone-numbers-list">
                                {phoneNumberList
                                    .filter(p => p.task_type === type)
                                    .map(phone => (
                                    <div key={phone.id} className="phone-number-item">
                                        <span>{phone.phone_number}</span>
                                        <button
                                        onClick={() => handleRemovePhoneNumber(phone.id)}
                                        className="remove-phone-button"
                                        >
                                        ×
                                        </button>
                                    </div>
                                    ))
                                }
                                <div className="add-phone-container">
                                    <input
                                    type="text"
                                    placeholder="Add phone number..."
                                    className="phone-input"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    />
                                    <button
                                    onClick={() => handleAddPhoneNumber(type)}
                                    className="add-phone-button"
                                    >
                                    Add
                                    </button>
                                </div>
                                </div>
                            </div>

                            <div className="tasks-list">
                            {typeTasks.length > 0 ? (
                                typeTasks.map(task => {
                                    let phoneNumber = "";
                                    let name = "";
                                    let taskDescription = "";

                                    if (task.task_title) {
                                        
                                        if (task.task_title.startsWith('Phone:')) {
                                            const parts = task.task_title.split(/Name:|Task:/);
                                            phoneNumber = parts[0].replace('Phone:', '').trim();
                                            
                                            if (task.task_title.includes('Name:')) {
                                                name = parts[1].trim();
                                            } else if (task.task_title.includes('Task:')) {
                                                taskDescription = parts[1].trim();
                                            }
                                        } else if (task.task_title.startsWith('Name:')) {
                                            const parts = task.task_title.split('Name:');
                                            name = parts[1].trim();
                                        } else if (task.task_title.startsWith('Task:')) {
                                            const parts = task.task_title.split('Task:');
                                            taskDescription = parts[1].trim();
                                        } else {
                                            name = task.task_title;
                                        }
                                    }
                                    
                                    return (
                                        <div key={task.id} className="task-item">
                                        <div className="task-mobile-chat">
                                            <div className="task-tick-container">
                                                <div 
                                                className={`task-tick ${task.completed ? 'completed' : ''}`}
                                                onClick={() => handleCompleteTask(task.id)}
                                                >
                                                <FiCheck />
                                                </div>
                                            </div>
                                            {phoneNumber && (
                                                <div className="phone-number-container">
                                                <button 
                                                className="navigate-inbox-btn-mobile"
                                                onClick={() => navigateInbox(phoneNumber)}
                                            >
                                                <FiMessageSquare /> 
                                            </button>
                                            </div>
                                            )}
                                        </div>
                                            <div className="task-content">
                                                {phoneNumber && (
                                                    <div className="task-title-row">
                                                        <div className="phonetag-todo">
                                                            <span className="phone-tag">Phone</span>
                                                            <span className="phone-number">{phoneNumber}</span>
                                                        </div>
                                                    </div>
                                                )}
                                                {name && (
                                                    <div className="name-container">
                                                    <div className="task-title-row-name">
                                                        <div className="name-tag-todo">
                                                        <span className="name-tag">Name</span>
                                                        </div>
                                                        <span className="name-value">{name}</span>
                                                    </div>
                                                    </div>
                                                )}
                                                {!name && task.task_title && task.task_title.includes('Task:') && (
                                                    <div className="task-title-row">
                                                        <div className="task-tag-todo">
                                                        <span className="task-tag">Task</span>
                                                        <span className="task-value">{task.task_title.split('Task:')[1].trim()}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-tasks-message">
                                    <p>No tasks for this category</p>
                                    <button onClick={() => {setSelectedTaskType(type); setShowDeleteTaskTypeConfirmation(true);}} className='delete-task-type-btn'>
                                        Delete task type
                                    </button>
                                </div>
                                
                            )}
                        </div>
                        </div>
                        )}
                        </div>
                    );
                    })}
                    </div>
                </div>

                {showDeleteTaskTypeConfirmation && (
                                <div className="delete-confirmation-modal">
                                    <div className="delete-task-modal">
                                        <h3>Are you sure you want to delete "{selectedTaskType}" task type?</h3>
                                        <div className="delete-modal-buttons">
                                            <button className="delete-yes" onClick={() => {deleteTaskType(selectedTaskType);
                                            setShowDeleteTaskTypeConfirmation(false); }}>
                                                Yes
                                            </button>
                                            <button className="delete-no" onClick={() => setShowDeleteTaskTypeConfirmation(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                )}

            {/* Reservation part */}
            <div className='reservation-container'>
                <h2>Your reservations</h2>
                <div className='reservation-filters'>
                <div className="building-filter">
                <div className="building-filter-text">Building</div>
                    <select 
                    value={buildingFilter}
                    onChange={handleBuildingFilterChange}
                    >
                    <option value="">All Buildings</option>
                    {availableBuildings.map(building => (
                        <option key={building} value={building}>{building}</option>
                    ))}
                    </select>
                </div>
                <div className="unit-filter">
                <div className="unit-filter-text">Unit</div>
                    <select 
                    value={unitFilter}
                    onChange={handleUnitFilterChange}
                    >
                    <option value="">All Units</option>
                    {availableUnits.map(unit => (
                        <option key={unit} value={unit}>{unit}</option>
                    ))}
                    </select>
                </div>
                    <div className="date-filter">
                    <div className="check-in-period">
                        <div className="date-filter-text">Check-in Period:</div>
                        <input 
                        type="date"
                        value={dateFilter.checkIn}
                        onChange={(e) => handleDateFilterChange('checkIn', e.target.value)}
                        placeholder="Check-in Date"
                        />
                    </div>
                    <div className="check-out-period">
                        <div className="date-filter-text">Check-out Period:</div>  
                        <input 
                        type="date"
                        value={dateFilter.checkOut}
                        onChange={(e) => handleDateFilterChange('checkOut', e.target.value)}
                        placeholder="Check-out Date"
                        />
                    </div>
                    </div>
                    <button onClick={resetFilters}>Reset Filters</button>
                </div>
                <div className='reservation-header'>
                    <button onClick={() => handleReservationClicked('Checking in')} style={{'border': activeReservationBtn === 'Checking in' ? 'solid 1.6px #002244' : undefined}}>Checking in ({checkInList.length})</button>
                    <button onClick={() => handleReservationClicked('Checking out')} style={{'border': activeReservationBtn === 'Checking out' ? 'solid 1.6px #002244' : undefined}}>Checking out ({checkOutList.length})</button>
                    <button onClick={() => handleReservationClicked('Currently hosting')} style={{'border': activeReservationBtn === 'Currently hosting' ? 'solid 1.6px #002244' : undefined}}>Currently hosting ({currentlyHostingList.length})</button>
                    <button onClick={() => handleReservationClicked('Upcoming')} style={{'border': activeReservationBtn === 'Upcoming' ? 'solid 1.6px #002244' : undefined}}>Upcoming ({upcomingList.length})</button>
                    <button onClick={downloadReservationsExcel} style={{backgroundColor:'#002244', color: '#FFF'}}>Download excel</button>
                </div>

                <div className='reservation-body'>
                    {activeReservationList.length > 0 ? (
                        activeReservationList.map((reservation, index) => {
                            return (
                                <div className='reservation-card' key={reservation.id || index}>
                                    <div className='reservation-name-section'>
                                        <h3>{getUserName(reservation.name)}</h3>
                                        <p>+{reservation.phone_number}</p>
                                    </div>
                                    <hr style={{ borderColor: 'rgba(0, 0, 0, 0.3)', borderWidth: '0.5px' }} />
                                    <div className='reservation-building-section'>
                                        {reservation.checkin_datetime && reservation.checkout_datetime && reservation.building && reservation.unitName ? (
                                            reservation.checkin_datetime.length > 0 ? (
                                                reservation.checkin_datetime.map((checkin, idx) => (
                                                    <div className='reservation-building' key={`${reservation.id}-${idx}`}>
                                                        <div className='reservation-date-data'>
                                                        <div className="reservation-date-icon">
                                                        <MdOutlineDateRange />
                                                        </div>
                                                        <h3>{checkin} - {reservation.checkout_datetime[idx]}</h3>
                                                        </div>
                                                        <div className="reservation-unit-data">
                                                        <p>{reservation.building[idx]}: {reservation.unitName[idx]}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No check-in data available</p>
                                            )
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className='reservation-empty'>
                            <p>You're all done for today</p>
                        </div>
                    )}
                    </div>
                </div>
            
            <ToastContainer position='bottom-center' autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            <HelpButton />
        </React.Fragment>
    );
};

export default ToDoList;
